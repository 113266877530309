import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { ChevronDown } from 'tabler-icons-react';
import {
  Content,
  ToggableBoxWrapper,
  Trigger,
} from 'components/ToggableBox/style';

const ToggableBox = ({ children, title, defaultOpened, subItem }) => {
  const [isOpened, setIsOpened] = useState(defaultOpened);
  const toggleSection = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  return (
    <ToggableBoxWrapper>
      <Trigger
        type="button"
        onClick={toggleSection}
        isOpened={isOpened}
        subItem={subItem}
      >
        {title}
        <ChevronDown size={24} />
      </Trigger>

      <CSSTransition
        in={isOpened}
        timeout={500}
        classNames="slide"
        unmountOnExit
      >
        <Content>{children}</Content>
      </CSSTransition>
    </ToggableBoxWrapper>
  );
};
ToggableBox.defaultProps = {
  defaultOpened: false,
  subItem: false,
};

ToggableBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  defaultOpened: PropTypes.bool,
  subItem: PropTypes.bool,
};

export default ToggableBox;
