import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'rebass';
import { inject, observer } from 'mobx-react';
import * as Styled from './style';
import SearchForm from '../search/components/forms/SearchForm';

const Home = inject('emailStore')(
  observer(({ t, emailStore: { askForRoomInYourCity } }) => {
    // {/* <Styled.VideoBackground
    //   playsInline
    //   autoPlay
    //   muted
    //   loop
    //   poster="/images/backgrounds/homepage-video-poster.jpg"
    // >
    //   <source src="/video/homepage-bg.mp4" type="video/mp4" />
    // </Styled.VideoBackground> */}
    return (
      <Styled.Wrapper>
        <Styled.MainTitle>{t('Rent a dance hall')}</Styled.MainTitle>
        <SearchForm />
        <Button
          variant="text"
          type="button"
          mt={40}
          onClick={askForRoomInYourCity}
        >
          {t("home:Can't find room in your city?")}
        </Button>
      </Styled.Wrapper>
    );
  }),
);

Home.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Home);
