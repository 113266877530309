import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { breakpoint } from 'styles/breakpoints';
import { LineSeparator } from 'styles/globalStyles';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  height: 100%;
  width: ${props => (props.width ? `${props.width}px` : null)};
`;

export const TimeBox = styled(Box)`
  width: 291px;
  background: ${props => props.bgTimeColor};
  padding: 17px 25px 27px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 0px 3px 3px;
  width: 100%;
  height: 100%;

  @media ${breakpoint.md} {
    border-bottom-left-radius: 0px;
    width: 291px;
    height: 275px;
  }
`;

export const DateTimeWrapper = styled(Flex)`
  flex-direction: row;
  @media screen and (max-width: 40em) {
    flex-direction: column;
  }
`;

export const SelectedTime = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
`;

export const HoursSeparator = styled.span`
  color: black;
  margin: 10px;
  align-self: flex-end;
  margin-bottom: 18px;
  @media ${breakpoint.xs} {
    align-self: flex-start;
    margin-top: 35px;
  }
  @media ${breakpoint.md} {
    margin-top: 55px;
    margin-bottom: 0;
  }
`;

export const Line = styled(LineSeparator)`
  @media ${breakpoint.xs} {
    margin-top: 16px;
  }
  @media ${breakpoint.md} {
    margin-top: 30px;
  }
`;
