import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  color: #fff;

  img {
    display: block;
    width: 26px;
    height: 20px;
    border-radius: 3px;
    object-fit: cover;
  }
`;

export const FlagButton = styled.button`
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    display: block;
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ isOpened }) => (isOpened ? -180 : 0)}deg);

    path {
      fill: #b06c3b;
    }
  }
`;

export const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  margin: 10px 0 0;
  padding: 0;
  left: -5px;
  /* background: #f4b78a; */
  background: #3e291c;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  button {
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
`;
