import styled from '@emotion/styled';
import { Flex, Heading } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 30px 0;
  margin: 30px 0;
  padding: 30px;
  position: relative;
  background-color: #fff;

  @media ${breakpoint.md} {
    margin: 30px 0 109px;
    padding: 40px 45px;
  }
`;

export const Breadcrumb = styled(Flex)`
  margin: 25px 0;

  @media ${breakpoint.md} {
    margin-top: 0;
    margin-bottom: 60px;
  }
`;

export const FormSection = styled.div`
  border-bottom: ${props => (props.separated ? '1px solid #DCDCDC' : 0)};
  padding-bottom: ${props => (props.separated ? 30 : 0)}px;
  margin-bottom: ${props => (props.separated ? 30 : 0)}px;
  /* max-width: 1000px; */

  border-top: ${props => (props.separatedTop ? '1px solid #DCDCDC' : 0)};
  padding-top: ${props => (props.separatedTop ? 30 : 0)}px;
  margin-top: ${props => (props.separatedTop ? 30 : 0)}px;

  @media ${breakpoint.md} {
    border-bottom: ${props => (props.separated ? '1px solid #DCDCDC' : 0)};
    padding-bottom: ${props => (props.separated ? 50 : 0)}px;
    margin-bottom: ${props => (props.separated ? 40 : 0)}px;
    /* max-width: 1000px; */

    border-top: ${props => (props.separatedTop ? '1px solid #DCDCDC' : 0)};
    padding-top: ${props => (props.separatedTop ? 50 : 0)}px;
    margin-top: ${props => (props.separatedTop ? 40 : 0)}px;
  }
`;

export const FormRow = styled(Flex)`
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
    ${props =>
      props.multi &&
      `
      > div {
        padding-left: 14px;
        &:first-of-type {
          padding-right: 14px;
          padding-left: 0;
        }
      }
  `}
  }
`;

export const MultiRow = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
  gap: 14px;
  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const FormTitle = styled(Heading)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 24px;
    margin-left: 10px;
  }
`;

export const DeleteAccountInfo = styled.div``;

export const SmallText = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

export const Link = styled(RouterLink)`
  display: block;
  text-decoration: none;
  font-weight: 700;
  color: #000;
`;

export const RadioButtonGroup = styled(Flex)`
  flex-wrap: wrap;
  margin-bottom: 13px;
  & > div {
    margin-right: 10px;
  }
  @media ${breakpoint.md} {
    flex-wrap: nowrap;
  }
`;

export const CompanyType = styled(RadioButtonGroup)`
  @media ${breakpoint.md} {
    min-width: 500px;
  }
`;
