import styled from '@emotion/styled';
import React from 'react';
import { Flex, Box } from 'rebass';
import { breakpoint } from 'styles/breakpoints';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Flex)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  flex-wrap: wrap;
`;

export const Title = styled(Flex)`
  justify-content: space-between;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: left;

  @media ${breakpoint.md} {
    padding-top: 0;
    font-size: 28px;
    line-height: 33px;
  }
`;

export const Small = styled.span`
  font-size: 16px;
  @media ${breakpoint.md} {
    font-size: 20px;
  }
`;

const Column = styled.div`
  min-height: 650px;
  background-color: #fff;
`;

export const LeftColumn = styled(Column)`
  flex: 1 0 100%;
  padding: 15px;
  position: relative;
  order: 2;
  min-height: unset;

  @media ${breakpoint.md} {
    flex: 1 0 43%;
    padding: 50px;
    order: 1;
    min-height: 650px;
  }
`;

export const ImageColumn = styled(Column)`
  flex: 1 0 57%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${props => props.image}');
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  order: 1;
  height: 190px;
  min-height: unset;

  @media ${breakpoint.md} {
    order: 2;
    min-height: 650px;
    height: auto;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
  }
`;

export const SummarySection = styled.div`
  margin: 15px 0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;

  @media ${breakpoint.md} {
    padding: ${props => (props.noPadding ? 0 : 15)}px;
    background-color: ${props =>
      props.transparent ? 'transparent' : 'rgba(0, 0, 0, 0.03)'};
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #c6c6c6;
`;

export const CancelBooking = styled.div`
  font-size: 16px;
  @media ${breakpoint.md} {
    font-size: 17px;
  }
`;

export const TextRow = styled(Box)`
  margin-top: 12px;
  font-size: 14px;

  @media ${breakpoint.md} {
    font-size: 16px;
  }
`;

export const Label = styled.span`
  display: inline-block;
  min-width: 130px;
`;

export const Value = styled.span`
  color: #d9a248;

  > span {
    color: #000;
  }
`;

export const SummaryRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  font-size: 22px;
  margin: 25px 0 35px;

  ${Label} {
    font-weight: 500;

    > span {
      color: #6b6b6b;
    }
  }

  ${Value} {
    color: #6b6b6b;
    font-weight: 500;
  }
`;

export const Or = styled.div`
  margin: 30px 0 45px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  position: relative;

  span {
    display: inline-block;
    padding: 0 15px;
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dedede;
    position: absolute;
    top: 50%;
  }
`;

export const DiscountInfo = styled.div`
  margin-bottom: 16px;
`;

export const ActionLink = styled(({ positionabs, ...props }) => (
  <Link {...props} />
))`
  color: #d9a248;
  text-decoration: none;
  font-weight: ${props => (props.positionabs ? '500' : '900')};
  cursor: pointer;
  position: ${props => (props.positionabs ? 'absolute' : '')};
  right: ${props => (props.positionabs ? '0' : 'auto')};
  z-index: 10;
`;
