import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import TextInput from 'components/forms/TextInput';
import MainButton from 'components/MainButton';
import Preloader from 'components/Preloader';
import { FormRow, FormSection, FormTitle } from '../style';
import AddressForm from './AddressForm';

const ProfileForm = ({ onSubmit, t, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, submitting, values }) => (
      <form onSubmit={handleSubmit}>
        {submitting && <Preloader cover />}

        <FormSection>
          <FormTitle
            as="h2"
            fontWeight="medium"
            fontSize={['16px', '16px', '16px', '22px']}
          >
            {t('Personal information')}
          </FormTitle>
          <FormRow>
            <Field
              name="name"
              type="text"
              component={TextInput}
              label={t('Name')}
            />
          </FormRow>
          <FormRow multi>
            <Field
              disabled
              name="email"
              type="email"
              component={TextInput}
              label={t('Email')}
            />
            <Field
              name="phone"
              type="phone"
              component={TextInput}
              label={t('Phone')}
            />
          </FormRow>
        </FormSection>

        <FormSection>
          <FormTitle
            as="h2"
            fontWeight="medium"
            fontSize={['16px', '16px', '16px', '22px']}
          >
            {t('Billing information')}
          </FormTitle>
          <AddressForm
            handleSubmit={handleSubmit}
            showTypeSelector
            accountType={values.billingAddress?.type}
          />
        </FormSection>

        <MainButton
          mt={[20, 20, 20, 40]}
          onClick={handleSubmit}
          type="submit"
          disabled={submitting}
        >
          {t('Save')}
        </MainButton>
      </form>
    )}
  />
);

ProfileForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default withTranslation('account')(ProfileForm);
