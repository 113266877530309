import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
import { ButtonsWrapper, SecondaryButton } from './style';

const CancelBookingModal = ({
  roomName,
  cancelBooking,
  bookingId,
  close,
  t,
}) => {
  return (
    <>
      <Heading
        as="h2"
        fontWeight="medium"
        fontSize={[3, 3, 3, 6]}
        mb={3}
        pr={['30px', '30px', '30px', 0]}
      >
        {t('Cancel room booking', {
          name: roomName,
        })}
      </Heading>
      <Text
        lineHeight={['14px', '14px', '14px', '18px']}
        fontSize={[0, 0, 0, 3]}
      >
        {t(
          'Are you sure you want to cancel this reservation? A fee eqaual to 6 zł per booking hour will be charged.',
        )}
      </Text>
      <ButtonsWrapper>
        <MainButton
          type="button"
          // TODO: store-> cancel reservation
          onClick={() => cancelBooking(bookingId, close)}
        >
          {t('booking.cancelRes')}
        </MainButton>
        <SecondaryButton
          type="button"
          variant="secondary"
          fontSize="20px"
          lineHeight="26px"
          onClick={() => close()}
        >
          {t('booking.cancel')}
        </SecondaryButton>
      </ButtonsWrapper>
    </>
  );
};

CancelBookingModal.propTypes = {
  cancelBooking: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('account')(CancelBookingModal);
