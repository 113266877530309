import styled from '@emotion/styled';
import { Heading } from 'rebass';

export const FormTitle = styled(Heading)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 24px;
    margin-left: 10px;
  }
`;

export const Description = styled.p`
  margin-bottom: 30px;
`;
