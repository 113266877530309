import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { Container, LineSeparator } from 'styles/globalStyles';
import { breakpoint } from 'styles/breakpoints';

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContentWrapper = styled(Container)`
  position: relative;
  text-align: center;
  z-index: 1;
`;

export const Wrapper = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 30px 0;

  @media ${breakpoint.xs} {
    margin: 30px 0;
  }
  @media ${breakpoint.md} {
    margin: 51px 0 109px;
  }
`;

export const Row = styled(Flex)`
  justify-content: space-between;
  background-color: #fff;

  @media ${breakpoint.xs} {
    flex-direction: column;
    padding: 15px;
  }
  @media ${breakpoint.md} {
    padding: 45px;
    flex-direction: row;
  }
`;

export const LeftColumn = styled(Flex)`
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  max-width: 772px;
  width: 100%;
  @media ${breakpoint.md} {
    margin-right: 25px;
  }
`;

export const VerticalLineSeparator = styled(LineSeparator)`
  width: unset;
  margin-top: 34px;
  @media ${breakpoint.md} {
    margin-top: unset;
  }
`;

export const RightColumn = styled(Flex)`
  flex: auto;
  flex-direction: column;
  padding-bottom: 30px;
  @media ${breakpoint.md} {
    margin-left: 89px;
    padding-bottom: unset;
  }
`;

export const ContactPhone = styled.div`
  text-align: left;
  line-height: 31px;

  a.phone {
    text-decoration: none;
  }
`;

export const LabelPhone = styled.span`
  font-weight: 500;
`;

export const PhoneNumber = styled.a`
  text-decoration: none;
  color: black;
`;

export const NameEmailWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;
