/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Text } from 'rebass';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Preloader from 'components/Preloader';
import { Container } from 'styles/globalStyles';
import { ListWrapper } from 'containers/Blog/style';
import Post from './components/Post';

const List = styled.ul`
  margin-top: 30px;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.05);
`;

const BlogPage = ({
  t,
  blogStore: { isLoading, initialized, fetchBlogPosts, blogPosts },
}) => {
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  if (!initialized) {
    return <Preloader />;
  }

  return (
    <Container>
      <ListWrapper>
        {blogPosts.map(post => (
          <Post key={`post_${post._id}`} {...post} />
        ))}
      </ListWrapper>
    </Container>
  );
};

export default withTranslation('promotions')(
  inject('blogStore')(observer(BlogPage)),
);
