/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import styled from '@emotion/styled';
import i18n from 'i18n';
import FormError from './FormError';

import Label from './FormLabel';

const customStyles = (variant, border, fullWidth, countrySelect = false) => {
  return {
    container: provided => ({
      ...provided,
      height: '100%',
      width: fullWidth && '100%',
      ...(countrySelect === true && {
        border: 'inset',
        borderRadius: '3px',
        borderWidth: '2px',
        borderColor: 'rgb(118,118,118)',
      }),
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      textAlign: 'left',
      color: state.isSelected ? 'black' : '#666666',
      '&:hover': {
        color: 'black',
      },
      backgroundColor: state.isFocused ? 'none' : 'none',
      padding: 10,
      ':active': {
        backgroundColor: 'none',
      },
    }),
    control: provided => {
      return {
        ...provided,
        boxShadow:
          variant === 'primary'
            ? 'inset 0px -2px 4px rgba(0, 0, 0, 0.15)'
            : 'none',
        borderRadius:
          variant === 'primary'
            ? 3
            : border === 'secondary'
            ? '3px 0px 0px 3px'
            : '0',
        '&:hover': {
          cursor: 'pointer',
        },
        height: '100%',
        minHeight: '46px',
        backgroundColor:
          variant === 'primary'
            ? '#FAFAFA'
            : variant === 'secondary'
            ? '#FAFAFA'
            : 'hsl(0,0%,100%)',
        color: '#000000',
        border: 'none',
        '@media (min-width: 640px) and (max-width: 767px)': {
          borderRadius: 3,
        },
      };
    },
    menuList: provided => ({
      ...provided,
      padding: '0px',
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#573B27',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#573B27',
      },
    }),
    menu: provided => ({
      ...provided,
      borderRadius: '0px 0px 3px 3px',
      zIndex: 4,
      boxShadow: 'none',
      marginTop: '0',
      marginBottom: '10px',
      backgroundColor: variant === 'secondary' ? '#FAFAFA' : 'hsl(0,0%,100%)',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#B06C3B',
      '&:hover': {
        color: '#B06C3B',
      },
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };
};

const ReactSelectEl = styled(ReactSelect)`
  @media screen and (max-width: 47.938em) {
    & .react-select__control {
      border-radius: 3px;
      border-right: none;
      max-height: 46px;
    }
    & .react-select__control--menu-is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 4;
    }
    & .react-select__menu {
      position: unset;
    }
    & .react-select__value-container {
      padding: 14px 11px;
    }
  }
`;

const IndicatorSeparator = () => null;

const Select = ({
  children,
  variant,
  border,
  input,
  meta,
  options,
  label,
  size,
  fullWidth,
  isIpad,
  countrySelect = false,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState(null);
  React.useEffect(() => {
    const value = get(
      options?.filter(option => input.value === option.value),
      '[0]',
      null,
    );
    setSelectedValue(value);
  }, [input.value, i18n.language]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <ReactSelectEl
        {...input}
        {...props}
        options={options}
        classNamePrefix="react-select"
        className="react-select-container"
        styles={customStyles(variant, border, fullWidth, countrySelect)}
        onChange={e => {
          input.onChange(e.value);
          if (props.onChange) {
            props.onChange(e.value);
          }
        }}
        value={selectedValue}
        components={{ IndicatorSeparator, MenuList: components.MenuList }}
        isSearchable={!!countrySelect}
        menuPlacement="auto"
        menuPosition={isIpad ? 'fixed' : 'absolute'}
        menuShouldBlockScroll={!!isIpad}
      />
      <FormError meta={meta} />
    </>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.object,
  width: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  size: PropTypes.oneOf(['big', 'normal']),
  countrySelect: PropTypes.bool,
};

Select.defaultProps = {
  input: {},
  meta: {},
  children: [],
  width: 169,
  options: [],
  onChange: () => {},
  size: 'normal',
  countrySelect: false,
};

export default Select;
