/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Preloader from 'components/Preloader';
import InfiniteScroll from 'react-infinite-scroller';
import { Card } from 'styles/globalStyles';
import Booking from './Booking';

import * as Styled from './style';

const MyBookings = inject(
  'userStore',
  'bookingStore',
)(
  observer(
    ({
      fetchingBookings,
      fetchMoreBookings,
      hasMore,
      bookings,
      t,
      bookingStore: { cancelBooking },
      isPremium,
    }) => {
      const renderBookings = () => {
        if (!bookings.length)
          return (
            <Styled.Item alignItems="center" justifyContent="space-between">
              {t('You have no bookings yet.')}
            </Styled.Item>
          );

        // map orders
        return (
          <>
            <Styled.CancelInfo>
              {t(
                'booking:You can cancel your booking within {{hours}} hours.',
                {
                  hours: isPremium ? 12 : 24,
                },
              )}
            </Styled.CancelInfo>
            <Flex maxHeight={500} width="100%" overflow="auto">
              <InfiniteScroll
                style={{ width: '100%' }}
                threshold={10}
                pageStart={1}
                loadMore={fetchMoreBookings}
                hasMore={hasMore}
                initialLoad={false}
                loader={<Preloader h={100} key={0} />}
              >
                <Flex flexWrap="wrap" flexDirection="row">
                  {bookings.map(b => {
                    // map bookings from order
                    return b.bookings.map(booking => (
                      <Flex
                        flexDirection="column"
                        flexGrow={1}
                        p={15}
                        key={`b_${booking._id}`}
                        sx={{
                          '@media (max-width: 769px)': {
                            height: '100%',
                          },
                        }}
                      >
                        <Booking
                          booking={booking}
                          cancelBooking={cancelBooking}
                          paymentStatus={b.paymentState}
                        />
                      </Flex>
                    ));
                  })}
                </Flex>
              </InfiniteScroll>
            </Flex>
          </>
        );
      };

      return (
        <Card>
          <Styled.Header
            as="h2"
            fontSize={['22px', '22px', '22px', '22px']}
            fontWeight="bold"
          >
            {t('My bookings')}
          </Styled.Header>

          <Styled.List>
            {fetchingBookings ? <Preloader h={100} /> : renderBookings()}
          </Styled.List>
        </Card>
      );
    },
  ),
);

MyBookings.propTypes = {
  t: PropTypes.func.isRequired,
  isPremium: PropTypes.bool.isRequired,
};

export default withTranslation('account')(MyBookings);
