import moment from 'moment-timezone';

const { REACT_APP_TZ: TZ } = process.env;

export const BOOKING_HOURS = (start, end) => {
  const hours = [];
  for (let index = start; index <= end; index += 0.5) {
    const hour = moment.utc(index * 3600 * 1000).format('HH:mm');
    hours.push({
      label: hour,
      value: hour,
    });
  }
  return hours;
};

export const determineBookingsHoursSearch = () => {
  const EARLIEST_HOUR = 8;
  const LATEST_HOUR = 1630;
  const now = moment.tz(TZ);
  const minutes = now.minutes();
  const hours = now.hours();

  if (Number(now.format('HHmm')) >= LATEST_HOUR || hours < EARLIEST_HOUR) {
    return {
      from: '08:00',
      to: '10:00',
      date: moment
        .tz(TZ)
        .add({ days: hours < EARLIEST_HOUR ? 0 : 1 })
        .format('YYYY-MM-DD'),
    };
  }
  const from = moment
    .tz(TZ)
    .startOf('day')
    .add({ hours, minutes: minutes < 30 ? 30 : 60 });
  const to = moment.tz(from, TZ).add({ hours: 1 });
  return {
    from: from.format('HH:mm'),
    to: to.format('HH:mm'),
  };
};

export const formatDateTimeForAPI = (date, time) => {
  const t = time === '00:00' ? '24:00' : time;

  return moment(`${date} ${t}`, 'DD.MM.YYYY HH:mm').format(
    'YYYY-MM-DDTHH:mm:ss',
  );
};

export const formatDateFromAPI = (date, format = 'DD.MM.YYYY') =>
  // moment(date).format(format);
  // force time zone to warsaw
  moment(date)
    .tz('Europe/Warsaw')
    .format(format);
