import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, ...rest }) => {
  const imageUrl = useMemo(() => `${process.env.REACT_APP_API_URL}/${src}`, [
    src,
  ]);
  return <img src={imageUrl} alt={alt} {...rest} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default memo(Image);
