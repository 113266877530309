import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass';
import { withTranslation } from 'react-i18next';
import TextInput from 'components/forms/TextInput';
import MainButton from 'components/MainButton';
import { breakpoint } from 'styles/breakpoints';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from 'utils/formValidators';
import { SecondaryTitle, ContactForm } from 'styles/globalStyles';
import * as Styled from '../style';

const FormContact = ({ onSubmit, t, subject }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{
      subject,
    }}
    render={({ handleSubmit, submitError }) => (
      <ContactForm onSubmit={handleSubmit}>
        <SecondaryTitle>{t('forms:contactForm')}</SecondaryTitle>
        <Styled.NameEmailWrapper>
          <Field
            label={t('Name')}
            name="name"
            type="text"
            component={TextInput}
            fullWidth="50%"
          />
          <Field
            label={t('Email')}
            name="emailAddress"
            type="email"
            component={TextInput}
            validate={composeValidators(required, mustBeValidEmail)}
            fullWidth="50%"
            pl="10px"
          />
        </Styled.NameEmailWrapper>
        <Styled.NameEmailWrapper>
          <Field
            label={t('Phone')}
            name="phone"
            type="phone"
            component={TextInput}
            fullWidth="50%"
          />
          <Field
            label={t('City')}
            name="city"
            type="text"
            validate={required}
            component={TextInput}
            fullWidth="50%"
            pl="10px"
          />
        </Styled.NameEmailWrapper>
        <Field
          label={t('Subject')}
          name="subject"
          type="text"
          component={TextInput}
        />
        <Field
          label={t('Message')}
          name="message"
          type="textarea"
          validate={required}
          component={TextInput}
        />
        {submitError && <div>{submitError}</div>}
        <Box pt={20} width={1}>
          <MainButton
            width={192}
            sx={{
              [`@media ${breakpoint.xs}`]: {
                display: 'block !important',
              },
            }}
            type="submit"
          >
            {t('buttons:Send')}
          </MainButton>
        </Box>
      </ContactForm>
    )}
  />
);

FormContact.defaultProps = {
  subject: null,
};

FormContact.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  subject: PropTypes.string,
};

export default withTranslation('forms')(FormContact);
