import styled from '@emotion/styled';
import { PreWrappedBox } from 'styles/globalStyles';
import { withTranslation } from 'react-i18next';
import { breakpoint } from 'styles/breakpoints';

export const ContactInfoPreWrappedBox = styled(PreWrappedBox)`
  line-height: 24px;
  text-align: left;
  white-space: inherit;
  margin-bottom: 15px;
  margin-top: 30px;

  h3 {
    font-weight: bold;
  }

  ul {
    list-style: disc;
    padding-left: 30px;
  }

  ol {
    list-style: decimal;
    padding-left: 30px;
  }

  a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }

  @media ${breakpoint.md} {
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export default withTranslation('contactInfo')(ContactInfoPreWrappedBox);
