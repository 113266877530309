import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';

export const Error = styled.div`
  color: #f45a5a;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const Position = styled(Box)`
  position: ${props =>
    props.position === 'absolute' ? 'absolute' : 'relative'};
  ${props =>
    props.position === 'absolute' &&
    `
    bottom: -22px;
    min-width: 200px;
  `}
`;

export const RoomsForm = styled.form`
  width: 100%;
  max-width: 1100px;

  .form-error {
    position: absolute;
  }
`;

const Icon = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
`;

const FormError = ({ meta, marginTop, marginLeft, position, ...props }) => {
  if (
    (meta.modified || meta.submitFailed) &&
    (meta.error || meta.submitError)
  ) {
    return (
      <Position
        marginTop={marginTop}
        marginLeft={marginLeft}
        position={position}
      >
        <Error {...props} className="form-error">
          <Icon src="/images/icons/error.svg" alt="error" />
          {meta.error || meta.submitError[0]}
        </Error>
      </Position>
    );
  }

  return null;
};

FormError.defaultProps = {
  marginTop: 0,
  marginLeft: 0,
};

FormError.propTypes = {
  meta: PropTypes.object.isRequired,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

export default FormError;
