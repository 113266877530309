import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Box, Heading } from 'rebass';
import { Form, Field } from 'react-final-form';
import TextInput from 'components/forms/TextInput';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from 'utils/formValidators';
import MainButton from 'components/MainButton';
import * as Styled from '../style';

const SignInForm = ({ onSubmit, t }) => (
  <div>
    <Heading fontSize="22px" fontWeight="medium" mb={3}>
      {t('Sign In')}
    </Heading>

    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Field
            label={t('forms:Email')}
            name="email"
            type="text"
            component={TextInput}
            validate={composeValidators(required, mustBeValidEmail)}
          />
          {/* <LoginLayoutAction
            linkText={t('Forgot password?')}
            to={routes.auth.forgotPassword}
            positionabs
          /> */}
          <Field
            label={t('forms:Password')}
            name="password"
            type="password"
            component={TextInput}
            validate={required}
          />
          {submitError && <div>{submitError}</div>}
          <Box pt={20} width={1}>
            <MainButton width="100%" mb={15} type="submit">
              {t('forms:Login')}
            </MainButton>
          </Box>
        </form>
      )}
    />

    <Styled.Or>
      <span>{t('or')}</span>
    </Styled.Or>
  </div>
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('booking')(SignInForm);
