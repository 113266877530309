/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form, Field } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import MainButton from 'components/MainButton';
import Preloader from 'components/Preloader';
import Checkbox from 'components/Checkbox';
import { required } from 'utils/formValidators';
import AddressForm from '../../account/components/AddressForm';
import { FormTitle, Description } from './style';

const BuyModal = inject('promotionsStore')(
  observer(
    ({
      billingAddress,
      validityInDays,
      promotionsStore: { isLoading, buyPromotion },
      promotionId,
    }) => {
      const { t } = useTranslation('promotions');

      return (
        <div>
          <FormTitle
            as="h3"
            fontWeight="medium"
            fontSize={['16px', '16px', '16px', '24px']}
          >
            {t('Buy discount')}
          </FormTitle>

          <Description>{t('buy.promotion.modal.description')}</Description>

          <Form
            onSubmit={async v => {
              await buyPromotion(promotionId, v, validityInDays);
            }}
            initialValues={{ billingAddress }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {(submitting || isLoading) && <Preloader cover />}
                <AddressForm
                  showTypeSelector
                  accountType={values.billingAddress.type}
                />

                <Field
                  label={
                    <Trans i18nKey="forms:termsAndConditions">
                      Creating an account means you’re okay with our{' '}
                      <RouterLink to="/regulations">Terms of Service</RouterLink>
                      and <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>.
                    </Trans>
                  }
                  name="terms"
                  type="checkbox"
                  component={Checkbox}
                  validate={required}
                />
                <Field
                  label={t('buy.promotion.modal.statement')}
                  name="statement"
                  type="checkbox"
                  component={Checkbox}
                  validate={required}
                />
                <MainButton
                  width="100%"
                  mb={15}
                  mt={30}
                  type="submit"
                  disabled={submitting}
                >
                  {t('Buy')}
                </MainButton>
              </form>
            )}
          />
        </div>
      );
    },
  ),
);

BuyModal.defaultProps = {
  billingAddress: {},
  promotionId: null,
  validityInDays: 0,
};

BuyModal.propTypes = {
  billingAddress: PropTypes.shape({}),
  promotionId: PropTypes.string,
  validityInDays: PropTypes.number,
};

export default BuyModal;
