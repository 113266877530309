import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

const customStyles = promotionSelect => ({
  container: provided => ({
    ...provided,
    flex: 1,
    width: promotionSelect && '100px',
    '@media (min-width: 320px) and (max-width: 769px)': {
      margin: promotionSelect && 'auto',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    textAlign: 'left',
    color: state.isSelected || state.isFocused ? '#000' : '#000',
    backgroundColor: state.isFocused ? 'none' : 'none',
    paddingVertical: 5,
    paddingHorizontal: 15,
    paddingBottom: 8,
    ':hover': {
      backgroundColor: 'rgba(255,255,255,.3)',
    },
    ':active': {
      backgroundColor: 'none',
    },
  }),
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: '#fff',
    flex: 1,
    flexWrap: 'nowrap',
    minHeight: promotionSelect && 'none',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    background: '#f4b78a',
    margin: '0 -10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    borderRadius: 5,
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#573B27',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#573B27',
    },
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '0px 0px 3px 3px',
    zIndex: 10,
    boxShadow: 'none',
    marginTop: '0',
    marginBottom: '0',
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: '100px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    color: '#B06C3B',
    '&:hover': {
      color: '#B06C3B',
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: promotionSelect ? '#000' : '#FFF',
    position: 'relative',
    transform: 'none',
    top: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
  }),
  singleValue: provided => ({
    ...provided,
    color: promotionSelect ? '#000' : '#fff',
  }),
  indicatorContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    // width: 30,
    padding: 0,
    fontSize: 15,
    overflow: 'visible',
    position: 'relative',
    '> input': { position: 'absolute' },
  }),
});

const Select = ({
  value,
  options,
  onChange,
  placeholder,
  promotionSelect,
  isDisabled,
  className,
}) => {
  const getSelectedValue = () =>
    options.filter(option => option.value === value);

  return (
    <ReactSelect
      className={className}
      options={options}
      onChange={({ value: selectedValue }) => onChange(selectedValue)}
      styles={customStyles(promotionSelect)}
      isSearchable={false}
      hideSelectedOptions
      value={getSelectedValue(value)}
      placeholder={placeholder || ''}
      noOptionsMessage={null}
      isDisabled={isDisabled}
    />
  );
};

Select.defaultProps = {
  value: null,
  className: null,
};

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default Select;
