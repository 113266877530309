import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';

import FoundRoomTile from 'components/FoundRoomTile';
import { LineSeparator } from 'styles/globalStyles';
import { imagePath } from 'utils/imagePathHelper';
import Preloader from 'components/Preloader';

import SimilarRooms from 'components/SimilarRooms';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import Layout from 'components/Search/Layout';
import routes from 'routes';
import i18n from 'i18n';
import * as Styled from './style';

@withRouter
@inject('authStore', 'searchStore')
@observer
class SearchPage extends Component {
  state = {};

  componentDidMount = async () => {
    await this.fetchResources();
  };

  componentDidUpdate = async prevProps => {
    const {
      location: { search },
    } = this.props;
    if (!isEqual(prevProps.location.search, search)) {
      await this.fetchResources();
    }
  };

  fetchResources = async () => {
    const {
      searchStore: { fetchRooms },
    } = this.props;
    await fetchRooms();
  };

  render() {
    const {
      searchStore: { roomsList, isLoading },
      location: { search },
      t,
      authStore: { isAuthenticated },
    } = this.props;
    const query = queryString.parse(search);

    return (
      <Layout>
        {isLoading && <Preloader h={200} />}

        {roomsList.length === 0 && !isLoading && (
          <Text mt={20} mb={20} fontSize={20}>
            {t(
              'No rooms found! Please search for a different time or date, or contact us',
            )}
          </Text>
        )}

        {roomsList.length > 0 && !isLoading && (
          <Styled.Row pt={[29, 29, 29, 29, 57]} pb={[29, 29, 29, 29, 57]}>
            {roomsList.map((room, index) => {
              let { name } = room;
              if (room[i18n.language] && room[i18n.language].name) {
                name = room[i18n.language].name;
              }
              const image =
                room.images[room.primaryImageIndex].thumbnails.small ||
                room.images[room.primaryImageIndex].src;
              return (
                <FoundRoomTile
                  key={index}
                  image={imagePath(image)}
                  title={name}
                  street={room.location.street}
                  size={room.surfaceArea}
                  pricing={room.priceData || {}}
                  to={`${routes.description(room.slug)}${search}`}
                  isAuthenticated={isAuthenticated}
                />
              );
            })}
          </Styled.Row>
        )}

        {/* <LineSeparator /> */}
        {!isEmpty(query) && roomsList && roomsList.length === 0 && (
          <>
            <LineSeparator />
            <SimilarRooms query={query} />
          </>
        )}
      </Layout>
    );
  }
}

export default withTranslation()(SearchPage);
