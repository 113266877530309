import styled from '@emotion/styled';
import { Box, Text } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

export const LineSeparator = styled(Box)`
  border-bottom: 1px solid #dcdcdc;
`;

export const Quote = styled(Text)`
  position: relative;
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ${breakpoint.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
