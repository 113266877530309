import { extendObservable, action } from 'mobx';
import { API, APIRoutes } from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { authStore } from 'stores';
import { apiErrorMapper } from 'utils/formValidators';

const initialState = {
  initialized: false,
  isLoading: false,
  fetchingBookings: false,
  fetchingMoreBookings: false,
  error: null,
  profile: {},
  bookings: [],
  orders: [],
  hasMore: false,
  nextLink: '',
  hideDiscountBanner: false,
  userPromotions: [],
  boughtExclusivePromotion: false,
};

export class UserStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  getFBDiscount = async () => {
    try {
      await API.post(APIRoutes.discounts.fb);
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  @action
  fetchProfile = async () => {
    if (!authStore.isAuthenticated) return;
    this.isLoading = true;
    try {
      const {
        data: { user },
      } = await API(APIRoutes.user.profile);
      this.profile = user;
      this.initialized = true;
      this.userPromotions = user.promotions.map(p => {
        if (p.is_exclusive) {
          this.boughtExclusivePromotion = true;
        }
        // eslint-disable-next-line no-underscore-dangle
        return p._id;
      });
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  updateProfile = async profileData => {
    this.isLoading = true;
    try {
      const {
        data: { user },
      } = await API.patch(APIRoutes.user.update, profileData);
      this.profile = { ...this.profile, ...user };
    } catch (e) {
      this.error = e;

      if (e.errors) {
        return apiErrorMapper(e.errors);
      }
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  fetchBookings = async fetchAddress => {
    this.fetchingBookings = true;
    try {
      const {
        data: { items: bookings, links },
      } = await API(APIRoutes.user.bookings);
      const {
        data: { items: orders },
      } = await API(APIRoutes.user.orders);
      this.nextLink = links && links.next;
      this.hasMore = !!this.nextLink;
      this.bookings = bookings;
      this.orders = orders;
      if (fetchAddress) {
        await Promise.all(
          this.bookings.map(async booking => {
            await Promise.all(
              booking.bookings.map(async bookingEl => {
                if (bookingEl.bookableObject) {
                  const {
                    data: { data },
                  } = await API(
                    `${APIRoutes.addresses.details(
                      // eslint-disable-next-line no-underscore-dangle
                      bookingEl.bookableObject._addressId,
                    )}`,
                  );

                  const bookableObjectFullAddres = `${data.city}, ${data.street}`;
                  bookingEl.fullAddress = bookableObjectFullAddres;
                }
              }),
            );
          }),
        );
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.fetchingBookings = false;
    }
  };

  @action
  fetchMoreBookings = async () => {
    if (!this.hasMore && this.nextLink) {
      return;
    }
    if (!this.nextLink) {
      this.hasMore = false;
      return;
    }
    if (this.fetchingMoreBookings) {
      return;
    }
    this.fetchingMoreBookings = true;
    try {
      const {
        data: { items, links },
      } = await API(this.nextLink);
      this.nextLink = links && links.next;
      this.hasMore = !!this.nextLink;
      this.bookings = [...this.bookings, ...items];
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.fetchingMoreBookings = false;
    }
  };

  @action
  reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };

  @action
  deleteProfile = async (profile, callback) => {
    this.isLoading = true;
    try {
      await API.delete(APIRoutes.user.deleteProfile, profile);
      authStore.logout();
      toast.info(i18n.t('toastify:Your account has been deleted.'));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new UserStore();
