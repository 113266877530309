import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { formatDateFromAPI } from 'utils/datetimeHelpers';
import Modal from 'components/Modal';
import i18n from 'i18n';
// import InfiniteScroll from 'react-infinite-scroller';
import CancelBookingModal from '../CancelBookingModal/index';
import * as Styled from './style';

const Item = ({ booking, cancelBooking, t, paymentStatus }) => {
  const [cancelBookingModal, setCancelBooking] = useState(false);

  return (
    <Styled.Item alignItems="center" justifyContent="space-between">
      <Styled.Left>
        <Styled.Title>
          <span>
            <Styled.RoomName>{booking.shortCode}</Styled.RoomName>
            <Styled.ArrowBorder />
          </span>
        </Styled.Title>

        <Styled.Column width={['100%', '100%', '100%', '15%']}>
          <Styled.ColumnLabel>{t('booking:rentDate')}</Styled.ColumnLabel>
          <Styled.ColumnDate>
            {formatDateFromAPI(booking.from, 'dddd[\r\n]D MMMM YYYY')}
          </Styled.ColumnDate>
        </Styled.Column>

        <Styled.Column width={['100%', '100%', '100%', 'auto']}>
          <Styled.ColumnLabel>{t('booking:Hours')}</Styled.ColumnLabel>
          <Styled.ColumnValue>
            {formatDateFromAPI(booking.from, 'HH:mm')}{' '}
            <span>{t('booking:to')}</span>{' '}
            {formatDateFromAPI(booking.to, 'HH:mm')}
          </Styled.ColumnValue>
        </Styled.Column>

        <Styled.Column width={['100%', '100%', '100%', '20%']}>
          <Styled.ColumnLabel>{t('booking:Localization')}</Styled.ColumnLabel>
          <Styled.ColumnValue>{booking?.fullAddress || '-'}</Styled.ColumnValue>
        </Styled.Column>

        <Styled.Column width={['100%', '100%', '100%', '10%']}>
          <Styled.ColumnLabel>{t('booking:Room')}</Styled.ColumnLabel>
          <Styled.ColumnValue>
            {(booking.bookableObject &&
              ((booking.bookableObject[i18n.language] &&
                booking.bookableObject[i18n.language].name &&
                booking.bookableObject[i18n.language].name) ||
                booking.bookableObject.name)) ||
              '-'}
          </Styled.ColumnValue>
        </Styled.Column>

        <Styled.Column
          sx={{
            '@media (max-width: 950px)': { minWidth: '200px' },
          }}
          width={['100%', '100%', '100%', 'auto']}
        >
          <Styled.ColumnLabel>{t('booking:Payment status')}</Styled.ColumnLabel>
          <Styled.ColumnValue>
            {t(`paymentStatus:${paymentStatus}`)}
          </Styled.ColumnValue>
        </Styled.Column>
      </Styled.Left>

      {booking.bookableObject && (
        <>
          <Styled.Btn
            ml={[0, 0, '10px', '10px', '10px']}
            sx={{
              minWidth: [0, 0, '196.5px!important', '196.5px!important'],
              '@media (min-width: 950px)': {
                alignSelf: 'flex-start !important',
              },
            }}
            type="button"
            variant="secondary"
            onClick={() => setCancelBooking(true)}
            disabled={!booking.canBeCancelled}
          >
            {booking.isCancelled
              ? t('booking.cancelled')
              : t('booking.cancelRes')}
          </Styled.Btn>

          <Modal
            opened={cancelBookingModal}
            close={() => {
              setCancelBooking(false);
            }}
          >
            <CancelBookingModal
              // eslint-disable-next-line no-underscore-dangle
              bookingId={booking._id}
              cancelBookingModal={cancelBookingModal}
              roomName={booking.bookableObject.name}
              cancelBooking={cancelBooking}
            />
          </Modal>
        </>
      )}
    </Styled.Item>
  );
};

Item.propTypes = {
  booking: PropTypes.shape({
    _id: PropTypes.string,
    _bookableObjectId: PropTypes.string,
    _orderId: PropTypes.string,
    originalFrom: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    priceData: PropTypes.shape({
      price: PropTypes.number,
      discount: PropTypes.object,
      priceWithoutDiscount: PropTypes.number,
      currency: PropTypes.string,
      isFallbackPrice: PropTypes.bool,
    }),
    description: PropTypes.string,
    numberOfUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookableObject: PropTypes.object,
    isBookingEditable: PropTypes.bool,
    availableDateRanges: PropTypes.array,
  }).isRequired,
  t: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string.isRequired,
};

export default withTranslation('account')(Item);
