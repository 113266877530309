import styled from '@emotion/styled';
import { Flex, Button } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

export const ButtonsWrapper = styled(Flex)`
  padding: 15px 0 15px;
  flex-direction: column;
  gap: 10px;
  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const SecondaryButton = styled(Button)`
  padding: 13px 20px 13px 20px;
  @media ${breakpoint.md} {
    padding: 10px 40px;
    margin-left: 30px;
  }
`;
