import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const BaseFormInput = styled.input`
  display: block;
  height: 100%;
  background-color: ${props => props.bgColor};
  color: #000000;
  outline: none;
  font-size: 16px;
  width: 100%;
  border: none;
  &:hover {
    cursor: pointer;
  }
  background-image: url('/images/expandIcon.svg');
  background-repeat: no-repeat;
  background-position: right 10px bottom 50%;
  background-size: 15px 15px;
  &::placeholder {
    color: #000000 !important;
  }
  @media ${breakpoint.xs} {
    padding: 14px 11px;
    border-radius: 0;
    border-radius: 3px;
  }
  @media ${breakpoint.md} {
    border-radius: 0 3px 3px 0;
    padding: 20px 11px;
  }
`;
