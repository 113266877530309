import React from 'react';
import { withTranslation } from 'react-i18next';

import { Container, MainTitle } from 'styles/globalStyles';
import { PivacyPolicyPreWrappedBox } from './style';

const PrivacyPolicyPage = ({ t }) => (
  <Container>
    <MainTitle as="h1">{t('Privacy Policy')}</MainTitle>
    <PivacyPolicyPreWrappedBox
      dangerouslySetInnerHTML={{ __html: t('content') }}
    />
  </Container>
);

export default withTranslation('privacyPolicy')(PrivacyPolicyPage);
