import { extendObservable, action } from 'mobx';
import { API, APIRoutes } from 'api';
// import { toast } from 'react-toastify';
// import i18n from 'i18n';
// import { authStore } from 'stores';

const initialState = {
  isLoading: false,
  isLoadingBlogPost: false,
  initialized: false,
  blogPostInitialized: false,
  error: null,
  meta: {},
  blogPosts: [],
  blogPost: {},
};

export class BlogStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  resetBlogPost = () => {
    this.blogPost = {};
    this.blogPostInitialized = false;
  };

  @action
  fetchBlogPost = async slug => {
    this.isLoadingBlogPost = true;
    try {
      const {
        data: { data },
      } = await API.get(APIRoutes.blogPost(slug));
      this.blogPost = data;
    } catch (error) {
      // eslint-disable-next-line
      console.debug(error);
    } finally {
      this.isLoadingBlogPost = false;
      this.blogPostInitialized = true;
    }
  };

  @action
  fetchBlogPosts = async () => {
    this.isLoading = true;
    try {
      const {
        data: { data, links, totalCount },
      } = await API.get(APIRoutes.blogPosts);
      this.blogPosts = data;
      this.meta = { ...links, totalCount };
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.isLoading = false;
      this.initialized = true;
    }
  };

  @action
  reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };
}

export default new BlogStore();
