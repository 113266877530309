import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const Cover = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  z-index: 1001;
  position: fixed;
  width: 90%;
  max-width: 1000px;
  top: 50%;
  left: 50%;
  transform: scale(0) translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 15px;
  max-height: 90vh;
  overflow-y: auto;

  @media ${breakpoint.md} {
    padding: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media ${breakpoint.md} {
    max-height: unset;
  }
`;

export const Close = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: transparent url('/images/icons/close-dark.svg') no-repeat 50% 50% /
    contain;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
