import styled from '@emotion/styled';
import { Heading, Flex, Box, Button } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

export const Header = styled(Heading)`
  margin-bottom: 30px;
`;

export const List = styled.div``;

export const Item = styled(Flex)`
  padding-bottom: 15px;
  border-bottom: 1px solid #c6c6c6;
  flex-direction: column;
  width: 100%;

  & + & {
    margin-top: 15px;
  }

  @media ${breakpoint.md} {
    flex-direction: row;
    padding-bottom: 30px;
    & + & {
      margin-top: 25px;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 113.69%;
  margin-bottom: 10px;

  > span {
    display: inline-block;
    position: relative;
    max-width: 100%;
  }

  @media ${breakpoint.md} {
    width: 20%;
    font-size: 24px;
    margin-bottom: 0;
  }
`;
export const RoomName = styled.div`
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  position: relative;
  display: inline-block;
`;

export const ArrowBorder = styled.div`
  position: absolute;
  bottom: -10px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: none;

  &:after,
  &:before {
    position: absolute;
    right: 0;
    width: 6px;
    height: 1px;
    content: '';
    display: block;
    transform: rotate(-45deg);
    background-color: #000;
    right: -1px;
    top: 2px;
  }

  &:before {
    transform: rotate(45deg);
    top: -2px;
  }

  @media ${breakpoint.md} {
    display: block;
  }
`;

export const Column = styled(Box)`
  font-size: 13px;
  display: flex;
  margin-left: 0;
  flex-shrink: 0;

  @media ${breakpoint.xs} {
    justify-content: space-between;
  }

  @media (min-width: 700px) and (max-width: 768px) {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${breakpoint.md} {
    &:last-child {
      justify-content: flex-end;
    }
    display: block;
    margin-left: 25px;
    font-size: 18px;
  }

  @media ${breakpoint.md} and (max-width: 1140px) {
    margin-left: 30px;
  }

  @media ${breakpoint.md} and (max-width: 950px) {
    margin-left: 15px;
    font-size: 14px;
  }
`;

export const ColumnLabel = styled.div`
  margin-bottom: 15px;

  @media ${breakpoint.md} {
    margin-bottom: 10px;
  }
`;

export const ColumnValue = styled.div`
  color: #d9a248;
  margin-left: 10px;

  > span {
    color: #000;
  }

  @media ${breakpoint.xs} {
    text-align: right;
  }

  @media ${breakpoint.md} {
    text-align: left;
    margin-left: 0;
  }
`;

export const ColumnDate = styled(ColumnValue)`
  @media ${breakpoint.md} {
    white-space: pre;
  }
`;

export const Left = styled(Flex)`
  flex: 1 0 100%;
  flex-wrap: wrap;
  width: 100%;
  order: 1;

  @media ${breakpoint.md} {
    flex: 1 0 auto;
    flex-wrap: nowrap;
  }
`;

export const Btn = styled(Button)`
  border-radius: 4px;
  order: 1;
  font-size: 10px;
  line-height: 14px;
  align-self: flex-end;
  padding: 5px 7px;
  position: relative;
  z-index: 2;
  min-width: 80px;
  margin-right: ${props => props.space};
  align-self: center;

  @media ${breakpoint.md} {
    align-self: auto;
    margin-bottom: 0;
    padding: 12px ${props => props.theme.space[3]}px;
    margin-right: 0;
    order: 2;
    font-size: 16px;
    line-height: 18px;
    min-width: 138px;
  }
`;

export const CancelInfo = styled.div`
  margin-bottom: 22px;
  font-size: 14px;
  @media ${breakpoint.md} {
    font-size: 18px;
  }
`;
