import React, { Component } from 'react';
import { Flex, Box, Image, Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Container, MainTitle } from 'styles/globalStyles';
import { LineSeparator, Quote, QI } from './style'; // eslint-disable-line

const AboutUsWrapper = styled(Container)`
  ol {
    list-style: decimal;
    padding-left: 30px;
  }
  a {
    color: #575757;
    text-decoration: none;
  }
`;

class PercentPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <AboutUsWrapper>
        <MainTitle>{t('Tax Deduction - 1% Donation')}</MainTitle>

        <Flex flexWrap="wrap" mb={[4, 4, 4, 4, 6]}>
          <Box width={[1, 1, 1, 1, 1 / 3]} pr={4} mb={[4, 4, 4, 4, 0]}>
            <Text
              color="muted"
              as="p"
              textAlign="justify"
              fontSize={[3, 3, 3, 3, 4]}
              lineHeight={['23px', '23px', '23px', '23px', '28px']}
              dangerouslySetInnerHTML={{ __html: t('row-1.p1') }}
            />
          </Box>
          <Box width={[1, 1, 1, 1, 2 / 3]}>
            <Image
              src="/images/static-pages/percent.png"
              sx={{
                width: '100%',
              }}
            />
          </Box>
        </Flex>
      </AboutUsWrapper>
    );
  }
}

export default withTranslation('percent')(PercentPage);
