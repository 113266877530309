import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
import routes from 'routes';
import Modal from 'components/Modal';
import BuyModal from './BuyModal';
import PromotionsList from './Promotions';
import * as Styled from './style';

const AlreadyPremium = ({ profile, t }) => (
  <span>
    {t('Your PREMIUM account is active!')}{' '}
    <Styled.SmallText>
      ( {t('package is valid to')}
      {moment(profile.premiumTo).format('DD.MM.YYYY HH:mm')} )
    </Styled.SmallText>
  </span>
);

const BecomePremium = ({ t, onClick }) => (
  <>
    <MainButton mb={30} type="button" onClick={onClick}>
      {t('Buy premium!')}
    </MainButton>
    <Styled.Link to={routes.premium}>
      {t("Don't know the benefits of a premium account? Get to know them now")}
    </Styled.Link>
  </>
);

const PremiumSection = ({ profile, promotions }) => {
  const { t } = useTranslation('account');
  const [modalIsVisible, setModalVisibility] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    setModalVisibility(!!state?.selectedPlan);
  }, [state]);

  return (
    <>
      <Styled.Wrapper>
        <Styled.FormTitle
          as="h2"
          fontWeight="medium"
          fontSize={['16px', '16px', '16px', '22px']}
        >
          {t('Premium')} <img src="/images/icons/diamond.svg" alt="PREMIUM" />
        </Styled.FormTitle>

        {profile.isPremium ? (
          <AlreadyPremium profile={profile} t={t} />
        ) : (
          <BecomePremium t={t} onClick={() => setModalVisibility(true)} />
        )}

        {profile.isPremium ? (
          <PromotionsList
            myPromotions={profile.promotions}
            promotions={promotions}
          />
        ) : null}
      </Styled.Wrapper>
      <Modal
        opened={modalIsVisible}
        close={() => {
          setModalVisibility(false);
        }}
      >
        <BuyModal
          billingAddress={profile.billingAddress || {}}
          selectedPlan={state?.selectedPlan}
        />
      </Modal>
    </>
  );
};

PremiumSection.defaultProps = {
  promotions: [],
};

PremiumSection.propTypes = {
  profile: PropTypes.shape({
    canFacebookShareDiscountBeGranted: PropTypes.bool,
    email: PropTypes.string,
    isPremium: PropTypes.bool,
    locale: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    premiumTo: PropTypes.string,
    promotions: PropTypes.array,
    role: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  promotions: PropTypes.array,
};

export default PremiumSection;
