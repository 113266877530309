/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';
import styled from '@emotion/styled';
import MainButton from 'components/MainButton';
import { breakpoint } from 'styles/breakpoints';
import { timeParser } from 'utils/staticValues';
import Select from 'components/Select';

const Promotion = styled.li`
  opacity: ${({ bought }) => (bought ? 0.5 : 1)};
  /* display: flex;
  justify-content: space-between; */
  padding: 15px;
  align-items: flex-start;
  flex-direction: column;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

  & + li {
    margin-top: 15px;
  }

  > button {
    width: 100%;
  }

  @media ${breakpoint.md} {
    flex-direction: row;

    button {
      margin-top: 13px;
    }
  }
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: #4e2a0f;
  margin-bottom: 5px;
`;

const Col = styled.div`
  font-size: ${({ large }) => (large ? 20 : 16)}px;
  flex: ${({ flex }) => flex || 1};
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  border: 1px #f4b78a solid;
  padding: 10px;
  border-radius: 3px;

  .select {
    width: 100%;
  }

  ${Label} {
    background-color: #f4b78a;
    margin: -10px -10px 15px;
    padding: 10px;
  }


  @media ${breakpoint.md} {
    /* padding-top: ${({ large }) => (large ? '13px' : 0)}; */
    width: unset;
    margin-bottom: 0;
    text-align: left;
    b {
      padding-right: 15px;
    }
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 18px;

  @media ${breakpoint.md} {
    padding-right: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

const Details = styled.div`
  margin-bottom: 30px;

  > h2 {
    text-transform: uppercase;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }
`;

export const SinglePromotion = ({
  promotion,
  userPromotions,
  amount,
  setPromotionId,
  setValidityInDays,
  boughtExclusivePromotion,
}) => {
  const { t } = useTranslation('promotions');
  const [selectPrice, setSelectPrice] = useState('-');
  const [selectedDuration, setselectedDuration] = useState(
    promotion.pricings.length === 1
      ? promotion.pricings[0].validity_in_days
      : 0,
  );

  const promotionDuration = pricings => {
    let pricingsInDropdown;
    const sortedByDuration = orderBy(pricings, 'validity_in_days', 'asc');
    if (pricings) {
      pricingsInDropdown = sortedByDuration.map(price => ({
        value: price.validity_in_days,
        label: `${price.validity_in_days} ${t('days')} `,
      }));
    }

    return pricingsInDropdown;
  };

  const onDurationChangeHandler = v => {
    setselectedDuration(v || 0);
    setSelectPrice(
      promotion.pricings.find(p => p.validity_in_days === v)?.price || '-',
    );
  };

  const setPromotionValues = (promotionId, validityInDays) => {
    setPromotionId(promotionId);
    setValidityInDays(validityInDays);
  };

  return (
    <Promotion
      key={promotion._id}
      bought={
        userPromotions.indexOf(promotion._id) !== -1 ||
        (userPromotions.length > 0 && promotion.is_exclusive) ||
        boughtExclusivePromotion
      }
    >
      <Details>
        <h2>{promotion.name}</h2>
        <Description>{promotion.description}</Description>
      </Details>

      <Row>
        <Col important={setselectedDuration}>
          <Label>{t('Availability')}:</Label>
          {promotion && promotion.pricings && (
            <>
              {promotion.pricings.length === 1 ? (
                <>
                  {
                    // eslint-disable-next-line camelcase
                    promotion?.pricings[0].validity_in_days
                  }{' '}
                  {t('days')}
                </>
              ) : (
                <Select
                  isDisabled={
                    userPromotions.indexOf(promotion._id) !== -1 ||
                    (userPromotions.length > 0 && promotion.is_exclusive) ||
                    boughtExclusivePromotion
                  }
                  promotionSelect
                  options={promotionDuration(promotion?.pricings)}
                  onChange={onDurationChangeHandler}
                  placeholder={t('Select duration')}
                  value={selectedDuration}
                  className="select"
                />
              )}
            </>
          )}
        </Col>
        <Col>
          <Label>{t('Discount')}:</Label> {amount()}
        </Col>
        <Col>
          <Label>{t('Limit')}:</Label>
          {timeParser(promotion.max_minutes_per_day)}
        </Col>
        <Col>
          <Label>{t('Price')}:</Label>
          {promotion && promotion.pricings && (
            <>
              {promotion.pricings.length === 1 ? (
                <>{promotion.pricings[0].price / 100}</>
              ) : (
                <>{selectPrice !== '-' ? selectPrice / 100 : selectPrice} </>
              )}
              &nbsp;zł
            </>
          )}
        </Col>
      </Row>

      <MainButton
        type="button"
        onClick={() => setPromotionValues(promotion._id, selectedDuration)}
        disabled={
          userPromotions.indexOf(promotion._id) !== -1 ||
          (userPromotions.length > 0 && promotion.is_exclusive) ||
          boughtExclusivePromotion ||
          !selectedDuration
        }
      >
        {selectedDuration ? t('Buy now') : t('To buy, choose duration')}
      </MainButton>
    </Promotion>
  );
};

export default inject()(observer(SinglePromotion));
