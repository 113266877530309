import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex, Image } from 'rebass';
import { withTranslation } from 'react-i18next';
import { breakpoint } from 'styles/breakpoints';

const Wrapper = styled(Flex)`
  align-items: center;
`;

const Detail = styled.div`
  font-size: 12px;
  margin-left: ${props => props.ml};
  text-align: ${props => props.textAlign};

  @media ${breakpoint.md} {
    font-size: ${({ textSize }) => {
      switch (textSize) {
        case 'big':
          return '18px';
        case 'small':
          return '12px';
        default:
          return '16px';
      }
    }};
  }
`;

const Text = styled.span`
  color: ${props => props.color};
`;

const Icon = styled(Image)`
  margin-right: 12px;
  width: 12px;
  height: 14px;
`;

const Label = styled.span`
  font-weight: 500;
`;

const IconDetail = ({
  color,
  t,
  icon,
  text,
  textSize,
  label,
  ml,
  textAlign,
  ...props
}) => (
  <Wrapper {...props}>
    <Icon src={icon} alt="" />
    <Detail textAlign={textAlign} textSize={textSize} ml={ml}>
      {label && <Label>{t(label)}</Label>}
      <Text color={color}>{text}</Text>
    </Detail>
  </Wrapper>
);

IconDetail.defaultProps = {
  label: null,
  color: '#000',
  textSize: 'normal',
};

IconDetail.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  t: PropTypes.func.isRequired,
  color: PropTypes.string,
  textSize: PropTypes.oneOf(['big', 'normal', 'small']),
};

export default withTranslation()(IconDetail);
