import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass';
import { withTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
import TextInput from 'components/forms/TextInput';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from 'utils/formValidators';
import { MainTitle, LineSeparator, AuthForm } from 'styles/globalStyles';
import { LoginLayoutAction } from 'components/Auth/Layout';
import routes from 'routes';

const SignInForm = ({ onSubmit, t }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError }) => (
      <AuthForm onSubmit={handleSubmit}>
        <MainTitle>{t('titles:Login')}</MainTitle>
        <Field
          label={t('Email')}
          name="email"
          type="text"
          component={TextInput}
          validate={composeValidators(required, mustBeValidEmail)}
        />
        <LoginLayoutAction
          linkText={t('Forgot password?')}
          to={routes.auth.forgotPassword}
          positionabs
        />
        <Field
          label={t('Password')}
          name="password"
          type="password"
          component={TextInput}
          validate={required}
          displayErrorBelow
        />
        {submitError && <div>{submitError}</div>}
        <Box pt={20} width={1}>
          <MainButton width="192px" mb={15} type="submit">
            {t('Login')}
          </MainButton>
        </Box>
        <LineSeparator marginTop="49px" />
        <LoginLayoutAction
          text={t('Not a member?')}
          linkText={t('Sign up now')}
          to={routes.auth.register}
        />
      </AuthForm>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation('forms')(SignInForm);
