import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { breakpoint } from 'styles/breakpoints';
import MainButton from 'components/MainButton';

export const MainButtonSearch = styled(MainButton)`
  border-radius: 0px 3px 3px 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
`;

export const MainButtonShow = styled(MainButtonSearch)`
  border-radius: 3px !important;
  display: ${props =>
    props.showSection ? 'none !important' : 'block !important'};
  width: 100%;

  @media ${breakpoint.md} {
    display: none !important;
  }
`;

export const Row = styled(Flex)`
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
`;

export const FiltersWrapper = styled(Flex)`
  width: 100%;
  display: ${props =>
    props.showSection ? 'block !important' : 'none !important'};

  @media ${breakpoint.md} {
    display: flex !important;
  }
`;

export const ArrowIcon = styled.img`
  padding-left: 10px;
  @media (max-width: 1175px) {
    display: none;
  }
`;
export const ExpandArrowIcon = styled.img`
  transform: rotate(90deg);
`;

export const FiltersForm = styled(Flex)`
  width: 100%;
  flex-direction: column;
  min-width: 260px;
  box-shadow: none;

  @media ${breakpoint.md} {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    flex-direction: row;
  }
`;

export const InputWrapper = styled(Box)`
  @media ${breakpoint.xs} {
    margin-bottom: 13px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }

  @media ${breakpoint.md} {
    margin-bottom: 0;
    box-shadow: none;
    border-left: 1px solid #d3d7dc;
    &:first-of-type {
      border-left: 0;
    }
  }
`;

export const Error = styled.div`
  color: #f45a5a;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 150px;
  padding-top: 10px;

  @media ${breakpoint.xs} {
    justify-content: center;
    padding-right: 0;
  }
`;

export const Icon = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
`;
