import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass';
import { withTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import MainButton from 'components/MainButton';
import TextInput from 'components/forms/TextInput';
import Checkbox from 'components/Checkbox';
import Link from 'components/Link';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from 'utils/formValidators';
import { MainTitle, LineSeparator, ThinForm } from 'styles/globalStyles';
import routes from 'routes';

const SignInForm = ({ onSubmit, t }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError }) => (
      <ThinForm onSubmit={handleSubmit}>
        <MainTitle>{t('titles:Register')}</MainTitle>
        <Field
          label={t('Name')}
          name="name"
          type="text"
          component={TextInput}
          validate={required}
        />
        <Field
          label={t('Email')}
          name="email"
          type="email"
          component={TextInput}
          validate={composeValidators(required, mustBeValidEmail)}
        />
        <Field
          label={t('Password')}
          name="password"
          type="password"
          component={TextInput}
          validate={required}
        />
        <Field
          label={
            <Trans i18nKey="forms:termsAndConditions">
              Creating an account means you’re okay with our{' '}
              <RouterLink to="/regulations">Terms of Service</RouterLink>
              and <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>.
            </Trans>
          }
          name="terms"
          type="checkbox"
          component={Checkbox}
          validate={required}
        />
        {submitError && <div>{submitError}</div>}
        <Box pt={20} width={1}>
          <MainButton width="192px" mb={15} type="submit">
            {t('Register')}
          </MainButton>
        </Box>
        <LineSeparator marginTop="49px" />
        <Link
          description={t('Already a member?')}
          text={t('Sign in now')}
          to={routes.auth.signIn}
        />
      </ThinForm>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation('forms')(SignInForm);
