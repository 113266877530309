/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  Wrapper,
  PostImage,
  ReadMore,
} from 'containers/Blog/components/Post/style';
import { NotPublishedBadge } from 'styles/globalStyles';
import routes from 'routes';

const Post = ({ title, slug, shortDescription, image, isPublished }) => (
  <Wrapper to={routes.blogPost(slug)}>
    {!isPublished ? <NotPublishedBadge /> : null}
    <PostImage src={image.thumbnails.big} alt="" />
    <h2>{title}</h2>
    <Content>{shortDescription}</Content>
    <ReadMore>czytaj więcej</ReadMore>
  </Wrapper>
);

Post.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    thumbnails: PropTypes.shape({
      big: PropTypes.string,
      mini: PropTypes.string,
      small: PropTypes.string,
    }),
  }).isRequired,
};

export default memo(Post);
