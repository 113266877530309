import styled from '@emotion/styled';
import { Heading, Flex } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled.div`
  margin: 0 0 30px;
  padding: 20px 30px;
  max-width: unset;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #fff url('/images/icons/diamond-gray.svg') no-repeat right 0
    bottom -90px / 350px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

  @media ${breakpoint.md} {
    padding: 40px 45px;
  }
`;

export const FormTitle = styled(Heading)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 24px;
    margin-left: 10px;
  }
`;

export const FormSection = styled.div`
  margin-top: ${props => (props.separatedTop ? 30 : 0)}px;
`;

export const FieldWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 13px;
  width: 100%;
  @media ${breakpoint.md} {
    width: ${props => props.width};
  }
`;

export const SmallText = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

export const Link = styled(RouterLink)`
  display: block;
  text-decoration: none;
  font-weight: 700;
  line-height: 23px;
  color: #000;
  text-align: center;

  @media ${breakpoint.md} {
    text-align: left;
  }
`;

export const List = styled.div``;

export const Description = styled.p`
  margin-bottom: 30px;
`;

export const PromotionItem = styled.div`
  padding: 15px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 3px;
  margin-bottom: 10px;

  h3 {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    align-items: center;
  }

  time {
    margin-left: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
    @media ${breakpoint.md} {
      margin-left: 0;
    }
  }

  p {
    line-height: 18px;
    font-size: 16px;
  }
`;
