import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AuthLayout from 'components/Auth/Layout';

import routes from 'routes';
import SignInForm from './components/SignInForm';

@inject('authStore', 'userStore', 'routingStore')
@observer
class SignInPage extends Component {
  componentDidMount() {
    const {
      authStore: { isAuthenticated },
      routingStore: { replace },
    } = this.props;

    if (isAuthenticated) {
      replace(routes.home);
    }
  }

  render() {
    const {
      authStore: { signIn },
    } = this.props;
    return (
      <AuthLayout image="/images/backgrounds/login.jpg">
        <SignInForm onSubmit={signIn} />
      </AuthLayout>
    );
  }
}

export default SignInPage;
