import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
// import { ButtonsWrapper, SecondaryButton } from './style';
import * as Styled from './style';

const DeleteAccount = ({ profile, deleteProfile, close, t }) => {
  return (
    <>
      <Heading
        as="h2"
        fontWeight="medium"
        fontSize={[3, 3, 3, 6]}
        mb={3}
        pr={['30px', '30px', '30px', 0]}
      >
        {t('Delete account')}
      </Heading>
      <Text
        lineHeight={['14px', '14px', '14px', '18px']}
        fontSize={[0, 0, 0, 3]}
      >
        {t('Are you sure you want to delete your account?')}
      </Text>

      <Styled.ButtonsWrapper>
        <MainButton
          mt={['10px', '10px', 0, 0, 0]}
          type="button"
          onClick={() => deleteProfile(profile, close)}
        >
          {t('Delete account')}
        </MainButton>
        <Styled.SecondaryButton
          fontSize="20px"
          lineHeight="26px"
          type="button"
          variant="secondary"
          onClick={() => close()}
        >
          {t('Cancel')}
        </Styled.SecondaryButton>
      </Styled.ButtonsWrapper>
    </>
  );
};

DeleteAccount.propTypes = {
  profile: PropTypes.object.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default withTranslation('account')(DeleteAccount);
