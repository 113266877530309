import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Layout from './Layout';
import UserDataForm from './Forms/UserData';
import SignInForm from './Forms/SignIn';
import BookingSummary from './Summary';

@inject('authStore', 'bookingStore')
@observer
class BookingPage extends Component {
  componentDidUpdate = prevProps => {
    const {
      location: { search },
      bookingStore: { handleQueryParamsChange },
    } = this.props;

    if (prevProps.location.search !== search) {
      handleQueryParamsChange();
    }
  };

  render() {
    const {
      authStore: { isAuthenticated, signInOnBooking },
      bookingStore: { submitStep, step, userData },
    } = this.props;

    return (
      <Layout>
        {step === 1 && (
          <>
            {!isAuthenticated && <SignInForm onSubmit={signInOnBooking} />}
            <UserDataForm
              onSubmit={submitStep}
              userData={userData}
              isAuthenticated={isAuthenticated}
            />
          </>
        )}
        {step === 2 && <BookingSummary />}
      </Layout>
    );
  }
}

BookingPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(BookingPage);
