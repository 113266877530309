import React, { useEffect, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';

import StyledButton from 'components/Button';
import FormSelect from 'components/forms/FormSelect';
import DateTimePicker from 'components/forms/DateTimePicker';
import { required, composeValidators, checkTime } from 'utils/formValidators';
import { capacity } from 'utils/staticValues';
import Preloader from 'components/Preloader';
import i18n from 'i18n';
// import FormError from 'components/forms/FormError';
// import MobileSearchButton from './MobileSearchButton';
// import MobileValueSelector from './MobileValueSelector';
import * as Styled from './style';

const SearchForm = inject(
  'addressStore',
  'searchStore',
)(
  observer(
    ({
      addressStore: {
        fetchAddressesForDropdown,
        usedAddressesforDropdown,
        isLoading,
      },
      searchStore: { submitSearchForm },
      t,
    }) => {
      // const [isMobile, setIsMobile] = useState(
      //   window.innerWidth < breakpoint.md,
      // );
      // const [mobileSelectorIsVisible, setMobileSelectorVisibility] = useState(
      //   false,
      // );
      // const [selectorOptions, setSelectorOptions] = useState({});

      const CAPACITY = useMemo(capacity, [i18n.language]);

      const isIpad = !!(
        /Macintosh|iPad/i.test(navigator.userAgent) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1
      );

      const initialValues = useMemo(() => {
        const from = moment()
          .add(1, 'hour')
          .startOf('hour')
          .format('HH:mm');
        const to = moment()
          .add(2, 'hour')
          .startOf('hour')
          .format('HH:mm');

        return {
          from,
          to,
        };
      }, []);

      // let resizeDebouncer;
      // const windowResizeHandler = () => {
      //   clearTimeout(resizeDebouncer);
      //   resizeDebouncer = setTimeout(() => {
      //     setIsMobile(window.innerWidth < breakpoint.md);
      //   }, 300);
      // };

      useEffect(() => {
        const fetchDropdownValues = async () => {
          await fetchAddressesForDropdown();
        };

        fetchDropdownValues();

        // window.addEventListener('resize', windowResizeHandler);

        // return () => {
        //   window.removeEventListener('resize', windowResizeHandler);
        // };
      }, []);

      // const mobileSelectorButtonClickHandler = useCallback(options => {
      //   setSelectorOptions(options);
      //   setMobileSelectorVisibility(true);
      // }, []);

      // const closeMobileSelector = useCallback(() => {
      //   setMobileSelectorVisibility(false);
      //   setTimeout(() => {
      //     setSelectorOptions({});
      //   }, 450);
      // }, []);

      if (isLoading) return <Preloader light h={70} />;

      return (
        <Form
          onSubmit={submitSearchForm}
          initialValues={initialValues}
          render={({
            handleSubmit,
            dirtyFields,
            form: { change },
            values: formValues,
          }) => (
            <Styled.Form onSubmit={handleSubmit}>
              <Styled.Row>
                {/* Changes made according to the https://goodylabs.atlassian.net/browse/BLR-384  */}
                {/* {isMobile ? (
                  <>
                    <MobileValueSelector
                      visible={mobileSelectorIsVisible}
                      close={closeMobileSelector}
                      formValues={formValues}
                      {...selectorOptions}
                    />
                    <Styled.InputWrapper flex={1.8} borderSeparator>
                      <Field name="city" validate={required}>
                        {({ meta }) => (
                          <>
                            <MobileSearchButton
                              label="dropdowns:Choose location"
                              selectedLabel="dropdowns:City"
                              value={formValues.city}
                              onClick={() => {
                                mobileSelectorButtonClickHandler({
                                  name: 'city',
                                  title: 'dropdowns:Choose location',
                                  values: usedAddressesforDropdown,
                                  onSelect: change,
                                  value: formValues.city,
                                });
                              }}
                            />
                            <FormError meta={meta} style={{ right: '39px' }} />
                          </>
                        )}
                      </Field>
                    </Styled.InputWrapper>
                    <Styled.InputWrapper flex={1} borderSeparator>
                      <Field name="capacity" validate={required}>
                        {({ meta }) => (
                          <>
                            <MobileSearchButton
                              label="dropdowns:Number of people"
                              selectedLabel="dropdowns:Number of people"
                              value={formValues.capacity}
                              onClick={() => {
                                mobileSelectorButtonClickHandler({
                                  name: 'capacity',
                                  title: 'dropdowns:Number of people',
                                  values: CAPACITY,
                                  onSelect: change,
                                  value: formValues.capacity,
                                });
                              }}
                            />
                            <FormError meta={meta} style={{ right: '39px' }} />
                          </>
                        )}
                      </Field>
                    </Styled.InputWrapper>
                    <Styled.InputWrapper flex={1.5}>
                      <Field name="date" validate={required}>
                        {({ meta }) => (
                          <>
                            <MobileSearchButton
                              label="dropdowns:Select day and hours"
                              value={
                                formValues.date
                                  ? `${formValues.date} / ${formValues.from} - ${formValues.to}`
                                  : null
                              }
                              onClick={() => {
                                mobileSelectorButtonClickHandler({
                                  name: 'date',
                                  title: 'dropdowns:Select day and hours',
                                  dateTime: true,
                                  changeFieldValueHandler: change,
                                });
                              }}
                            />
                            <FormError meta={meta} style={{ right: '39px' }} />
                          </>
                        )}
                      </Field>
                    </Styled.InputWrapper>
                  </>
                ) : (
                  <> */}
                <Styled.InputWrapper flex={1.8} borderSeparator>
                  <Field
                    name="city"
                    placeholder={t('dropdowns:Choose location')}
                    border="secondary"
                    size="big"
                    component={FormSelect}
                    validate={required}
                    options={usedAddressesforDropdown}
                    isIpad={isIpad}
                  />
                </Styled.InputWrapper>
                <Styled.InputWrapper flex={1} borderSeparator>
                  <Field
                    name="capacity"
                    placeholder={t('dropdowns:Number of people')}
                    size="big"
                    component={FormSelect}
                    validate={required}
                    options={CAPACITY}
                    isIpad={isIpad}
                  />
                </Styled.InputWrapper>
                <Styled.InputWrapper flex={1.5}>
                  <Field
                    size="big"
                    name="date"
                    placeholder={t('dropdowns:Select day and hours')}
                    hidePlaceholder={dirtyFields.date}
                    component={DateTimePicker}
                    validate={composeValidators(checkTime, required)}
                    bgTimeColor="#ffffff"
                    changeFieldValueHandler={change}
                    formValues={formValues}
                  />
                </Styled.InputWrapper>
                {/* </>
                )} */}
              </Styled.Row>
              <StyledButton
                mt={['13px', '13px', '13px', '23px', '23px']}
                big
                type="submit"
              >
                {t('buttons:Search')}
              </StyledButton>
            </Styled.Form>
          )}
        />
      );
    },
  ),
);

const enhance = compose(withRouter, withTranslation());

export default enhance(SearchForm);
