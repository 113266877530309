import styled from '@emotion/styled';
import { Button } from 'rebass';
// import { css } from '@emotion/core';

export const ButtonAsLink = styled(Button)`
  color: white;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: none;
  font-size: 16px;
  font-weight: 500;
  padding: 0;

  &:hover {
    color: #947f72;
  }
`;
