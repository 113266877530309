import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';
import { BaseFormInput } from './BaseFormInput';

const DropDownWrapper = styled.div`
  position: relative;
  &:before {
    position: absolute;
    z-index: 10;
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    top: -3px;
  }
  @media ${breakpoint.md} {
    position: absolute;
    z-index: 10;
    top: 100%;
    right: 0;
    height: 100%;

    &:before {
      display: none;
    }
  }
`;

const Dropdown = ({
  placeholder,
  children,
  searchValue,
  bgColor,
  borderProp,
}) => {
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
  const otherElRef = useRef(null);

  const toggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleOutsideMouseDown = event => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !otherElRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleOutsideMouseDown);
    };
  }, []);

  return (
    <>
      <BaseFormInput
        bgColor={bgColor}
        borderProp={borderProp}
        placeholder={placeholder}
        onClick={toggleVisible}
        value={searchValue}
        readOnly
        ref={dropdownRef}
      />
      <DropDownWrapper
        ref={otherElRef}
        style={{ display: visible ? 'block' : 'none' }}
      >
        {children({ toggle: toggleVisible })}
      </DropDownWrapper>
    </>
  );
};

export default Dropdown;
