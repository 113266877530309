/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Preloader from 'components/Preloader';
import InfiniteScroll from 'react-infinite-scroller';
import { Card } from 'styles/globalStyles';
import Order from './Order';

import * as Styled from './style';

const MyOrders = inject(
  'userStore',
  'bookingStore',
)(
  observer(({ fetchingBookings, fetchMoreBookings, hasMore, orders, t }) => {
    const renderOrders = () => {
      if (!orders.length)
        return (
          <Styled.Item alignItems="center" justifyContent="space-between">
            {t('You have no bookings yet.')}
          </Styled.Item>
        );

      // map orders
      return (
        <>
          <Flex maxHeight={500} width="100%" overflow="auto">
            <InfiniteScroll
              style={{ width: '100%' }}
              threshold={10}
              pageStart={1}
              loadMore={fetchMoreBookings}
              hasMore={hasMore}
              initialLoad={false}
              loader={<Preloader h={100} key={0} />}
            >
              <Flex flexWrap="wrap" flexDirection="row">
                {orders.map(b => {
                  // map bookings from order
                  if (b.orderItems) {
                    return b.orderItems.map(order => (
                      <Flex
                        flexDirection="column"
                        flexGrow={1}
                        p={15}
                        key={`b_${order._id}`}
                      >
                        <Order
                          order={{ ...order, ...b }}
                          paymentStatus={b.paymentState}
                        />
                      </Flex>
                    ));
                  }
                  return null;
                })}
              </Flex>
            </InfiniteScroll>
          </Flex>
        </>
      );
    };

    return (
      <Card>
        <Styled.Header
          as="h2"
          fontSize={['22px', '22px', '22px', '22px']}
          fontWeight="bold"
        >
          {t('My orders')}
        </Styled.Header>

        <Styled.List>
          {fetchingBookings ? <Preloader h={100} /> : renderOrders()}
        </Styled.List>
      </Card>
    );
  }),
);

MyOrders.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('account')(MyOrders);
