import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { LineSeparator } from 'styles/globalStyles';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 30px 0;

  @media ${breakpoint.xs} {
    margin: 30px 0;
  }
  @media ${breakpoint.md} {
    margin: 51px 0 109px;
  }
`;

export const Row = styled(Flex)`
  justify-content: space-between;
  background-color: #fff;

  @media ${breakpoint.xs} {
    flex-direction: column;
    padding: 15px;
  }
  @media ${breakpoint.md} {
    padding: 45px;
    flex-direction: row;
  }
`;

export const LeftColumn = styled(Flex)`
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  max-width: 700px;
  width: 100%;

  @media ${breakpoint.md} {
    margin-right: 25px;
  }
`;

export const RightColumn = styled(Flex)`
  flex: 0 1 auto;
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const Details = styled.div`
  position: relative;
`;

export const MapImage = styled.img`
  max-width: 100%;
`;

export const Description = styled.p`
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;

  @media ${breakpoint.md} {
    font-size: 16px;
    line-height: 29px;
  }
`;

export const MyLineSeparator = styled(LineSeparator)`
  border-color: #ededed;
  margin: 15px 0 20px;
  width: 100%;

  @media ${breakpoint.md} {
    margin: 40px 0 40px -45px;
    width: calc(100% + 45px);
  }
`;

export const Pricing = styled.div`
  margin: -40px 0 -40px -45px;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.015);
`;

export const PricingHeader = styled.div`
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  padding: 0 40px;
`;

export const PricingTable = styled.table`
  width: 100%;
  text-align: left;

  th,
  td {
    padding: 12px 0;
    &:last-child {
      text-align: right;
      padding-right: 40px;
    }
    &:first-of-type {
      padding-left: 40px;
    }
  }

  th {
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  tr {
    &:first-of-type {
      td {
        padding-top: 15px;
      }
    }

    &:nth-of-type(even) {
      td {
        background-color: rgba(0, 0, 0, 0.015);
      }
    }
  }
`;
