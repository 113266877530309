import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Button } from 'rebass';
import { css } from '@emotion/core';

import { Container } from 'styles/globalStyles';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ isOnHomepage, theme }) =>
    isOnHomepage ? 'transparent' : theme.colors.navbarBackground};
  z-index: 2;
  top: 0;
  left: 0;
  /* left: 0; */
  /* margin-bottom: 61px; */
  /* position: fixed; */
  position: ${({ isOnHomepage }) => (isOnHomepage ? 'fixed' : 'sticky')};
  height: 72px;

  @media ${breakpoint.lg} {
    /* position: relative; */
    height: 100px;
  }
`;

export const NavBar = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: relative;
  height: 100%;
`;

export const NavBarLeft = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  min-width: max-content;
  @media ${breakpoint.xs} {
    display: none;
  }
  @media ${breakpoint.lg} {
    display: flex;
  }
`;

export const NavTitle = styled(Link)`
  text-align: center;
  color: #fff;
  font-weight: 900;
  text-decoration: none;
  display: block;
  margin-right: 30px;
  margin-top: -10px;
`;

export const NavBarRight = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  @media ${breakpoint.xs} {
    display: none;
  }
  @media ${breakpoint.lg} {
    display: flex;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: #947f72;
  }
`;

export const MobileNavItem = styled(Link)`
  display: block;
  color: white;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  white-space: nowrap;
  padding: 5px 0;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    color: #947f72;
  }

  &:focus {
    outline: none;
  }
`;

export const StyledLinkSeparated = styled(StyledLink)`
  margin-left: 45px;

  @media (min-width: 1400px) and (max-width: 1600px) {
    margin-left: 35px;
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    margin-left: 20px;
  }
`;

export const LangSelector = styled.div`
  flex: 0 0 65px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px #fff solid;
`;

export const UserProfile = styled.span`
  color: #f4b78a;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  > div {
    margin-left: 5px;
  }
  > span {
    color: #fff;
    padding-left: 5px;
  }
`;

export const UserName = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const UserProfileMobile = styled.span`
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  height: 150px;
  margin-bottom: -15px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: row;
  padding: 0 30px 15px;

  ${props =>
    props.column &&
    css`
      flex-direction: column;
      justify-content: flex-end !important;
      align-items: center;
    `}
  > span {
    color: #fff;
    padding-left: 5px;
  }

  img {
    display: block;
    max-width: 20px;
  }

  &:focus {
    outline: none;
  }
`;

export const MobileBurgerIcon = styled(Button)`
  img {
    width: 22px;
  }

  &:focus {
    outline: none;
  }

  @media ${breakpoint.xs} {
    display: block;
  }
  @media ${breakpoint.md} {
    display: none;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 15px 0;
`;

export const LogoImage = styled.img`
  display: block;
  width: 200px;
  margin: 0 auto;

  @media ${breakpoint.xs} {
    width: 150px;
  }
`;
