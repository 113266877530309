import preset from '@rebass/preset';

export default {
  ...preset,
  breakpoints: ['20em', '23.48em', '48em', '64em', '75em', '100em'],
  buttons: {
    ...preset.buttons,
    icon: {
      fontSize: 2,
      fontWeight: 'bold',
      color: 'background',
      bg: 'transparent',
      borderRadius: 0,
      padding: 10,
      cursor: 'pointer',
    },
    text: {
      bg: 'transparent',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
    },
    secondary: {
      background: '#3E291C',
      color: '#fff',
      padding: '10px 40px',
      cursor: 'pointer',
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      '&:disabled': {
        opacity: 0.3,
        cursor: 'default',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#000',
    secondary: '#fff',
    muted: '#575757',
    gray: '#dddddf',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
    gold: '#d9a248',
    navbarBackground: '#3e291c',
    footerTopBackground: '#3e291c',
    footerCopyBacground: '#eeeeee',
    bodyBackground: '#eeeeee',
  },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto',
    monospace: 'Roboto',
  },
  fontWeights: {
    thin: 300,
    body: 400,
    heading: 900,
    bold: 700,
    medium: 500,
  },
  fontSizes: [12, 13, 14, 16, 18, 20, 24, 26, 28, 36],
  space: [0, 4, 8, 16, 32, 64, 85, 128, 256, 512],
};
