import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 32px 32px 60px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  width: 100%;
  @media ${breakpoint.md} {
    flex: 1 0 calc(50% - 16px);
    width: auto;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }
`;

export const PostImage = styled(Image)`
  margin: 0 auto 30px;
  display: block;
  height: 300px;
  width: 100%;
  object-fit: cover;
`;

export const ReadMore = styled.span`
  position: absolute;
  right: 32px;
  bottom: 32px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.gold};
`;

export const Content = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
`;
