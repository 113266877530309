/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'recompose';

import i18n from 'i18n';
import routes from 'routes';
import Preloader from 'components/Preloader';
import LanguageSelector from 'components/NavBar/LanguageSelector';
import Select from '../ImageSelect';
import * as Styled from './style';

const NavBarMain = inject(
  'authStore',
  'userStore',
  'routingStore',
  'searchStore',
)(
  observer(
    ({
      t,
      location,
      location: { pathname },
      authStore: { logout, isAuthenticated },
      userStore: { fetchProfile, isLoading, profile },
      routingStore: { push },
      searchStore: { notFound },
    }) => {
      const fetchUserProfile = async () => {
        await fetchProfile();
      };

      const isOnHomepage = useMemo(() => pathname === '/', [pathname]);

      useEffect(() => {
        if (isAuthenticated && isEmpty(profile)) fetchUserProfile();
      }, []);
      // observe route change to scroll window to the top
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);

      // prevent rendering navbar on auth pages
      if (pathname.indexOf('auth') !== -1) return null;
      if (notFound) return null;

      const userSelectOptions = [
        { value: 'account', label: t('Account Settings') },
        { value: 'logout', label: t('Logout') },
      ];

      const userSelectChangeHandler = v => {
        if (v !== 'logout') {
          if (Object.hasOwnProperty.call(routes, v)) {
            push(routes[v]);
          }
        } else {
          logout();
        }
      };

      const userSection = () => {
        if (!isAuthenticated) {
          return (
            <Styled.StyledLink to={routes.auth.signIn}>
              {t('Sign in')}
            </Styled.StyledLink>
          );
        }

        const profileName = () => (
          <Flex justifyContent="center">
            <Styled.UserName>{profile.name || profile.email} </Styled.UserName>
            {profile.isPremium ? (
              <img
                style={{ marginLeft: '5px' }}
                width="16px"
                height="16px"
                src="/images/icons/diamond.svg"
                alt="PREMIUM"
              />
            ) : null}
          </Flex>
        );

        return (
          <Styled.UserProfile>
            {t('Hello')},{' '}
            <Select
              options={userSelectOptions}
              onChange={userSelectChangeHandler}
              placeholder={profileName()}
            />
          </Styled.UserProfile>
        );
      };

      return (
        <Styled.Wrapper isOnHomepage={isOnHomepage}>
          <Styled.NavBar>
            <Styled.NavTitle to="/">
              <Styled.LogoImage alt="" src="/images/saletaneczne-logo.png" />
            </Styled.NavTitle>

            <Styled.NavBarLeft>
              {/* <Styled.StyledLink to={routes.aboutUs}>
                {t('About us')}
              </Styled.StyledLink> */}

              {i18n.language === 'pl' && (
                <Styled.StyledLinkSeparated to={routes.blog}>
                  {t('Blog')}
                </Styled.StyledLinkSeparated>
              )}

              <Styled.StyledLinkSeparated to={routes.howToBook}>
                {t('How to book')}?
              </Styled.StyledLinkSeparated>

              <Styled.StyledLinkSeparated to={routes.rooms}>
                {t('Our locations')}
              </Styled.StyledLinkSeparated>

              <Styled.StyledLinkSeparated to={routes.premium}>
                {t('Premium')}
              </Styled.StyledLinkSeparated>

              {!isAuthenticated && (
                <Styled.StyledLinkSeparated to={routes.auth.register}>
                  {t('Sign up')}
                </Styled.StyledLinkSeparated>
              )}
            </Styled.NavBarLeft>

            <Styled.NavBarRight>
              {isLoading ? <Preloader light small /> : userSection()}
              <Styled.LangSelector>
                <LanguageSelector />
                {/* <Select
                  options={availableLanguages}
                  onChange={v => i18n.changeLanguage(v)}
                  value={i18n.language}
                /> */}
              </Styled.LangSelector>
            </Styled.NavBarRight>
          </Styled.NavBar>
        </Styled.Wrapper>
      );
    },
  ),
);

NavBarMain.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(withRouter, withTranslation('navbar'));

export default enhance(NavBarMain);
