import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Container } from 'styles/globalStyles';
import { breakpoint } from 'styles/breakpoints';

export const VideoBackground = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(5px);
`;

export const Wrapper = styled(Container)`
  position: relative;
  text-align: center;
  z-index: 10;
  pointer-events: none;
  /* padding-top: 16vh; */

  > * {
    pointer-events: auto;
  }

  @media ${breakpoint.md} {
    padding-top: 0;
    margin: auto;
  }
`;

export const MainShapes = styled.img`
  position: absolute;
  margin: auto;
  display: block;
  height: 100%;
  width: 100%;
  z-index: -1;
  @media screen and (max-width: 40em) {
    width: auto;
  }
`;

export const MainTitle = styled.div`
  text-align: center;
  font-weight: 900;
  line-height: 107.69%;
  color: #fff;
  white-space: pre-line;
  font-size: 32px;
  margin-bottom: 20px;
  pointer-events: none;

  @media ${breakpoint.md} {
    font-size: 60px;
    margin-bottom: 45px;
  }
  @media ${breakpoint.lg} {
    font-size: 74px;
  }
  @media ${breakpoint.xxl} {
    font-size: 92px;
  }
  @media (min-width: 1800px) {
    font-size: 110px;
  }
`;

export const SearchButton = styled.button`
  text-shadow: none;
  font-style: normal;
  font-weight: 900;
  color: #ffffff;
  background: linear-gradient(
    180deg,
    #f7e8a7 0%,
    #967032 45.56%,
    #997732 59.26%,
    #c1a542 100%
  );
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  min-width: 120px;
  transition: all 0.5s cubic-bezier(0.25, 0.25, 0, 1);
  vertical-align: baseline;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #947f72;
  }
`;
