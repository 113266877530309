import styled from '@emotion/styled';
import { Container } from 'styles/globalStyles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const SearchContentWrapper = styled(Container)`
  position: relative;
  text-align: center;
  z-index: 1;
`;
