import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Img from 'components/Image';

export const ProductBox = styled(Link)`
  position: relative;
  display: block;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  flex: 1 0 calc(50% - 16px);
  padding: 15px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 20px rgb(0 0 0 / 15%);
  }
`;

export const Image = styled(Img)`
  display: block;
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
`;
