/* eslint-disable max-len */
import React, { useCallback, useState, useEffect } from 'react';
import i18n, { availableLanguages } from 'i18n';
import { CSSTransition } from 'react-transition-group';
import {
  Wrapper,
  FlagButton,
  Dropdown,
} from 'components/NavBar/LanguageSelector/style';

const LanguagesDropdown = ({ close }) => {
  useEffect(() => {
    window.addEventListener('click', close);

    return () => {
      window.removeEventListener('click', close);
    };
  }, []);

  return (
    <Dropdown>
      {availableLanguages.map(lang => (
        <li key={lang.value}>
          <button type="button" onClick={() => i18n.changeLanguage(lang.value)}>
            <img src={`/images/flags/${lang.value}.svg`} alt={lang.label} />
          </button>
        </li>
      ))}
    </Dropdown>
  );
};

const LanguageSelector = () => {
  const [opened, setOpened] = useState(false);

  const closeDropdown = useCallback(() => {
    setOpened(false);
  }, []);

  const toggleDropdown = useCallback(
    ev => {
      ev.stopPropagation();
      setOpened(!opened);
    },
    [opened],
  );

  return (
    <Wrapper>
      <FlagButton type="button" onClick={toggleDropdown} isOpened={opened}>
        <img src={`/images/flags/${i18n.language}.svg`} alt={i18n.language} />
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
        </svg>
      </FlagButton>
      <CSSTransition in={opened} timeout={500} classNames="slide" unmountOnExit>
        <LanguagesDropdown close={closeDropdown} />
      </CSSTransition>
    </Wrapper>
  );
};

export default LanguageSelector;
