import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { imagePath } from 'utils/imagePathHelper';
import { RoomHeaderTitle } from 'styles/globalStyles';
import * as Styled from './style';

const ImageSlider = ({ images, price, title, hidePrice }) => {
  if (images.length === 1) {
    return (
      <Styled.ImageBanner
        img={imagePath(images[0].thumbnails.big || images[0].src)}
      >
        <RoomHeaderTitle>
          <Styled.Title as="h1">{title}</Styled.Title>
          {!hidePrice ? <Styled.Price>{price}zł</Styled.Price> : null}
        </RoomHeaderTitle>
      </Styled.ImageBanner>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  // render slick carousel
  return (
    <Styled.ImageBanner>
      <RoomHeaderTitle absolute>
        <Styled.Title as="h1">{title}</Styled.Title>
        {/* tmp fix */}
        <Styled.Price>{price}zł</Styled.Price>
      </RoomHeaderTitle>
      <Slider {...settings}>
        {images.map(img => (
          <Styled.ImageItem
            key={`image-slider_${img.name}`}
            src={imagePath(img.thumbnails.big || img.src)}
            alt={img.name}
          />
        ))}
      </Slider>
    </Styled.ImageBanner>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  hidePrice: PropTypes.bool,
};

ImageSlider.defaultProps = {
  hidePrice: false,
};

export default ImageSlider;
