import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Container, MainTitle } from 'styles/globalStyles';
import { ProductsGrid } from 'containers/Shop/style';
import Product from 'containers/Shop/components/Product';
import Preloader from 'components/Preloader';

const ShopPage = ({
  shopStore: { fetchProducts, isLoading, products, initialized },
}) => {
  const { t } = useTranslation('shop');

  useEffect(() => {
    (async () => {
      await fetchProducts();
    })();
  }, []);

  return (
    <Container>
      <MainTitle>{t('title')}</MainTitle>

      {isLoading ? <Preloader /> : null}
      {initialized && !products.length ? t('no products') : null}
      <ProductsGrid>
        {products.map(product => (
          <Product key={product._id} {...product} />
        ))}
      </ProductsGrid>
    </Container>
  );
};

export default inject('shopStore')(observer(ShopPage));
