import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button as RawButton } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

const MainButton = styled(RawButton)`
  text-shadow: none;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  background: #d9a248;
  outline: none;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 26px;
  display: inline-block;
  align-items: center;
  text-align: center;
  position: 'relative';
  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.5;
        cursor: default;
        filter: grayscale(1);
      `;
    }

    return null;
  }}
  @media ${breakpoint.xs} {
    width: 100%;
  }
  @media ${breakpoint.md} {
    width: ${props => (props.width ? `${props.width}` : 'auto')};
  }
`;

const Button = ({ children, ...props }) => (
  <MainButton {...props}>{children}</MainButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Button.defaultProps = {
  px: 20,
  py: 13,
};

export default Button;
