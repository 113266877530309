/* eslint-disable max-len */
export default {
  shop: {
    title: 'Sklep taneczny',
    'no products': 'Przykro nam, nie ma żadnych produktów',
  },
  footer: {
    termsAndConditions: 'Regulamin',
    privacyPolicy: 'Polityka prywatności',
    Contact: 'Kontakt',
    'Investor Relations': 'Relacje inwestorskie',
  },
  account: {
    'Account Settings': 'Profil',
    'My bookings': 'Moje rezerwacje sal',
    'Personal information': 'Dane osobowe',
    'Billing information': 'Dane do płatności',
    expired: 'Wygasa:',
    Phone: 'Numer telefonu',
    Email: 'Adres e-mail',
    Name: 'Imię i Nazwisko / Ksywka',
    'Billing Name': 'Imię i Nazwisko / Nazwa firmy',
    'VAT Number': 'NIP',
    Street: 'Ulica',
    Country: 'Kraj',
    StreetNumber: 'Numer ulicy',
    FlatNumber: 'Numer mieszkania',
    'ZIP Code': 'Kod pocztowy',
    City: 'Miasto',
    Save: 'Zapisz',
    'booking.complete': 'ZAKOŃCZONY',
    'booking.modify': 'MODYFIKUJ',
    'booking.cancelRes': 'ANULUJ REZERWACJĘ',
    'booking.cancel': 'ANULUJ',
    'booking.cancelled': 'ANULOWANA',
    'Cancel room booking': 'Anulowanie rezerwacji sali - {{ name }}',
    'Are you sure you want to cancel this reservation? A fee eqaual to 6 zł per booking hour will be charged.':
      'Czy na pewno chcesz odwołać rezerwację? Zostanie naliczona opłata w wysokości 6 zł za każdą godzinę rezerwacji.',
    'Modify room booking': 'Modyfikacja rezerwacji sali - {{ name }}',
    "You can change your booking's hours without extra costs. Choose one of listed options":
      'Możesz zmienić bezpłatnie godzinę rezerwacji sali, wybierając jedną z listy dostępnych.',
    Change: 'Zmień',
    'You have no bookings yet.': 'Nie masz jeszcze żadnych rezerwacji',
    DeleteInfo:
      'Usuwając swoje konto nie będziesz mieć dostępu do swoich rezerwacji.',
    'Delete account': 'Usuń konto',
    'Are you sure you want to delete your account?':
      'Jesteś pewien, że chcesz usunąć swoje konto?',
    Cancel: 'Anuluj',
    Delete: 'Usuń',
    Premium: 'Premium',
    'Your PREMIUM account is active!': 'Jesteś PREMIUM - gratulujemy!',
    'package is valid to': 'pakiet ważny do ',
    'Buy premium!': 'Chcę być premium!',
    "Don't know the benefits of a premium account? Get to know them now":
      'Nie znasz korzyści konta premium? Poznaj je teraz',
    'Additional promotions': 'Dodatkowe promocje',
    "You don't have any additional promotions":
      'Aktualnie nie posiadasz żadnych dodatkowych promocji',
    'Check additional promotions': 'Sprawdź dodatkowe promocje',
    'Check more additional promotions': 'Sprawdź więcej dodatkowych promocji',
    'buy.premium.modal.description':
      'Aby wykupić dostęp PREMIUM, wprowadź dane płatnika, wybierz okres trwania i kliknij przycisk KUPUJĘ',
    Buy: 'Kupuję',
    'Buy PREMIUM account': 'Zakup konta PREMIUM',
    "You've successfully bought premium account":
      'Jesteś teraz użytkownikiem PREMIUM',
    "You've successfully bought promotion": 'Kupiłeś dodatkowy pakiet zniżek',
    'My orders': 'Historia moich zamówień',
    'First name': 'Imię',
    'Last name': 'Nazwisko',
    'Company name': 'Nazwa firmy',
    selfEmployed: 'Jednoosobowa działalność gospodarcza',
    otherBusiness: 'Inny rodzaj działalności',
  },
  buttons: {
    Search: 'Szukaj',
    'Open filters': 'Otwórz filtry',
    'Reset password': 'Zresetuj hasło',
    Book: 'Zarezerwuj',
    'Free room': 'Wolne pokoje',
    'Selected room': 'Wybrany pokój',
    'Back to the homepage': 'Wróć na stronę główną',
    Send: 'Wyślij',
  },
  countries: {
    pl: 'Polska',
    de: 'Niemcy',
    fr: 'Francja',
  },
  discount: {
    facebook:
      '<b>Kliknij tu aby zyskać 5zł/1h przez <b>14 dni</b></b> Wejdź na nasz funpage i udostępnij go na swoim wallu, a otrzymasz RABAT!',
    percent:
      '<b>Kliknij tu aby zyskać 5zł / 1h przez 7 dni!</b> Przekaż 1% podatku',
  },
  common: {
    'Rent a dance hall': 'Zarezerwuj Salę Taneczną',
    From: 'Od',
    To: 'Do',
    'Current settings': 'Aktualnie wybrane',
    'Search criteria': 'Kryteria wyszukiwania',
    'Want to book a room for long term?': 'Chcesz zarezerwować wiele dni?',
    'Contact us': 'Skontaktuj się z nami',
    'Similar rooms': 'Sale dostępne w podobnym terminie',
    'No rooms found! Please search for a different time or date, or contact us':
      'Nie znaleziono sal! Zmień czas lub datę lub skontaktuj się z nami',
    Price: 'Cena',
    max_people: 'Dla maksymalnie: ',
    available: 'Dostępna: ',
    person_0: '{{count}} osoba',
    person_1: '{{count}} osoby',
    person_2: '{{count}} osób',
    personAndMore: '10 osób i więcej',
    'Not found': 'Nie znaleziono',
    starInfo:
      '*Opłata dodatkowa w wysokości 500 zł zostanie naliczona w przypadku wprowadzenia rezerwującego salę w błąd odnośnie ilości osób.',
    termsAndConditionsFooter: '<0>Regulamin</0> | <2>Polityka prywatności</2>',
    short: '1 miesiąc - 39PLN',
    long: '3 miesiące - 99PLN',
  },
  dropdowns: {
    'Choose location': 'Wybierz lokalizację sali',
    'Choose plan': 'Wybierz plan',
    'Number of people': 'Liczba osób',
    'Select day and hours': 'Wybierz dzień - w godz',
    'Premium duration': 'Okres trwania konta premium',
    City: 'Miasto',
  },
  home: {
    "Can't find room in your city?": 'Nie znalazłeś sali w swoim mieście?',
  },
  navbar: {
    'About us': 'O nas',
    Blog: 'Aktualności',
    Contact: 'Kontakt',
    'Sign up': 'Utwórz konto',
    'Sign in': 'Zaloguj się',
    Hello: 'Witaj',
    'Account Settings': 'Moje konto',
    'Investor Relations': 'Relacje inwestorskie',
    Logout: 'Wyloguj',
    Premium: 'Premium',
    Shop: 'Sklep taneczny',
    'How to book': 'Jak zrobić rezerwację',
    'Our locations': 'Nasze sale',
  },
  titles: {
    Login: 'Zaloguj się',
    Register: 'Rejestracja nowego użytkownika',
    Contact: 'Kontakt',
  },
  forms: {
    'Already a member?': 'Masz już konto?',
    termsAndConditions:
      'Oświadczam, że zapoznałem się z <2>Regulaminem</2> i <4>Polityką prywatności</4>',
    Email: 'Email',
    'Forgot password?': 'Zapomniałeś hasła?',
    Login: 'Zaloguj',
    Name: 'Imię i nazwisko / Nazwa',
    'Not a member?': 'Nie masz jeszcze konta? ',
    Password: 'Hasło',
    'Password confirmation': 'Powtórz hasło',
    Register: 'Utwórz konto',
    'Sign in now': 'Zaloguj się',
    'Sign up now': 'Zarejestruj się teraz',
    forgotPasswordInstruction:
      'Wpisz adres e-mail użyty podczas rejestracji, a my wyślemy Ci instrukcje dotyczące resetowania hasła',
    resetPasswordInstruction: 'Wprowadź nowe hasło dla swojego konta',
    contactForm: 'Masz pytanie? Skontaktuj się z nami:',
    Phone: 'Numer telefonu',
    Subject: 'Temat',
    Message: 'Wiadomość',
    City: 'Miasto',
    callUs: 'Bezpośredni kontakt:',
    "I can't find room in my city": 'Nie znalazłem sali w swoim mieście',
    'Reset password': 'Zresetuj hasło',
  },
  errors: {
    'Invalid username or password': 'Niepoprawna nazwa użytkownika lub hasło',
    'Something went wrong': 'Coś poszło nie tak',
    'Invalid email address': 'Niepoprawny adres email',
    'Invalid street number': 'Numer ulicy musi być liczbą',
    'This field is required': 'To pole jest wymagane',
    "Passwords don't match": 'Hasła nie są takie same',
    'Minimum duration is 1h': 'Minimalny czas rezerwacji to 1 godzina',
    'Start time must be before end time':
      'Godzina początkowa musi być wcześniejsza od końcowej',
    'Incorrect booking time': 'Niepoprawny czas rezerwacji',
    'Invalid e-mail address': 'Niepoprawny format adresu e-mail',
    invalid_zip_code: 'Prawidłowy format kodu to XX-XXX, gdzie X oznacza cyfrę',
  },
  aboutUs: {
    'About us': 'O nas',
    'row-1.p1': `Rezerwuj sale taneczne w Polsce online`,
    'row-1.p2': `
    <ol>
      <li>Bezobsługowa rezerwacja – Rezerwujesz -> Płacisz -> Korzystasz</li>
      <li>Natychmiastowa informacja o dostępności sal 24/7</li>
      <li> Możliwość odwołania sali do 24h przed rezerwacją (za opłatą zgodną z aktualnym Regulaminem)</li>
      <li>Ty wybierasz salę i lokalizację</li>
      <li>Płatności online</li>
      <li>Faktura automatycznie wysyłana na maila od razu po opłaceniu rezerwacji.</li>
    </ol>
    `,
    'row-2.p1': `Rabat za rejestrację`,
    'row-2.p2': `Rewolucja dla instruktorów`,
    'row-3.p1': `Dla zarejestrowanych użytkowników przygotowaliśmy rabat, który będzie aktywny na koncie przez 7 dni.<br/>
      Wszystkie ceny spadną o 5zł/1h jeśli udostępnisz post na swoim facebook’u!<br/>
      Rabat jest odnawialny. Kolejny klik, kolejny tydzień obniżonych cen.<br/>
      Jak uruchomić rabat:<br/>
      Zaloguj się na konto<br/>
      Na górze strony pojawi się niebieski pasek z info o rabacie<br/>
      Kliknij w pasek, przekieruje Cię on do facebook’a<br/>
      Kliknij „Udostępnij”<br/>
      Upewnij się, że post jest publiczny<br/>
      Wróć do saletaneczne.pl i rezerwuj<br/>
      Zapoznaj się z Regulaminem, a w razie pytań pisz do nas na <a href="https://www.facebook.com/saletanecznepl" target="_blank">facebook’u</a>`,
    'row-3.p2': `Para odwołała zajęcia pierwszego tańca? Klient nagle „zachorował”?
      Teraz to klientów możesz poprosić o zarezerwowanie sali przez nasz system.
      To na nich przejdzie obowiązek zapłaty, pilnowania rezerwacji i wybór sali.`,
    'row-4.quote': `Sukces nie polega na osiąganiu czegoś w przyszłości, lecz na robieniu tego co kochasz teraz`,
    'row-5.p1': `Misją naszej firmy jest tworzenie profesjonalnych przestrzeni tanecznych.  Sale dedykujemy zarówno zawodowcom, jak i miłośnikom tańca.<br/><br/>
      Głównymi zaletami współpracy w tym modelu są dostęp do powierzchni różnej wielkości, absolutnie dowolne godziny zajęć oraz realizacja rezerwacji tu i teraz, bez oczekiwania na odpowiedź szkoły.<br/>
      W praktyce ten system doskonale sprawdza się w pracy instruktora, który potrzebuje natychmiastowej informacji dla klienta, czy w danym terminie zajęcia mogą się odbyć.<br/><br/>
      Jest to świetne rozwiązanie dla tancerza, który szuka miejsca do treningu w godzinach, które mu pasują. Znajdziesz tutaj salę dostępną nawet w Nowy Rok o 8:00 rano, nie wspominając o weekendach, popołudniach i porankach. Sale dostępne są ZAWSZE.<br/>
      Z każdym miesiącem dołączają do nas kolejne szkoły tańca. W tym modelu nie trzeba się zastanawiać, jak zapełnić salę poza prime time, to nasz problem. Wy robicie to, co kochacie, pracujecie z kursantami wtedy, kiedy chcecie. Jeśli jest taka potrzeba, możecie rezerwować kilka sal równocześnie o godzinie 19:00. Nie ma problemu😉<br/><br/>
      Na naszym koncie wybiło już ponad 200.000 zrealizowanych godzin rezerwacji. Posiadamy 40 sal, w 5 lokalizacjach, w 3 miastach i wciąż się rozwijamy😉`,
  },
  investorRelations: {
    title: 'Relacje inwestorskie',
  },
  percent: {
    'Tax Deduction - 1% Donation': 'Przekaż 1% podatku',
    'row-1.p1': `Jestem tancerzem, trenerem i&nbsp;współzałożycielem klubu tańca towarzyskiego. Od wielu lat zajmujemy się pracą pedagogiczną na różnych poziomach,
    dla osób w każdym wieku. Wieloletnia pasja i&nbsp;zaangażowanie pozwoliły nam stworzyć unikatowe miejsce na tanecznej mapie Polski. W&nbsp;obrębie naszych sal pracuje
    na co dzień przeszło pół tysiąca tancerzy, instruktorów i&nbsp;szkół tańca.<br/><br>
Zdajemy sobie sprawę, że niemożliwym jest prosić kogokolwiek o&nbsp;realne wsparcie finansowe, dlatego prosimy o&nbsp;przekazanie 1% podatku na rzecz naszej działalności<br/><br/><b>KRS: 0000270261</b><br/><b>Cel szczegółowy: MACIEJ HOLSKI 9726</b>`,
  },
  premiumLanding: {
    'Become PREMIUM user': 'Zostań użytkownikiem PREMIUM',
    'row-1.p': `Korzyści konta Premium:`,
    'list-item.1': 'Niższe ceny o 5zł/1h *',
    'list-item.2': 'Możliwość odwołania rezerwacji do 12h przed rezerwacją',
    'list-item.3':
      'Dostęp do dodatkowych pakietów obniżających ceny o KOLEJNE 10zł/1h',
    'Get premium account': 'Chcę zostać użytkownikiem premium',
    'plans.header': 'Dostępne plany',
    month: 'miesiąc',
    months: 'miesiące',
    'asterix-info':
      '*od cen bez rejestracji konta. Np. Warszawa, 3 osoby, g.10-11 25zł-5zł=20zł/1h',
    'already-premium':
      'Posiadasz aktywne konto <1>PREMIUM</1> (ważne do {{availableTo}})',
  },
  promotions: {
    'row-1.p':
      'Rezerwujesz sale najczęściej w tych samych godzinach? Może zainteresują Cię dodatkowe pakiety.',
    'row-2.p':
      'Obniżają ceny aż o dodatkowe 10zł/1h w różnych przedziałach czasowych.',
    'row-3.p':
      'Pakiety Poranki, Wieczory i Weekendy łączą się. Pakiet Zawodowiec nie łączy się z pozostałymi promocjami.',
    'Buy more discounts': 'Kup dodatkowe rabaty',
    "There's no special offers": 'Obecnie nie ma dostępnych żadnych promocji.',
    Discount: 'Zniżka',
    Availability: 'Ważny przez',
    Price: 'Cena',
    Limit: 'Dzienny limit',
    Description: 'Opis',
    'Buy now': 'Kup teraz',
    'To buy, choose duration': 'Wybierz okres',
    Buy: 'Kup',
    days: 'dni',
    hour_0: '{{count}} godzina',
    hour_1: '{{count}} godziny',
    hour_2: '{{count}} godzin',
    minutes: 'minut',
    'Buy discount': 'Kup zniżkę',
    'buy.promotion.modal.description':
      'Aby wykupić dodatkowy PAKIET, wprowadź dane płatnika i kliknij przycisk KUPUJĘ',
    'buy.promotion.modal.statement':
      'Proszę o natychmiastowe rozpoczęcie świadczenia treści cyfrowych (przed upływem 14-dniowego terminu na odstąpienie od umowy). Tym samym przyjmuję do wiadomości, że od tego momentu nie mogę odstąpić od umowy.',
    Duration: 'Okres',
    'Select duration': 'Okres',
  },
  privacyPolicy: {
    'Privacy Policy': 'Polityka prywatności saletaneczne.pl',
    content: `
    <h3>1. Informacje ogólne</h3>
    <p>1. Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: https://saletaneczne.pl ('Serwis'). Wszystkie sformułowania pisane w niniejszej Polityce wielką literą i w niej nie zdefiniowane powinny być rozumiane zgodnie z definicjami wynikającymi z Regulaminu Serwisu saletaneczne.pl.</p>
    <p>2. Operatorem serwisu oraz administratorem danych osobowych Użytkowników Serwisu jest firma: Dance Rooms Europe Sp. z o.o. z siedzibą w Warszawie: Al. Jerozolimskie 123a, 02-017 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0001057815, REGON: 526386363, NIP: 7011164542 o kapitale zakładowym wynoszącym 5000,00 zł (słownie: pięć tysięcy i 00/100 złotych) ('Operator').</p>
    <p>3. Adres kontaktowy poczty elektronicznej Operatora: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>
    <p>4. Operator jest administratorem danych osobowych Użytkowników w odniesieniu do danych podanych dobrowolnie w Serwisie.</p>
    <p>5. Serwis wykorzystuje dane osobowe w następujących celach:</p>
    <ul>
      <li>Publikacja Ogłoszeń;</li>
      <li>Przekazywanie informacji o rezerwacji obiektów rekreacyjnych do Właścicieli obiektów;</li>
      <li>Wyświetlanie wybranej nazwy użytkownika na tablicy w miejscu rezerwacji Sali tanecznej.</li>
    </ul>
    <p>6. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:</p>
    <ol>
      <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
      <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</li>
    </ol>

    <h3>2. Zasady przetwarzania danych osobowych Użytkowników Serwisu przez Operatora</h3>
    <p>1. Administratorem danych osobowych Użytkowników Serwisu jest Operator.</p>
    <p>2. Dane osobowe będą przetwarzane przez Operatora dla celów niezbędnych do świadczenia usług w ramach Serwisu i wykonania umowy pomiędzy Operatorem a Użytkownikiem, w tym – w przypadku Właściciela obiektu - świadczenia na jego rzecz Usług Operatora (art. 6 ust. 1 pkt. b. RODO). Operator może również wykorzystywać dane osobowe Użytkownika do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora (art. 6 ust. 1 pkt. f. RODO), w szczególności marketingu usług świadczonych przez Operatora, w tym profilowania danych osobowych dla powyższych potrzeb, jak również dla potrzeb tworzenia statystyk, z zastrzeżeniem, iż Użytkownik ma prawo w każdym czasie zgłosić sprzeciw wobec przetwarzania jego danych osobowych dla celów, o których mowa w niniejszym zdaniu.</p>
    <p>3. Dla celów marketingowych Operator może również przetwarzać dane osobowe na podstawie odrębnie wyrażonej zgody Użytkownika, w zakresie wynikającym z takiej zgody (art. 6 ust. 1 pkt. a. RODO). Wyrażenie tej zgody jest dobrowolne, a Użytkownik może ją w dowolnym momencie wycofać, z tym, że wycofanie zgody nie będzie miało wpływu na zgodność z prawem przetwarzania danych osobowych na podstawie zgody przed jej wycofaniem.</p>
    <p>4. Dane osobowe Właściciela obiektu będą widoczne w ramach Serwisu dla wszystkich osób, które będą przeglądały treść Serwisu.</p>
    <p>5. Dane osobowe Użytkownika będą przekazywane Właścicielowi obiektu w chwili dokonania Rezerwacji. Dane będą przekazywane Wynajmującemu jako odrębnemu administratorowi danych osobowych. Akceptacja Regulaminu stanowi zgodę na przekazanie przez Operatora danych osobowych Użytkownika Właścicielowi obiektu jako odrębnemu administratorowi danych. Ust. 4 stosuje się odpowiednio.</p>
    <p>6. Z zastrzeżeniem ust. 5 i 6 powyżej, dane osobowe Użytkowników mogą być przekazywane podmiotom przetwarzającym je na zlecenie Operatora, w szczególności podwykonawcom Operatora świadczącym usługi informatyczne, utrzymania Serwisu itp.</p>
    <p>7. Dane osobowe Użytkowników, którzy dokonali Rezerwacji i Rejestracji w Serwisie, będą przechowywane przez Operatora przez okres korzystania z Serwisu i 24 miesięcy od ostatniego zalogowania do Serwisu.</p>
    <p>8. W odniesieniu do danych osobowych przetwarzanych dla potrzeb marketingowych, Operator będzie je przetwarzać do czasu uzyskania ewentualnego sprzeciwu Użytkownika bądź cofnięcia zgody przez Użytkownika.</p>
    <p>9. Operator będzie przetwarzać wszystkie lub niektóre z następujących danych osobowych Użytkowników: imię i nazwisko, firma prowadzonej przez Użytkownika działalności gospodarczej, adres Użytkownika, numer identyfikacji podatkowej NIP, adres e-mail, numer telefonu kontaktowego.</p>
    <p>10. Oprócz pozostałych uprawnień, o których mowa w niniejszym artykule Regulaminu, Użytkownik ma prawo do żądania od Operatora dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, jak również prawo do przenoszenia danych oraz wniesienia skargi do organu nadzorczego: Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</p>
    <p>11. Podanie danych osobowych jest dobrowolne, przy czym jest ono niezbędne dla potrzeb Rejestracji i korzystania z funkcjonalności zamieszczania Ogłoszeń oraz dokonywania Rezerwacji.</p>
    <p>12. W sprawach danych osobowych można kontaktować się poprzez email <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>

    <h3>3. Wybrane metody ochrony danych stosowane przez Operatora</h3>
    <p>1. Hasła Użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.</p>
    <p>2. W celu ochrony danych Operator regularnie wykonuje kopie bezpieczeństwa.</p>

    <h3>4. Logi Administratora</h3>
    <p>1. Informacje o zachowaniu Użytkowników w Serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania Serwisem.</p>

    <h3>5. Istotne techniki marketingowe</h3>
    <p>1. Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics, Google Tag Manager (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: <a href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a></p>
    <p>2. Operator stosuje techniki remarketingowe, pozwalające na dopasowanie przekazów reklamowych do zachowania użytkownika na stronie, co może dawać złudzenie, że dane osobowe użytkownika są wykorzystywane do jego śledzenia, jednak w praktyce nie dochodzi do przekazania żadnych danych osobowych od Operatora do operatorom reklam. Technologicznym warunkiem takich działań jest włączona obsługa plików cookie.</p>
    <p>3. Operator korzysta z piksela Facebooka. Ta technologia powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym wypadku na danych, wobec których sam jest administratorem, Operator nie przekazuje od siebie żadnych dodatkowych danych osobowych serwisowi Facebook. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.</p>
    <p>4. Operator stosuje rozwiązanie automatyzujące działanie Serwisu w odniesieniu do użytkowników, np. mogące przesłać maila do użytkownika po odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na otrzymywanie korespondencji handlowej od Operatora.</p>

    <h3>6. Informacja o plikach cookies</h3>
    <p>1. Serwis korzysta z plików cookies.</p>
    <p>2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
    <p>3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest Operator Serwisu.</p>
    <p>4. Pliki cookies wykorzystywane są w następujących celach:</p>
    <ol>
      <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
      <li>realizacji celów określonych powyżej w części "Istotne techniki marketingowe";</li>
    </ol>
    <p>5. W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez użytkownika.</p>
    <p>6. Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym użytkownika. Każdy użytkownik Serwisu może dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p>
    <p>7. Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</p>
    <p>8. Pliki cookies zamieszczane w urządzeniu końcowym użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</p>

    <h3>7. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</h3>
    <p>1. Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</p>
    <p>2. W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:</p>
    <ul>
      <li>Edge</li>
      <li>Chrome</li>
      <li>Safari</li>
      <li>Firefox</li>
      <li>Opera</li>
    </ul>

    <p>Urządzenia mobilne:</p>
    <ul>
      <li>Android</li>
      <li>Safari (iOS)</li>
    </ul>
    `,
  },
  regulations: {
    Regulations: 'Regulamin',
    RegulationsIndividual:
      'Regulamin Serwisu saletaneczne.pl dla Klientów Indywidualnych',
    RegulationsCompany:
      'Regulamin Serwisu saletaneczne.pl dla Klientów Biznesowych',
    content: `

    <h3>1. Przedmiot Regulaminu</h3>
    <p>Regulamin określa zasady dotyczące rejestracji w Serwisie „saletaneczne.pl” podmiotów zainteresowanych wynajęciem sal, jak również zasady dotyczące wynajmu sal oraz ich rezerwacji i dokonywania płatności za wynajem.</p>

    <h3>2. Definicje</h3>
    <p>Dla potrzeb interpretacji Regulaminu przyjmuje się następujące definicje:</p>

    <dl>
      <dt>Dostawca Płatności</dt>
      <dd>oznacza podmiot świadczący, na podstawie odpowiednich przepisów prawa i posiadanych uprawnień, usługi płatnicze umożliwiające dokonanie Opłaty, w tym portal www.przelewy24.pl;</dd>

      <dt>Najemca</dt>
      <dd>oznacza przedsiębiorcę lub inny podmiot, któremu przysługuje zdolność do czynności prawnych, który jest zainteresowany wynajmem Powierzchni;</dd>

      <dt>Ogłoszenie</dt>
      <dd>oznacza zaproszenie do zawarcia Umowy Najmu w rozumieniu art. 71 kodeksu cywilnego, zamieszczone w Serwisie przez Operatora na zlecenie Wynajmującego, określające podstawowe warunki Umowy Najmu, w szczególności określenie Powierzchni, możliwe daty jej wynajęcia, wynagrodzenie (czynsz) za wynajem Powierzchni, zasady dotyczące możliwości odstąpienia od Umowy Najmu i ewentualnie dodatkowe warunki korzystania z Powierzchni, w szczególności dotyczące świadczeń dostępnych w ramach Umowy Najmu;</dd>
      <dt>Operator</dt>
      <dd>oznacza podmiot, któremu przysługują prawa do Serwisu, tj. firmę: Dance Rooms Europe Sp. z o.o. z siedzibą w Warszawie: Al. Jerozolimskie 123a, 02-017 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0001057815, REGON: 526386363, NIP: 7011164542 o kapitale zakładowym wynoszącym 5 000,00 zł (słownie: pięć tysięcy i 00/100 złotych), e-mail: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>;</dd>

      <dt>Opłata</dt>
      <dd>oznacza łączną kwotę należną na podstawie Umowy Najmu od Najemcy na rzecz Wynajmującego, obejmująca w szczególności wynagrodzenie Wynajmującego (czynsz) za udostępnienie na rzecz Najemcy Powierzchni w okresie objętym Umową Najmu;</dd>

      <dt>Powierzchnie</dt>
      <dd>oznaczają powierzchnie sal tanecznych, prezentowane przez Wynajmujących w Serwisie, będące przedmiotem Umowy Najmu w przypadku jej zawarcia;</dd>

      <dt>Potrącenie</dt>
      <dd>oznacza kwotę, która zostanie potrącona ze zwracanej Opłaty za Rezerwację. Aktualna kwota Potrącenia jest dostępna w załączniku nr 1 niniejszego Regulaminu.</dd>

      <dt>Regulamin</dt>
      <dd>oznacza niniejszy regulamin;</dd>

      <dt>Rejestracja</dt>
      <dd>oznacza proces zakładania przez Użytkownika konta w Serwisie, zawierającego jego dane, służące do jego identyfikacji w Serwisie; </dd>

      <dt>Rezerwacja</dt>
      <dd>złożenie przez Najemcę oświadczenia woli za pośrednictwem Serwisu odnośnie do chęci wynajmu Powierzchni w określonym terminie oraz za określona kwotę. Dokonanie rezerwacji oznacza akceptację podstawowych warunków Umowy Najmu, w pozostałym zakresie do Umowy Najmu zastosowanie znajdują przepisy Kodeksu Cywilnego i/lub innych ustaw; </dd>

      <dt>RODO</dt>
      <dd>oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE; </dd>

      <dt>Serwis</dt>
      <dd>oznacza platformę internetową pod adresem <a href="https://www.saletaneczne.pl">www.saletaneczne.pl</a>, w tym jej podstrony, która umożliwia prezentację przez Wynajmujących oraz zapoznanie się przez potencjalnych Najemców z informacjami o dostępności Powierzchni i warunkach ich wynajmu, jak również umożliwia Rezerwację Powierzchni przez Najemcę;</dd>

      <dt>Sklepik Promocji</dt>
      <dd>oznacza zbiór usług, które są dostępne dla Użytkownika Premium;</dd>

      <dt>Umowa Najmu</dt>
      <dd>oznacza umowę najmu Powierzchni, zawartą pomiędzy Najemcą a Wynajmującym na skutek złożenia Rezerwacji przez Najemcę w ramach Serwisu;</dd>

      <dt>Usługa Operatora</dt>
      <dd>oznacza usługę świadczoną przez Operatora na rzecz Wynajmującego, polegającą na pośredniczeniu na rzecz Wynajmującego w zawarciu Umowy Najmu; w ramach realizacji Usługi Operatora, Operator wykonuje na rzecz Wynajmującego czynności zmierzające do zawarcia Umowy Najmu;</dd>

      <dt>Użytkownik</dt>
      <dd>oznacza wszystkich użytkowników Serwisu, tj. Wynajmujących i Najemców;</dd>

      <dt>Użytkownik Zarejestrowany</dt>
      <dd>oznacza użytkowników (tj. Najemców), którzy zarejestrowali konto w Serwisie;</dd>

      <dt>Użytkownik Premium</dt>
      <dd>oznacza zarejestrowanych użytkowników, który wykupili dostęp do konta Premium;</dd>

      <dt>Wynajmujący</dt>
      <dd>oznacza przedsiębiorcę, będącego właścicielem Powierzchni bądź któremu przysługują odpowiednie prawa do Powierzchni, umożliwiające jej wynajem na rzecz Najemcy zgodnie z warunkami zaprezentowanymi w Serwisie.</dd>

    </dl>

    <h3>3. Rezerwacja Powierzchni przez Serwis</h3>
    <ol>
      <li>Jeśli Najemca będzie zainteresowany wynajmem Powierzchni i z treści Ogłoszenia będzie wynikało, że jest ona dostępna w żądanych przez Najemcę dniach i godzinach, Najemca może dokonać Rezerwacji Powierzchni.</li>
      <li>Dokładny proces Rezerwacji następuje zgodnie z instrukcjami wynikającymi z Serwisu.</li>
      <li>Dla potrzeb Rezerwacji i korzystania z Serwisu niezbędne jest posiadanie przez Użytkownika urządzenia z dostępem do sieci Internet i przeglądarką internetową (typu Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Safari itp.) oraz aktywnego konta poczty elektronicznej.</li>
      <li>Rezerwacja przekazywana jest za pośrednictwem Serwisu do Wynajmującego, wraz z danymi Najemcy, co Najemca akceptuje.</li>
      <li>Umowa Najmu będzie uważana za zawartą z chwilą opłacenia Rezerwacji przez Najemcę. Możliwe jest rozwiązanie Umowy Najmu poprzez odwołanie Rezerwacji zgodnie z par. 6 niniejszego Regulaminu.</li>
      <li>Po dokonaniu Rezerwacji Najemca otrzyma wiadomość e-mail na adres podany w formularzu potwierdzający dokonanie Rezerwacji.</li>
      <li>Warunkiem dokonania Rezerwacji jest akceptacja Regulaminu, który określa stosunek prawny między Operatorem a Użytkownikiem, w tym zawiera informacje dotyczące zasad przetwarzania przez Operatora danych osobowych Użytkownika, będącego osobą fizyczną.</li>
      <li>Podczas Rezerwacji Użytkownik zobowiązany jest do podania prawdziwych danych. Użytkownik może w każdym czasie dokonać zmiany danych podanych podczas Rejestracji. Ilekroć Regulamin mówi o danych podanych podczas Rejestracji, należy przez to rozumieć odpowiednio dane zmodyfikowane przez Użytkownika w późniejszym okresie.</li>
      <li>Poprzez akceptację Regulaminu i dokonanie Rezerwacji Najemca zobowiązuje się wobec Operatora, iż:
        <ol>
          <li class="lower-alpha">posiada pełne prawa umożliwiające Najemcy zawarcie z Wynajmującym ważnej i skutecznej Umowy Najmu oraz odpowiednie środki finansowe w celu jej realizacji;</li>
          <li class="lower-alpha">Najemca podał w trakcie Rejestracji prawdziwe dane.</li>
        </ol>
      </li>
      <li>Ponadto, poprzez akceptację Regulaminu i dokonanie Rezerwacji Użytkownicy przyjmują do wiadomości i akceptują, iż:
        <ol>
          <li class="lower-alpha">do stosunków prawnych pomiędzy Użytkownikiem a Operatorem będą znajdować zasady wynikające z Regulaminu, w tym zasady dotyczące odpowiedzialności Operatora oraz powszechnie obowiązujących przepisów prawa;</li>
          <li class="lower-alpha">Operator nie weryfikuje prawidłowości danych podanych przez Użytkownika w procesie Rejestracji i nie ponosi odpowiedzialności za ich prawidłowość i rzetelność oraz ewentualne negatywne konsekwencje podania przez Użytkownika nieprawidłowych lub niepełnych danych;</li>
          <li class="lower-alpha">Operator nie ponosi odpowiedzialności wobec Najemcy ani Wynajmującego za należyte wykonanie Umowy Najmu, w tym w szczególności nie ponosi odpowiedzialności za stan Powierzchni, zapewnienie dodatkowego wyposażenia bądź innych świadczeń przez Wynajmującego;</li>
          <li class="lower-alpha">wszelkie reklamacje składane przez Najemcę odnośnie do realizacji Umowy Najmu powinny być składane bezpośrednio wobec Wynajmującego i przez niego wyjaśniane i rozstrzygane.</li>
        </ol>
      </li>
      <li>Operator ma prawo do nałożenia kary na Użytkownika, jeśli naruszy on zasady Umowy Najmu a w szczególności, kiedy liczba osób na Sali będzie różna od tej wykazanej w dokonanej Rezerwacji i jeśli różnica ta będzie miała wpływ na cenę Sali. Wysokość kary jest wyszczególniona w Załączniku nr 1 niniejszego Regulaminu.</li>
      <li>Odpowiedzialność Operatora wobec Użytkownika niebędącego konsumentem w rozumieniu art. 221 kodeksu cywilnego za wszelkie szkody poniesione przez Użytkownika na skutek niewykonania lub nienależytego wykonania Usług Operatora bądź inne działania lub zaniechania Operatora będzie ograniczona do kwoty 1.000 PLN.</li>
      <li>W przypadku, jeśli Najemcą będzie konsument w rozumieniu art. 221 kodeksu cywilnego, tj. osoba fizyczna dokonująca z przedsiębiorcą (Wynajmującym lub Operatorem) czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową, nie będzie znajdować zastosowania ograniczenie odpowiedzialności Operatora, o którym mowa w ust. 11 powyżej, jak również nie będą znajdować zastosowania inne ograniczenia lub wyłączenia odpowiedzialności Operatora wynikające z Regulaminu w zakresie, w jakim nie zezwalają na nie obowiązujące przepisy prawa.</li>
    </ol>

    <h3>4. Rejestracja w Serwisie, uprawnienia konta Użytkownika</h3>
    <ol>
      <li>Rejestracja w Serwisie następuje poprzez wypełnienie formularza rejestracyjnego dostępnego na stronie Serwisu. Pierwsze zalogowanie się do konta jest równoznaczne z jego aktywowaniem. W razie utraty loginu lub hasła dostępu do konta, Użytkownik może skorzystać z procedury ich odzyskiwania, klikając na odpowiednie pola dostępne w Serwisie.</li>
      <li>Dokładny proces Rejestracji Użytkowników następuje zgodnie z instrukcjami wynikającymi z Serwisu.</li>
      <li>Dla potrzeb Rejestracji i korzystania z Serwisu niezbędne jest posiadanie przez Użytkownika urządzenia z dostępem do sieci Internet i przeglądarką internetową (typu Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Safari itp.) oraz aktywnego konta poczty elektronicznej.</li>
      <li>Podczas Rejestracji Użytkownik zobowiązany jest do podania prawdziwych danych. Użytkownik może w każdym czasie dokonać zmiany danych podanych podczas Rejestracji. Ilekroć Regulamin mówi o danych podanych podczas Rejestracji, należy przez to rozumieć odpowiednio dane zmodyfikowane przez Użytkownika w późniejszym okresie.</li>
      <li>Użytkownik powinien zachować w tajemnicy i odpowiednio chronić login i hasło.</li>
      <li>W najszerszym dopuszczalnym prawem zakresie, Operator nie ponosi odpowiedzialności za skutki podania przez Użytkownika błędnych lub niepełnych danych oraz za skutki posłużenia się loginem i hasłem Użytkownika przez osobę trzecią.</li>
      <li>Użytkownik ma prawo w każdym czasie rozwiązać stosunek prawny łączący go z Operatorem, którego zasady wynikają z Regulaminu, poprzez usunięcie konta Użytkownika w Serwisie. Operator ma prawo rozwiązać umowę z Użytkownikiem Premium na korzystanie z Serwisu z zachowaniem siedmiodniowego okresu wypowiedzenia z ważnych przyczyn.</li>
    </ol>

    <h3>5. Odwołanie Rezerwacji</h3>
    <ol>
      <li>Użytkownik ma prawo Odwołać Rezerwację bez podania przyczyny.</li>
      <li>Jeśli odwołanie Rezerwacji nastąpi do 24 godzin przed Rezerwacją Użytkownik otrzyma zwrot Opłaty za Rezerwację pomniejszoną o kwotę Potrącenia.</li>
      <li>Jeśli odwołanie Rezerwacji nastąpi później niż 24 godziny przed Rezerwacją Użytkownik nie otrzyma zwrotu Opłaty.</li>
      <li>Dokładny proces Odwołania Rezerwacji i Zwrotu nastąpi zgodnie z instrukcjami wynikającymi z Serwisu.</li>
      <li>Zwrot Opłaty nastąpi przez Operatora Płatności.</li>
    </ol>

    <h3>6. Opłaty za Rezerwacje</h3>
    <ol>
      <li>Wysokość Opłaty wynika z Umowy Najmu zawartej pomiędzy Wynajmującym a Najemcą. Wysokość Opłaty będzie wskazana w Serwisie, po wyborze przez Najemcę Ogłoszenia.</li>
      <li>W przypadku dojścia do zawarcia Umowy Najmu, Najemca jest zobowiązany do dokonania Opłaty na rzecz Wynajmującego zgodnie z zawartą przez Strony Umową Najmu, przy czym dokonanie Opłaty następuje przez Operatora Płatności.</li>
      <li>Najemca musi dokonać Opłaty wynikającej z Umowy Najmu niezwłocznie po zawarciu Umowy Najmu.</li>
      <li>W przypadku, jeśli Najemca nie dokona Opłaty Rezerwacja zostanie automatycznie skasowana po upływie 15 minut od potwierdzenia Rezerwacji.</li>
      <li>Wynajmujący będzie wystawiać na Najemcę faktury na kwoty Opłat zgodnie z obowiązującymi przepisami prawa i postanowieniami Umowy Najmu.</li>
      <li>Uzgodnione zostaje, że po anulowaniu Rezerwacji przez Najemcę zostanie wystawiona faktury korygująca na kwotę zgodną z wysokością Potrącenia.</li>
      <li>Usługa płatnicza jest oferowana Najemcy przez Dostawcę Płatności, przy czym lista dostępnych Dostawców Płatności jest zawarta w Serwisie. Przed dokonaniem płatności Najemca powinien zapoznać się z regulaminem płatności Dostawcy Płatności i go zaakceptować.</li>
      <li>Dokonanie Opłaty za pośrednictwem elektronicznych kanałów płatności zostanie potwierdzone przez Operatora w drodze przesłania Najemcy wiadomości e-mail.</li>
    </ol>

    <h3>7. Ochrona danych osobowych</h3>
    <ol>
      <li>W przypadku Użytkowników będących osobami fizycznymi, zastosowanie znajdą postanowienia niniejszego artykułu 8. Regulaminu.</li>
      <li>Administratorem danych osobowych Użytkownika jest Operator, tj.: Usługi Taneczne Maciej Holski, Al. Jerozolimskie 123a, 00-017 Warszawa wpisana do Centralnej Ewidencji Działalności Gospodarczej, o numerze ewidencji podatkowej NIP: 9522052934, Regon: 367371440, e-mail: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
      <li>Dane osobowe będą przetwarzane przez Operatora dla celów niezbędnych do świadczenia usług w ramach Serwisu i wykonania umowy pomiędzy Operatorem a Użytkownikiem, w tym – w przypadku Wynajmującego – świadczenia na jego rzecz Usług Operatora (art. 6 ust. 1 pkt. b. RODO). Operator może również wykorzystywać dane osobowe Użytkownika do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora (art. 6 ust. 1 pkt. f. RODO), w szczególności marketingu usług świadczonych przez Operatora, w tym profilowania danych osobowych dla powyższych potrzeb, jak również dla potrzeb tworzenia statystyk, z zastrzeżeniem, iż Użytkownik ma prawo w każdym czasie zgłosić sprzeciw wobec przetwarzania jego danych osobowych dla celów, o których mowa w niniejszym zdaniu.</li>
      <li>Dla celów marketingowych Operator może również przetwarzać dane osobowe na podstawie odrębnie wyrażonej zgody Użytkownika, w zakresie wynikającym z takiej zgody (art. 6 ust. 1 pkt. a. RODO). Wyrażenie tej zgody jest dobrowolne, a Użytkownik może ją w dowolnym momencie wycofać, z tym, że wycofanie zgody nie będzie miało wpływu na zgodność z prawem przetwarzania danych osobowych na podstawie zgody przed jej wycofaniem.</li>
      <li>Dane osobowe Wynajmującego będą widoczne w ramach Serwisu dla wszystkich osób, które będą przeglądały treść Serwisu.</li>
      <li>Dane osobowe Najemcy będą przekazywane Wynajmującemu w chwili dokonania Rezerwacji. Dane będą przekazywane Wynajmującemu jako odrębnemu administratorowi danych osobowych. Akceptacja Regulaminu stanowi zgodę na przekazanie przez Operatora danych osobowych Najemcy Wynajmującemu jako odrębnemu administratorowi danych. Ust. 4 stosuje się odpowiednio.</li>
      <li>Z zastrzeżeniem ust. 5 i 6 powyżej, dane osobowe Użytkowników mogą być przekazywane podmiotom przetwarzającym je na zlecenie Operatora, w szczególności podwykonawcom Operatora świadczącym usługi informatyczne, utrzymania Serwisu itp.</li>
      <li>Dane osobowe Użytkowników, którzy dokonali Rejestracji w Serwisie, będą przechowywane przez Operatora przez okres korzystania z Serwisu i 24 miesięcy od ostatniego zalogowania do Serwisu.</li>
      <li>W odniesieniu do danych osobowych przetwarzanych dla potrzeb marketingowych, Operator będzie je przetwarzać do czasu uzyskania ewentualnego sprzeciwu Użytkownika bądź cofnięcia zgody przez Użytkownika.</li>
      <li>Operator będzie przetwarzać wszystkie lub niektóre z następujących danych osobowych Użytkowników: imię i nazwisko, firma prowadzonej przez Użytkownika działalności gospodarczej, adres Użytkownika, numer identyfikacji podatkowej NIP, adres e-mail, numer telefonu kontaktowego.</li>
      <li>Oprócz pozostałych uprawnień, o których mowa w niniejszym artykule Regulaminu, Użytkownik ma prawo do żądania od Operatora dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, jak również prawo do przenoszenia danych oraz wniesienia skargi do organu nadzorczego.</li>
      <li>Podanie danych osobowych jest dobrowolne, przy czym jest ono niezbędne dla potrzeb Rejestracji i korzystania z funkcjonalności zamieszczania Ogłoszeń oraz dokonywania Rezerwacji.</li>
      <li>Niniejszy Regulamin nie reguluje sposobu przetwarzania danych osobowych Najemcy przez Wynajmującego bądź Wynajmującego przez Najemcę. W szczególności, z chwilą przekazania Wynajmującemu danych osobowych Najemcy w wyniku dokonania Rezerwacji, wzajemne przetwarzanie danych osobowych przez każdego z Użytkowników podlega zasadom obowiązującego prawa, w szczególności Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych.</li>
      <li>Przekazanie przez Operatora danych osobowych Najemcy Wynajmującemu następuje wyłącznie dla potrzeb podjęcia przez Wynajmującego działań zmierzających do zawarcia Umowy Najmu na rzecz Najemcy, w tym w szczególności kontaktowania się przez Strony dla powyższych potrzeb. Przekazanie danych osobowych Najemcy Wynajmującemu ma charakter przekazania takich danych niezależnemu administratorowi. Wynajmujący przyjmuje do wiadomości, iż Wynajmującemu nie wolno korzystać z danych osobowych przekazanych przez Operatora w żadnym innym zakresie, w tym w szczególności dla potrzeb przekazywania Najemcy jakichkolwiek ofert czy innych informacji handlowych (oprócz danych oferty wynajmu Powierzchni, którą zainteresowanie wyraził Najemca), chyba że Wynajmujący niezależnie pozyska prawa do takich działań.</li>
      <li>W sprawach danych osobowych można kontaktować się poprzez email <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
    </ol>

    <h3>8. Wynajem sal w trakcie obowiązywania stanu epidemii</h3>
    <ol>
      <li>Do czasu ustąpienia stanu epidemii na terytorium RP najem lokalu dopuszczalny jest jedynie w przypadkach regulowanych przez rozporządzenia Rządu RP. W szczególności są to:
        <ol>
          <li class="lower-alpha">Działalność sportowych klubów tanecznych</li>
          <li class="lower-alpha">Działania niezbędne do przygotowania wydarzeń artystycznych, takich jak próby i ćwiczenia</li>
          <li class="lower-alpha">Nagrań fonograficznych i audiowizualnych</li>
          <li class="lower-alpha">Wydarzeń transmitowanych za pomocą środków bezpośredniego porozumiewania się na odległość.</li>
        </ol>
      </li>
    </ol>

    <h3>9. Konto i Pakiety Premium</h3>
    <ol>
      <li>Użytkownik Zarejestrowany ma możliwość wykupienia dostępu do Konta Premium zgodnie z instrukcjami wynikającymi z serwisu.</li>
      <li>Użytkownik Zarejestrowany musi dokonać Opłaty wynikającej z cennika Konta Premium niezwłocznie.</li>
      <li>Użytkownik Zarejestrowany może wykupić Konto Premium według instrukcji wynikającej z serwisu. Po wykupieniu Konta Premium staje się Użytkownikiem Premium.</li>
      <li>Nie ma możliwości rezygnacji z pakietu przed upływem wybranego okresu.</li>
      <li>Wraz z opłatą ma miejsce natychmiastowe rozpoczęcie świadczenia treści cyfrowych (przed upływem 14-dniowego terminu na odstąpienie od umowy). Tym samym po wykupieniu dostępu do Konta Premium oraz Pakietów Premium nie można odstąpić od umowy.</li>
      <li>Użytkownik Premium otrzymuje następujące korzyści:
        <ol>
          <li class="lower-alpha">Jeśli odwołanie Rezerwacji nastąpi do <b>12</b> godzin przed Rezerwacją Użytkownik otrzyma zwrot Opłaty za Rezerwację pomniejszoną o kwotę Potrącenia;</li>
          <li class="lower-alpha">Następuje aktywacja dostępu do Sklepiku Promocji;</li>
          <li class="lower-alpha">Cena za 1 godzinę Rezerwacji Sali zostaje obniżona o 5zł od ceny dla Użytkownika niezarejestrowanego w Serwisie przez wybrany okres aktywności konta Premium.</li>
        </ol>
      </li>
      <li>Każdy Pakiet ze Sklepiku Promocji obniża ceny o wskazaną w Serwisie cenę od ceny obowiązującej dla Użytkownika Premium w określonych w Systemie dniach i godzinach.</li>
      <li>Limit Rezerwacji w obniżonej cenie jest to ilość czasu jaką można zarezerwować w obniżonej cenie każdego dnia obowiązywania wykupionego Pakietu.</li>
    </ol>

    <h3>10. Postanowienia końcowe</h3>
    <ol>
      <li>Niniejszy Regulamin wchodzi w życie od dnia 08 czerwca 2020 roku.</li>
      <li>W przypadku zmiany Regulaminu, Operator powiadomi Użytkownika o zmianie w formie elektronicznej, na adres e-mail Użytkownika podany w Serwisie. Nowy Regulamin wiąże Użytkownika, jeśli Użytkownik nie wypowiedział Umowy w ciągu 14 dni od dnia otrzymania informacji o proponowanej zmianie. Oświadczenie o wypowiedzeniu może zostać złożone w formie elektronicznej, na adres e-mail Operatora wskazany w Regulaminie i będzie ono wywierało skutek natychmiastowy. Odpowiednie zastosowanie znajduje art. 3 ust. 16 Regulaminu. Przez wskazany powyżej 14-dniowy okres znajdują zastosowanie warunki Regulaminu w wersji dotychczasowej (sprzed proponowanej zmiany).</li>
      <li>Operator jest zobowiązany do świadczenia usług w sposób należyty (bez wad).</li>
      <li>Użytkownik ma prawo złożenia reklamacji do Operatora odnośnie do świadczonych przez Operatora usług lub jakości Serwisu, na adres e-mail Operatora wskazany w Regulaminie. Operator rozpatrzy reklamację Użytkownika i udzieli odpowiedzi nie później niż w ciągu 14 dni od otrzymania reklamacji. Odpowiedź zostanie przekazana Użytkownikowi na jego adres e-mail wskazany w Serwisie.</li>
      <li>Operator zastrzega sobie prawo czasowego zawieszenia dostępności Serwisu, w szczególności w przypadkach prowadzenia konserwacji, napraw bądź rozbudowy Serwisu.</li>
      <li>W najszerszym dopuszczalnym przez prawo zakresie Operator nie ponosi odpowiedzialności za blokowanie przez administratorów serwerów pocztowych przesyłania wiadomości na adres poczty elektronicznej wskazany przez Użytkownika oraz za usuwanie i blokowanie wiadomości e-mail przez oprogramowanie zainstalowane na komputerze używanym przez Użytkownika.</li>
      <li>Operator w najszerszym dopuszczalnym przez prawo zakresie nie ponosi odpowiedzialności za zakłócenia, w tym przerwy, w funkcjonowaniu Serwisu, w szczególności spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich lub niekompatybilność Serwisu z infrastrukturą techniczną Użytkownika bądź przyczynami leżącymi po stronie dostawców usług telekomunikacyjnych.</li>
      <li>W celach statystycznych oraz w celu zapewnienia jak najwyższej jakości usług Serwis korzysta z informacji zapisywanych przez serwer na urządzeniu końcowym Użytkownika, które następnie są odczytywane przy każdorazowym połączeniu się przeglądarki internetowej (tzw. cookies). Użytkownik może w dowolnym momencie zmienić ustawienia przeglądarki, by nie akceptowała takich plików lub informowała o ich przesyłaniu. Nieakceptowanie plików cookie może spowodować utrudnienia w korzystaniu z Serwisu. Korzystanie przez Użytkownika z przeglądarki, której ustawienia zezwalają na zapisywanie plików cookie na urządzeniu Użytkownika, oznacza wyrażenie zgody na zapisywanie powyższych plików na tym urządzeniu Użytkownika.</li>
      <li>Użytkownik zobowiązany jest do powstrzymywania się od działań, które mogłyby zakłócić prawidłowe funkcjonowanie Serwisu, w szczególności od ingerowania w oprogramowanie zapewniające funkcjonowanie Serwisu lub w treści prezentowane w Serwisie, a także od dostarczania treści o charakterze bezprawnym.</li>
      <li>Spory wynikające z relacji prawnych pomiędzy Użytkownikiem i Operatorem będą rozpoznawane przez sąd powszechny dla m.st. Warszawy. Powyższe nie dotyczy ewentualnych sporów z Najemcami będącymi konsumentami, które będą rozpoznawane przez sąd właściwy zgodnie z ogólnymi przepisami obowiązującego prawa bądź - jeśli Strony tak ustalą – przez uzgodniony przez Strony organ powołany do pozasądowego rozpoznawania sporów.</li>
      <li>W sprawach nieuregulowanych w Regulaminie mają zastosowanie przepisy prawa polskiego, w szczególności przepisy kodeksu cywilnego, a w przypadku Najemców będącymi konsumentami – również przepisy ustawy z dnia 30 maja 2014 r. o prawach konsumenta.</li>
    </ol>

    <br/>
    <br/>

    <h3><a name="zalacznik_1">ZAŁĄCZNIK nr 1</a></h3>
    <p><b>Tabela Opłat</b></p>

    <table>
      <tr>
        <th>Rodzaj Opłaty</th>
        <th>Wysokość Opłaty</th>
        <th>Komentarze</th>
      </tr>
      <tr>
        <td>Potrącenie</td>
        <td>6 zł brutto</td>
        <td>za każdą odwołaną godzinę</td>
      </tr>
      <tr>
        <td>Kara</td>
        <td>500 zł brutto</td>
        <td>za podanie innej niż faktyczna ilość osób na Sali, jeśli ma to wpływ na cenę sali</td>
      </tr>
    </table>

    `,
  },
  contactInfo: {
    content: `
      Email: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a><br/>
      Facebook Page: <a href="https://www.facebook.com/saletanecznepl" target="_blank">saletanecznepl</a><br/>
      <br/>
      Dance Rooms Europe Sp. z o.o.<br/>
      al. Jerozolimskie 123a<br/>
      02-017 Warszawa<br/>
      NIP: 7011164542<br/>
    `,
  },
  toastify: {
    'We sent you a password reset link. Check your email!':
      'Wysłaliśmy Ci link do resetowania hasła. Sprawdź swój email!',
    'We sent your contact request': 'Wysłaliśmy Twoją wiadomość.',
    'Your booking time limit expired.':
      'Upłynął limit czasu składania rezerwacji.',
    'Your booking schedule has been changed.':
      'Godziny Twojej rezerwacji zostały zmienione.',
    'Your account has been deleted.': 'Twoje konto zostało usunięte',
    'Your booking has been cancelled.': 'Twoja rezerwacja została anulowana.',
    'Your password has been changed.': 'Hasło zostało zmienione.',
    'Form contains some errors': 'Formularz zawiera błedy.',
    'Something went wrong. Contact us to book the room.':
      'Wystąpił problem z rezerwacją, skontaktuj się z nami w celu dokonania rezerwacji.',
  },
  order: {
    Date: 'Data zakupu',
    Price: 'Koszt zakupu',
  },
  booking: {
    typePrivate: 'Osoba fizyczna',
    selfEmployed: 'Jednoosobowa działalność gospodarcza',
    otherBusiness: 'Inny rodzaj działalności',
    typeCompany: 'Firma',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    Name: 'Imię i nazwisko',
    Phone: 'Numer telefonu',
    Street: 'Ulica',
    Country: 'Kraj',
    StreetNumber: 'Numer ulicy',
    FlatNumber: 'Numer mieszkania',
    'ZIP Code': 'Kod pocztowy',
    City: 'Miasto',
    Private: 'Konto prywatne',
    Company: 'Konto firmowe',
    'Company name': 'Nazwa firmy',
    'VAT Number': 'NIP',
    Next: 'Dalej',
    'You can cancel your booking within {{hours}} hours.':
      'Możesz anulować rezerwację do {{hours}} godzin przed rezerwacją.',
    'You are renting the room with the following options':
      'Podsumowanie warunków rezerwacji sali',
    'Billing details': 'Dane płatnika',
    Modify: 'Zmień',
    Invoice: 'Faktura',
    Book: 'Rezerwuj',
    Date: 'Data',
    rentDate: 'Data rezerwacji',
    For: 'Dla',
    From: 'Od',
    Hours: 'Godziny',
    to: 'do',
    'Room details': 'Twoja rezerwacja',
    NIP: 'NIP',
    'E-mail': 'E-mail',
    'Confirmation of the room booking':
      'Potwierdzenie rezerwacji sali {{room}}',
    'Booking status': 'Status rezerwacji',
    'Payment status': 'Płatność',
    'Booking number': 'Nr.rezerwacji',
    Localization: 'Lokalizacja',
    Room: 'Sala',
    title: 'Rezerwacja sali <b>{{ name }}</b>',
    step: 'krok {{ step }} z 2',
    'Book as guest user': 'Rezerwuj bez logowania',
    'Customer data': 'Dane płatnika',
    'Sign In': 'Zaloguj się',
    or: 'lub',
    Remember:
      'Zarejestruj się i sprawdź jak płacić mniej o 5zł / 1h przez 7 dni!',
  },
  pricing: {
    'PRICE LIST': 'CENNIK',
    Hours: 'Godziny',
    Capacity: 'Maksymalna liczba osób',
    'Gross price per hour': 'Cena brutto / h',
  },
  bookingStatus: {
    COMPLETE: 'Potwierdzona',
  },
  paymentStatus: {
    PAID: 'Opłacone',
    NOT_PAID: 'Nieopłacone',
    PROCESSING: 'Oczekiwanie',
    WILL_BE_PAID_IN_PERSON: 'Oczekiwanie',
    FAILED: 'Błąd opłaty',
  },
  validator: {
    'Incorrect VAT Number': 'Niepoprawny numer NIP',
  },
  cookies: {
    'This website uses cookies to enhance the user experience.':
      'Strona wykorzystuje pliki cookies. W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie. Jeśli wybierzesz opcję “Zaakceptuj wszystkie”, zaakceptujesz ten fakt i wyrazisz zgodę na przetwarzanie przez nas tych informacji. Jeśli odmówisz wykorzystamy tylko niezbędne pliki cookie. Wybierz “Ustawienia preferencji” aby zapoznać się ze szczegółami i zarządzać opcjami. Aby uzyskać więcej informacji, zapoznaj się z naszą <1>polityką prywatności</1>.',
    button: 'Zaakceptuj wszystkie',
    declineButton: 'Odrzuć wszystkie',
    modifyPreferences: 'Ustawienia preferencji',
    Preferences: 'Preferencje',
    'Manage preferences': 'Zarządzaj preferencjami',
    Essential:
      'Niezbędne pliki cookie - gwarantujące działanie witryny od strony technicznej. Zawsze włączone.',
    Analysis: 'Analityczne pliki cookie.',
    readMore: 'Zobacz więcej',
    readLess: 'Zobacz mniej',
    'Save preferences': 'Zapisz ustawienia',
    Statistic: 'Statystyka',
    statisticDescription: `<p>gtag.js oraz analytics.js ustawiaja następujące pliki cookie:</p><br/>`,
    statisticPoints: `
    <p> - <b>_ga</b>: służy do rozróżniania użytkowników na potrzeby gromadzenia informacji na temat odwiedzin na stronie</p>
    <p> - <b>_gid</b>: służy do rozróżniania użytkowników na potrzeby gromadzenia informacji na temat odwiedzin na stronie</p>
    <p> - <b>_gat</b>: jest wykorzystywany przez Google Analytics do monitorowania wskaźnika zapytań w odniesieniu do własnych serwerów.</p>
    `,
  },
  howToBook: {
    title: 'Jak zrobić rezerwację?',
    content: `<ol>
<li><p>Nie musisz się rejestrować/logować, żeby wykonać rezerwację.</p><p>Natomiast pamiętaj, że zalogowany użytkownik ma możliwość samodzielnego zarządzania swoimi rezerwacjami, a także wykonywania zadań marketingowych i zdobywania zniżek!</p></li>
<li><p>Wybierz miasto</p></li>
<li><p>Wybierz ilość osób (razem z prowadzącym)</p></li>
<li><p>Wybierz datę i godziny rezerwacji</p>
  <p>Pamiętaj! System szuka dla Ciebie wolnej Sali, dużo szybciej znajdziesz salępytając go o konkretne i wąskie zakresy godzin np. 18-20 niż o szerokie zapytanie typu 10-23</p></li>
  <li><p>Kliknij „szukaj”</p></li>
<li><p>System wskaże dostępne sale dla Twojego zapytania. Musisz dokonać wyboru, kliknij na zdjęcie na Sali</p>
<p>Pamiętaj! Jeśli pojawia się tylko 1 dostępna sala, wciąż musisz ją wybrać i kliknąć na jej zdjęcie!</p></li>
<li><p>System otworzy profil Sali i podsumuje Twoje zamówienie. Jeśli wszystko jest w porządku, przejdź dalej klikając „Zarezerwuj”</p></li>
<li><p>Przygotowujemy płatność za salę! Musisz wskazać swoje dane, których użyjemy do przygotowania faktury VAT. Jeśli FV będzie Twoim kosztem, upewnij się,że wprowadzasz poprawne dane swojej firmy. Na końcu nie zapomnij zaakceptować naszego Regulaminu! Kliknij „Dalej”.</p></li>
<li><p>System podsumuje Twoje dane do FV oraz jeszcze raz upewni Cię co do składanego zamówienia. Jeśli wszystko się zgadza – Kliknij „Rezerwuj”</li>
<li><p>Nastąpi przekierowanie do przelewy24.pl, dokonaj płatności</p><p>Jeśli wycofasz się z płatności, zostanie ona automatycznie anulowana.</p><p>Jeśli coś pójdzie nie tak masz ok.1 godzinę na opłacenie rezerwacji. Szczegóły w mailu z informacją, że rezerwacja jest PRZETWARZANA. Po tym czasie zostanie ona anulowana.</p></li>
<li><p></p>Jeśli wszystko się udało otrzymasz 4 maile: z fakturą (InFakt), ze szczegółami płatności (Przelewy24), z informacją, że rezerwacja jest Przetwarzana, z Potwierdzeniem rezerwacji.</li>
</ol>`,
  },
};
