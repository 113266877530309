import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

export const LineSeparator = styled(Box)`
  border-bottom: 1px solid #dcdcdc;
`;

export const Quote = styled(Text)`
  position: relative;
`;

export const QI = styled.span`
  /* position: absolute; */
  width: 26px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-image: url(${props =>
    props.up ? '/images/icons/quote-up.svg' : '/images/icons/quote-down.svg'});
  display: inline-block;
  position: relative;
  margin-left: ${props => (props.up ? '-26px' : '10px')};
  left: ${props => (props.up ? '-8px' : 'auto')};
  top: ${props => (props.up ? '-8px' : 'auto')};
  bottom: ${props => (props.up ? 'auto' : '-33px')};
  margin-top: ${props => (props.up ? '-50px' : '-33px')};
`;
