import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
  padding-top: 38px;

  > a {
    margin-left: 8px;
  }
`;

export const Description = styled.span`
  font-size: 16px;
  color: #000000;
`;

export const Link = styled(RouterLink)`
  font-size: 16px;
  color: #d9a248;
  text-decoration: none;
  font-weight: 900;
  cursor: pointer;
`;
