import styled from '@emotion/styled';
import { Heading, Flex, Image as RebassImage } from 'rebass';
import { Link } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

export const Card = styled(Link)`
  display: block;
  overflow: hidden;
  flex: 0 0 100%;
  padding: 10px;
  text-decoration: none;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  color: #b1b8bc;
  &:hover {
    transform: scale(1.01);
  }

  @media ${breakpoint.xs} {
    flex-basis: 100%;
    height: 314px;
  }

  @media (min-width: 540px) {
    flex-basis: 100%;
    /* height: 250px; */
  }
  @media ${breakpoint.md} {
    flex-basis: 33.33%;
    height: 535px;
  }
`;

export const Image = styled(RebassImage)`
  height: 377px;
  width: 100%;
  display: block;
  object-fit: cover;

  @media ${breakpoint.xs} {
    flex-basis: 100%;
    height: 185px;
  }
  @media ${breakpoint.md} {
    height: 377px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url('${props => props.image}');
  padding: 18px 0 0 18px;


  @media ${breakpoint.md} {
    padding: 49px 0 0 49px;
  }
`;

export const SimilarRoomWrapper = styled.div`
  background-color: #3e291c;
  width: 100%;

  @media ${breakpoint.xs} {
    height: 119px;
    padding: 13px;
  }
  @media ${breakpoint.md} {
    height: 164px;
    padding: 15px;
  }
  @media ${breakpoint.lg} {
    padding: 20px 30px;
  }
`;

export const SimilarRoomName = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;

  @media screen and (max-width: 40em) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const SimilarRoomSurface = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  align-self: flex-end;
  @media screen and (max-width: 40em) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const NameSurfaceWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 3px;

  @media ${breakpoint.xs} {
    padding-bottom: 3px;
  }
  @media ${breakpoint.md} {
    padding-bottom: 10px;
  }
`;

export const EmphLine = styled.img`
  position: absolute;
  bottom: 0;
  right: 4px;
`;

export const Title = styled(Heading)`
  font-size: 14px;
  font-weight: 900;
  color: #000000;

  @media ${breakpoint.xs} {
    font-size: 14px;
  }
  @media ${breakpoint.md} {
    font-size: 22px;
  }
`;
export const Price = styled.div`
  color: #b67c1e;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;

  @media ${breakpoint.xs} {
    margin-top: 10px;
    font-size: 14px;
  }
  @media ${breakpoint.md} {
    margin-top: 30px;
    font-size: 25px;
  }
`;
export const DiscountInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-top: 10px;
  max-width: 250px;

  @media ${breakpoint.xs} {
    margin-top: 10px;
    font-size: 12px;
    max-width: 160px;
  }
  @media ${breakpoint.md} {
    margin-top: 30px;
    font-size: 16px;
    max-width: 250px;
  }
`;

export const StyledLink = styled.button`
  border: none;
  padding: 0;
  margin: 10px 0 0 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  max-width: 250px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  @media ${breakpoint.xs} {
    margin-top: 10px;
    font-size: 12px;
    max-width: 160px;
  }
  @media ${breakpoint.md} {
    margin-top: 30px;
    font-size: 16px;
    max-width: 250px;
  }
  &:hover {
    color: #000000;
  }
  &:focus {
    outline: none;
  }
`;
