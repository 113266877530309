import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

const Wrapper = styled.main`
  padding: ${({ isAuthPage }) => (isAuthPage ? 0 : '30px 0')};
  margin: auto 0;

  @media screen and ${breakpoint.md} {
    margin: 0;
    flex: 1;
    display: flex;
    min-height: ${({ isHomepage }) => (isHomepage ? '900px' : 'unset')};
  }
`;

const MainContent = ({ children }) => {
  const { pathname } = useLocation();
  const isAuthPage = useMemo(() => pathname.indexOf('auth') !== -1, [pathname]);
  const isHomepage = useMemo(() => pathname === '/', [pathname]);

  return (
    <Wrapper isAuthPage={isAuthPage} isHomepage={isHomepage}>
      {children}
    </Wrapper>
  );
};

export default MainContent;
