import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { imagePath } from 'utils/imagePathHelper';
import Preloader from 'components/Preloader';
import { Container, Surface, SurfaceBorder } from 'styles/globalStyles';
import i18n from 'i18n';
import * as Styled from './style';

const BookingPageLayout = inject('bookingStore')(
  observer(
    ({
      match: {
        params: { slug },
      },
      location: { search },
      bookingStore: { room, initialized, initBooking, isLoading, step },
      children,
      complete,
      t,
    }) => {
      const init = async () => {
        if (!initialized && !complete) {
          await initBooking(slug, search);
        }
      };

      useEffect(() => {
        init();
      }, []);

      if (!initialized) return <Preloader h={500} />;

      const image =
        room?.images[room?.primaryImageIndex]?.thumbnails.big ||
        room?.images[room?.primaryImageIndex]?.src;

      let { name } = room;
      if (room[i18n.language] && room[i18n.language].name) {
        name = room[i18n.language].name;
      }

      return (
        <Container>
          <Styled.Title>
            <span
              dangerouslySetInnerHTML={{
                __html: t('booking:title', { name, step }),
              }}
            />
            {step && <Styled.Small>{t('booking:step', { step })}</Styled.Small>}
          </Styled.Title>

          <Styled.Wrapper>
            {isLoading || !initialized ? (
              <Preloader h={500} />
            ) : (
              <>
                <Styled.LeftColumn>{children}</Styled.LeftColumn>
                <Styled.ImageColumn image={imagePath(image)}>
                  <Surface bottom>
                    {room.surfaceArea} m<sup>2</sup>
                    <SurfaceBorder />
                  </Surface>
                </Styled.ImageColumn>
              </>
            )}
          </Styled.Wrapper>
        </Container>
      );
    },
  ),
);

BookingPageLayout.defaultProps = {
  complete: false,
};

BookingPageLayout.propTypes = {
  complete: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(withRouter, withTranslation());

export default enhance(BookingPageLayout);
