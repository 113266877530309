import React, { Component } from 'react';
import { Box } from 'rebass';
import Slider from 'react-slick';
import SimilarRoomTile from 'components/SimilarRoomTile';
import routes from 'routes';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { imagePath } from 'utils/imagePathHelper';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import i18n from 'i18n';
import * as Styled from './style';

const settings = {
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

@withRouter
@inject('authStore', 'searchStore')
@observer
class SimilarRooms extends Component {
  state = {};

  componentDidMount = async () => {
    await this.fetchRooms();
  };

  componentDidUpdate = async prevProps => {
    const {
      location: { search },
    } = this.props;
    if (!isEqual(prevProps.location.search, search)) {
      await this.fetchRooms();
    }
  };

  fetchRooms = async () => {
    const {
      searchStore: { fetchSimilarRooms },
    } = this.props;
    await fetchSimilarRooms();
  };

  formatTime = (startDate, endDate) => {
    return `${moment(startDate).format('DD.MM.YYYY [/] HH:mm')} - ${moment(
      endDate,
    ).format('HH:mm')}`;
  };

  getQueryString = (query, room) => {
    return queryString.stringify({
      date: moment(room.from).format('DD.MM.YYYY'),
      from: moment(room.from).format('HH:mm'),
      to: moment(room.to).format('HH:mm'),
      capacity: query.capacity,
      city: query.city,
    });
  };

  render() {
    const {
      searchStore: { similarRoomsList, isLoading },
      location: { search },
      t,
      authStore: { isAuthenticated },
    } = this.props;
    if (!similarRoomsList) return null;

    // disable infinite loop while there's less than 2 elements
    settings.infinite = similarRoomsList.length > 2;

    const query = queryString.parse(search);
    return (
      <Box pt={[21, 21, 21, 21, 57]} pb={[40, 40, 40, 40, 57]}>
        {similarRoomsList.length > 0 && !isLoading && (
          <>
            <Styled.Title>{t('Similar rooms')}:</Styled.Title>
            <Styled.Row>
              <Slider {...settings}>
                {similarRoomsList.map(room =>
                  room.items.map((el, index) => {
                    let { name } = el;
                    if (el[i18n.language] && el[i18n.language].name) {
                      name = el[i18n.language].name;
                    }

                    const image =
                      el.images[el.primaryImageIndex].thumbnails.small ||
                      el.images[el.primaryImageIndex].src;

                    return (
                      <SimilarRoomTile
                        key={index}
                        image={imagePath(image)}
                        similarRoomName={name}
                        similarRoomAddress={`${el.location.city}, ${el.location.street}`}
                        similarRoomAvailability={this.formatTime(
                          room.from,
                          room.to,
                        )}
                        similarRoomPrice={el.priceData.price / 100}
                        similarRoomCapacity={el.capacity}
                        similarRoomInfo={
                          el[i18n.language]
                            ? el[i18n.language].additionalLocationInfo
                            : null
                        }
                        similarRoomSurface={el.surfaceArea}
                        to={`${routes.description(
                          el.slug,
                        )}?${this.getQueryString(query, room)}`}
                        isAuthenticated={isAuthenticated}
                      />
                    );
                  }),
                )}
              </Slider>
            </Styled.Row>
          </>
        )}
      </Box>
    );
  }
}

export default withTranslation()(SimilarRooms);
