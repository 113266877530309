import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Card, Container, NotPublishedBadge } from 'styles/globalStyles';
import Preloader from 'components/Preloader';
import { Header, Content, PublishedDate } from 'containers/BlogPost/style';
import NotFound from 'components/NotFound';
import { formatDateFromAPI } from 'utils/datetimeHelpers';

const BlogPostPage = ({
  blogStore: {
    blogPost,
    fetchBlogPost,
    isLoadingBlogPost,
    resetBlogPost,
    blogPostInitialized,
  },
}) => {
  const params = useParams();
  useEffect(() => {
    fetchBlogPost(params.slug);

    return () => {
      resetBlogPost();
    };
  }, [params.slug]);

  if (isLoadingBlogPost || !blogPostInitialized) {
    return <Preloader />;
  }

  if (!blogPost || (blogPost && !Object.keys(blogPost).length)) {
    return <NotFound />;
  }

  return (
    <Container>
      {!blogPost.isPublished ? <NotPublishedBadge /> : null}
      <Header bg={blogPost.image.thumbnails.big}>
        <h1>{blogPost.title}</h1>
      </Header>

      <PublishedDate>{formatDateFromAPI(blogPost.createdAt)}</PublishedDate>

      <Card>
        <Content dangerouslySetInnerHTML={{ __html: blogPost.content }} />
      </Card>
    </Container>
  );
};

export default inject('blogStore')(observer(BlogPostPage));
