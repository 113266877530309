import React, { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import moment from 'moment-timezone';
import { ThemeProvider } from 'emotion-theming';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import theme from 'styles/theme';
import routes from 'routes';
import GlobalStyle from 'styles/globalStyles';
import MainContent from 'components/MainContent';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import MobileNav from 'components/NavBar/MobileNav';
import { size as breakpoint } from 'styles/breakpoints';
import CookieBanner from 'components/CookieBanner';

import ProtectedRoute from 'components/Auth/ProtectedRoute';
import NotFound from 'components/NotFound';

import ShopPage from 'containers/Shop'; // eslint-disable-line
import ProductPage from 'containers/Product'; // eslint-disable-line
import BlogPage from 'containers/Blog';
import BlogPostPage from 'containers/BlogPost';
import RoomsPage from 'containers/Rooms';

import Home from './home';
import SignInPage from './signIn';
import ResetPasswordPage from './resetPassword';
import RegisterPage from './register';
import SearchPage from './searchResults';
import ForgotPasswordPage from './forgotPassword';
import AboutUsPage from './aboutUs'; // eslint-disable-line
import InvestorRelationsPage from 'containers/InvestorRelations'; // eslint-disable-line
import HowToBookPage from './howToBook'; // eslint-disable-line
import PercentPage from './percent'; // eslint-disable-line
import PrivacyPolicyPage from './privacyPolicy';
import RegulationsPage from './regulations';
import SearchDetailPage from './roomDetails';
import BookingPage from './booking';
import BookingComplete from './booking/Complete';
import ContactPage from './contact';
import AccountPage from './account';
import PremiumLandingPage from './premium';
import PromotionsPage from './promotions';
import { history } from './_app/history';
import 'styles/toastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

moment.suppressDeprecationWarnings = true;

const App = () => {
  const shouldShowMobileNav = () => window.innerWidth < breakpoint.lg;
  const [mobileNavVisible, toggleMobileNavVisible] = useState(
    shouldShowMobileNav(),
  );

  let resizeDebouncer;
  const windowResizeHandler = () => {
    clearTimeout(resizeDebouncer);
    resizeDebouncer = setTimeout(() => {
      toggleMobileNavVisible(shouldShowMobileNav);
    }, 300);
  };

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <GlobalStyle />
        {mobileNavVisible && <MobileNav />}

        <NavBar />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnHover
          toastClassName="yellowbg"
        />
        <MainContent>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path={routes.auth.signIn} component={SignInPage} />
            <Route
              exact
              path={routes.auth.resetPassword()}
              component={ResetPasswordPage}
            />
            <Route exact path={routes.auth.register} component={RegisterPage} />
            <Route exact path={routes.aboutUs} component={AboutUsPage} />
            <Route
              exact
              path={routes.investorRelations}
              component={InvestorRelationsPage}
            />
            <Route exact path={routes.howToBook} component={HowToBookPage} />
            <Route exact path={routes.shop} component={ShopPage} />
            <Route exact path={routes.product()} component={ProductPage} />
            <Route exact path={routes.blog} component={BlogPage} />
            <Route exact path={routes.blogPost()} component={BlogPostPage} />
            <Route exact path={routes.rooms} component={RoomsPage} />
            <Route exact path={routes.contact} component={ContactPage} />
            <Route exact path={routes.percent} component={PercentPage} />
            <Route exact path={routes.premium} component={PremiumLandingPage} />
            <Route exact path={routes.promotions} component={PromotionsPage} />
            <Route
              exact
              path={routes.auth.forgotPassword}
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path={routes.privacyPolicy}
              component={PrivacyPolicyPage}
            />
            <Route
              exact
              path={routes.regulations}
              component={RegulationsPage}
            />

            <Route exact path={routes.search()} component={SearchPage} />
            <Route
              exact
              path={routes.description()}
              component={SearchDetailPage}
            />
            <Route
              exact
              path={routes.booking.process()}
              component={BookingPage}
            />
            <Route
              exact
              path={routes.booking.summary()}
              component={BookingComplete}
            />
            <ProtectedRoute
              exact
              path={routes.account}
              component={AccountPage}
            />
            <Route component={NotFound} />
          </Switch>
        </MainContent>

        <Footer />
        <CookieBanner />
      </Router>
    </ThemeProvider>
  );
};

export default withTranslation()(App);
