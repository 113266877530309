import React from 'react';
import { withTranslation } from 'react-i18next';
import { MainTitle } from 'styles/globalStyles';
import routes from 'routes';
import SearchResultsForm from 'components/SearchFilter/forms/SearchResultsForm';
import * as Styled from './style';

const SearchFilter = ({ query, t }) => {
  return (
    <>
      <MainTitle>{t('Search criteria')}</MainTitle>
      <Styled.SearchWrapper>
        <SearchResultsForm initialValues={query} />
        <Styled.SearchRight>
          <Styled.QuestionText to={routes.contact} textcolor="#919191">
            {t('Want to book a room for long term?')}
          </Styled.QuestionText>
          <Styled.QuestionText to={routes.contact} textcolor="#000000">
            {t('Contact us')}
          </Styled.QuestionText>
        </Styled.SearchRight>
      </Styled.SearchWrapper>
    </>
  );
};

export default withTranslation()(SearchFilter);
