import { action, extendObservable } from 'mobx';
import { FORM_ERROR } from 'final-form';

import {
  API,
  APIRoutes,
  setAuthenticationToken,
  clearAuthenticationToken,
} from 'api';
import { routingStore, userStore, bookingStore } from 'stores';
import routes from 'routes';
import i18n from 'i18n';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  authenticationToken: '',
  isAuthenticated: false,
};

export class AuthStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  signInWithSession = token => {
    this.authenticationToken = token;
    this.isAuthenticated = true;
    userStore.fetchProfile();
  };

  @action
  signIn = async ({ email, password }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(APIRoutes.auth.signIn, { email, password });
      setAuthenticationToken(token);
      this.authenticationToken = token;
      this.isAuthenticated = true;
      userStore.fetchProfile();
      routingStore.push(routes.home);
    } catch (e) {
      return {
        [FORM_ERROR]: i18n.t('errors:Invalid username or password'),
      };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  signInOnBooking = async ({ email, password }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(APIRoutes.auth.signIn, { email, password });
      setAuthenticationToken(token);
      this.authenticationToken = token;
      this.isAuthenticated = true;
      await userStore.fetchProfile();
      bookingStore.initBookingAfterSignIn();
    } catch (e) {
      return {
        [FORM_ERROR]: i18n.t('errors:Invalid username or password'),
      };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  // eslint-disable-next-line
  signUp = async formData => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(APIRoutes.auth.signUp, formData);

      this.isLoading = true;
      this.isAuthenticated = true;
      this.authenticationToken = token;
      setAuthenticationToken(token);
      userStore.fetchProfile();
      routingStore.push(routes.home);
    } catch (error) {
      if (Object.hasOwnProperty.call(error, 'errors')) {
        return error.errors;
      }
      return { [FORM_ERROR]: i18n.t('errors:Something went wrong') };
    } finally {
      this.isLoading = false;
    }
  };

  @action
  logout = async () => {
    this.authenticationToken = '';
    this.isAuthenticated = false;
    clearAuthenticationToken();
    userStore.reset();
    routingStore.push(routes.home);
    bookingStore.clearStoredData();
    bookingStore.reset();
  };

  @action
  resetPasswordSubmit = async ({ password, token }) => {
    this.isLoading = true;
    try {
      await API.post(APIRoutes.auth.passwordReset(token), {
        password,
      });
      toast.info(i18n.t('toastify:Your password has been changed.'));

      routingStore.push(routes.auth.signIn);
    } catch (error) {
      toast.error(error.message);
      if (Object.hasOwnProperty.call(error, 'errors')) {
        return error.errors;
      }
      return { [FORM_ERROR]: error.message };
    } finally {
      this.isLoading = false;
    }
    return null;
  };

  @action
  onForgotPassword = async ({ email }) => {
    this.isLoading = true;
    try {
      await API.post(APIRoutes.auth.passwordReset(), { email });
      toast.info(
        i18n.t('toastify:We sent you a password reset link. Check your email!'),
      );
      routingStore.push(routes.home);
    } catch (error) {
      if (Object.hasOwnProperty.call(error, 'errors')) {
        return error.errors;
      }
      return { [FORM_ERROR]: i18n.t('errors:Invalid email address') };
    } finally {
      this.isLoading = false;
    }
    return null;
  };
}

export default new AuthStore();
