import { extendObservable, action } from 'mobx';
import { API, APIRoutes } from 'api';
// import { toast } from 'react-toastify';
// import i18n from 'i18n';
// import { authStore } from 'stores';

const initialState = {
  isLoading: false,
  productIsLoading: false,
  initialized: false,
  productHasBeenFetched: false,
  error: null,
  meta: {},
  products: [],
  product: {},
};

export class ShopStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  resetProduct = () => {
    this.product = {};
    this.productHasBeenFetched = false;
  };

  @action
  fetchProduct = async slug => {
    this.productIsLoading = true;
    try {
      const {
        data: { data },
      } = await API.get(APIRoutes.product(slug));
      this.product = data;
    } catch (error) {
      // eslint-disable-next-line
      console.debug(error);
    } finally {
      this.productIsLoading = false;
      this.productHasBeenFetched = true;
    }
  };

  @action
  fetchProducts = async () => {
    this.isLoading = true;
    try {
      const {
        data: { data, links, totalCount },
      } = await API.get(APIRoutes.products);
      this.products = data;
      this.meta = { ...links, totalCount };
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.isLoading = false;
      this.initialized = true;
    }
  };

  @action
  reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };
}

export default new ShopStore();
