import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Heading, Flex } from 'rebass';
import styled from '@emotion/styled';
import { withTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import TextInput from 'components/forms/TextInput';
import MainButton from 'components/MainButton';
import FormSelect from 'components/forms/FormSelect';
import { COUNTRY_CODES } from 'utils/staticValues';
import i18n from 'i18n';
import {
  required,
  composeValidators,
  isValidNip,
  mustBeValidEmail,
  isValidZipCode,
  // isNumber,
} from 'utils/formValidators';
import Checkbox from 'components/Checkbox';
import RadioButton from 'components/RadioButton';
import { DiscountInfo } from '../style';

const RadioButtonGroup = styled(Flex)`
  flex-wrap: 'wrap';

  margin-bottom: 13px;
  & > div {
    margin-right: 10px;
  }
`;

const BookingFormUserData = ({ isAuthenticated, onSubmit, t, userData }) => {
  const codes = useMemo(() => COUNTRY_CODES(), [i18n.language]);
  const COUNTRY_SELECT_OPTIONS = Object.keys(codes).map(key => ({
    value: key,
    label: codes[key],
  }));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...userData,
        billingAddress: {
          type: 'private',
          businessType: 'self_employed',
          country: 'PL',
          ...userData.billingAddress,
        },
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Heading fontSize="22px" fontWeight="medium" mb={3}>
            {isAuthenticated ? t('Customer data') : t('Book as guest user')}
          </Heading>

          {!isAuthenticated && <DiscountInfo>{t('Remember')}</DiscountInfo>}
          <RadioButtonGroup>
            <Field
              label={t('typePrivate')}
              name="billingAddress.type"
              component={RadioButton}
              type="radio"
              value="private"
            />
            <Field
              label={t('typeCompany')}
              name="billingAddress.type"
              component={RadioButton}
              type="radio"
              value="company"
              // validateFields={[
              //   'billingAddress.street',
              //   'billingAddress.zipCode',
              //   'billingAddress.city',
              // ]}
            />
          </RadioButtonGroup>
          <Field
            label={t('firstName')}
            name="billingAddress.firstName"
            type="text"
            component={TextInput}
            validate={composeValidators(required)}
          />
          <Field
            label={t('lastName')}
            name="billingAddress.lastName"
            type="text"
            component={TextInput}
            validate={composeValidators(required)}
          />
          {values.billingAddress.type === 'company' && (
            <>
              <RadioButtonGroup
                style={{
                  flexWrap: 'wrap',
                }}
              >
                <Field
                  label={t('selfEmployed')}
                  name="billingAddress.businessType"
                  component={RadioButton}
                  type="radio"
                  value="self_employed"
                />
                <Field
                  label={t('otherBusiness')}
                  name="billingAddress.businessType"
                  component={RadioButton}
                  type="radio"
                  value="other_business"
                />
              </RadioButtonGroup>
              <Field
                label={t('Company name')}
                name="billingAddress.companyName"
                type="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
              <Field
                label={t('VAT Number')}
                name="billingAddress.vatNumber"
                type="text"
                component={TextInput}
                validate={composeValidators(required, isValidNip)}
              />
            </>
          )}

          <Field
            label={t('Phone')}
            name="phone"
            type="phone"
            component={TextInput}
          />

          <Field
            label={t('E-mail')}
            name="email"
            type="email"
            component={TextInput}
            validate={composeValidators(required, mustBeValidEmail)}
          />
          <Field
            name="billingAddress.country"
            placeholder={t('Country')}
            label={t('Country')}
            size="big"
            border="secondary"
            countrySelect
            component={FormSelect}
            validate={required}
            options={COUNTRY_SELECT_OPTIONS}
          />
          <Field
            label={t('Street')}
            name="billingAddress.street"
            type="text"
            component={TextInput}
            validate={required}
          />
          <Field
            label={t('StreetNumber')}
            name="billingAddress.streetNumber"
            type="text"
            component={TextInput}
            validate={composeValidators(required)}
          />
          <Field
            label={t('FlatNumber')}
            name="billingAddress.flatNumber"
            type="text"
            component={TextInput}
          />
          <Field
            label={t('ZIP Code')}
            name="billingAddress.zipCode"
            type="text"
            component={TextInput}
            validate={composeValidators(required, isValidZipCode)}
          />
          <Field
            label={t('City')}
            name="billingAddress.city"
            type="text"
            component={TextInput}
            validate={required}
          />
          <Field
            label={
              <Trans i18nKey="forms:termsAndConditions">
                Creating an account means you’re okay with our{' '}
                <RouterLink to="/regulations">Terms of Service</RouterLink>
                and <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
                .
              </Trans>
            }
            name="terms"
            type="checkbox"
            component={Checkbox}
            validate={required}
            sx={{ minHeight: '52px' }}
          />

          <MainButton
            mt="10px"
            width="100%"
            onClick={handleSubmit}
            type="submit"
          >
            {t('Next')}
          </MainButton>
        </form>
      )}
    />
  );
};

BookingFormUserData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withTranslation('booking')(BookingFormUserData);
