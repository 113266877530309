import React, { Component } from 'react';
import { Text } from 'rebass';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { withTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';

import { Container, MainTitle } from 'styles/globalStyles';
import routes from 'routes';
// import { LineSeparator, Quote, QI } from './style';
import { List, ListItem, Plan, Price, Plans } from './style';

const PremiumPageWrapper = styled(Container)`
  ol {
    list-style: decimal;
    padding-left: 30px;
  }
  a {
    color: #575757;
    text-decoration: none;
  }
`;

const Premium = styled.span`
  font-weight: 700;
  color: #f4b78a;
`;

@inject('userStore', 'routingStore')
@observer
class PremiumLandingPage extends Component {
  render() {
    const {
      t,
      userStore: { profile },
    } = this.props;

    return (
      <PremiumPageWrapper>
        <MainTitle>{t('Become PREMIUM user')}</MainTitle>

        <Text
          as="p"
          fontSize={[3, 3, 3, 3, 4]}
          lineHeight={['23px', '23px', '23px', '23px', '30px']}
          color="muted"
        >
          {t('row-1.p')}
        </Text>
        <List>
          <ListItem>{t('list-item.1')}</ListItem>
          <ListItem>{t('list-item.2')}</ListItem>
          <ListItem>{t('list-item.3')}</ListItem>
        </List>

        <Text as="p" fontSize={1} color="muted" mt={10} mb={30}>
          {t('asterix-info')}
        </Text>

        {profile.isPremium ? (
          <Trans
            i18nKey="premiumLanding:already-premium"
            availableTo={moment(profile.premiumTo).format('DD.MM.YYYY hh:mm')}
          >
            You already have a <Premium>PREMIUM</Premium> account (valid until{' '}
            {{
              availableTo: moment(profile.premiumTo).format('DD.MM.YYYY hh:mm'),
            }}
            )
          </Trans>
        ) : (
          <>
            <Text as="h2" fontSize={5} color="muted" mt={30}>
              {t('plans.header')}
            </Text>
            <Plans>
              <Plan
                to={{
                  pathname: routes.account,
                  state: { selectedPlan: 'short' },
                }}
              >
                <Price as="h2" fontSize={[5, 5, 5, 5, 9]} color="gold" mb={10}>
                  39zł
                </Price>
                <Text as="p" fontSize={3} color="muted">
                  1 {t('month')}
                </Text>
              </Plan>
              <Plan
                to={{
                  pathname: routes.account,
                  state: { selectedPlan: 'long' },
                }}
              >
                <Price as="h2" fontSize={[5, 5, 5, 5, 9]} color="gold" mb={10}>
                  99zł
                </Price>
                <Text as="p" fontSize={3} color="muted">
                  3 {t('months')}
                </Text>
              </Plan>
            </Plans>
          </>
        )}
      </PremiumPageWrapper>
    );
  }
}

export default withTranslation('premiumLanding')(PremiumLandingPage);
