import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import uniqueId from 'lodash/uniqueId';
import { breakpoint } from 'styles/breakpoints';

import FormError from 'components/forms/FormError';
import Label from 'components/forms/FormLabel';

export const InputText = styled.input`
  display: block;
  height: 52px;
  background-color: #fafafa;
  color: ${props => (props.error ? '#F45A5A' : '#000')};
  padding: 20px 11px;
  outline: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  border-radius: 3px;
  width: 100%;
`;

export const MsgInputArea = styled.textarea`
  height: 136px;
  display: block;
  background-color: #fafafa;
  color: ${props => (props.error ? '#F45A5A' : '#5b5b5b')};
  padding: 20px 11px;
  outline: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  border-radius: 3px;
  width: 100%;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  resize: none;
  @media ${breakpoint.md} {
    height: 378px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 13px;
  @media ${breakpoint.md} {
    width: ${props => props.fullWidth};
    padding-left: ${props => props.pl};
  }
`;

export const TextInput = ({
  input,
  meta,
  label,
  fullWidth,
  height,
  pl,
  displayErrorBelow,
  ...props
}) => {
  const fieldId = uniqueId(`field-${input.name}_${input.type}-`);
  const withError =
    meta.modified &&
    (meta.touched || meta.submitFailed) &&
    (meta.error || meta.submitError);

  return (
    <Wrapper fullWidth={fullWidth} pl={pl}>
      {label && (
        <Label error={withError} htmlFor={fieldId}>
          {label}
          {!displayErrorBelow && <FormError meta={meta} />}
        </Label>
      )}
      {input.type === 'textarea' ? (
        <MsgInputArea error={withError} id={fieldId} {...props} {...input} />
      ) : (
        <InputText error={withError} id={fieldId} {...props} {...input} />
      )}

      {(!label || displayErrorBelow) && (
        <FormError
          style={
            displayErrorBelow
              ? { justifyContent: 'right', marginTop: '2px' }
              : {}
          }
          meta={meta}
        />
      )}
    </Wrapper>
  );
};

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  displayErrorBelow: PropTypes.bool,
  fullWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  fullWidth: '100%',
  label: '',
  displayErrorBelow: false,
};

export default TextInput;
