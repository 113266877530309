import React, { memo, useMemo, useState, useEffect } from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { size as breakpoint } from 'styles/breakpoints';
import {
  required,
  composeValidators,
  isValidNip,
  isValidZipCode,
  // isNumber,
} from 'utils/formValidators';
import TextInput from 'components/forms/TextInput';
import RadioButton from 'components/RadioButton';
import FormSelect from 'components/forms/FormSelect';
import { COUNTRY_CODES } from 'utils/staticValues';
import i18n from 'i18n';
import { FormRow, MultiRow, RadioButtonGroup, CompanyType } from '../style';

const AddressForm = ({ t, showTypeSelector, accountType }) => {
  const codes = useMemo(() => COUNTRY_CODES(), [i18n.language]);
  const COUNTRY_SELECT_OPTIONS = Object.keys(codes).map(key => ({
    value: key.toLowerCase(),
    label: codes[key],
  }));

  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint.md);

  let resizeDebouncer;
  const windowResizeHandler = () => {
    clearTimeout(resizeDebouncer);
    resizeDebouncer = setTimeout(() => {
      setIsMobile(window.innerWidth < breakpoint.md);
    }, 300);
  };

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, []);

  return (
    <>
      {showTypeSelector && (
        <RadioButtonGroup>
          <Field
            label={t('booking:typePrivate')}
            name="billingAddress.type"
            component={RadioButton}
            type="radio"
            value="private"
          />
          <Field
            label={t('booking:typeCompany')}
            name="billingAddress.type"
            component={RadioButton}
            type="radio"
            value="company"
            validateFields={[
              'billingAddress.street',
              'billingAddress.zipCode',
              'billingAddress.city',
            ]}
          />
        </RadioButtonGroup>
      )}
      <FormRow multi>
        <>
          <Field
            label={t('First name')}
            name="billingAddress.firstName"
            type="text"
            component={TextInput}
            validate={composeValidators(required)}
          />
          <Field
            label={t('Last name')}
            name="billingAddress.lastName"
            type="text"
            component={TextInput}
            validate={composeValidators(required)}
          />
        </>
      </FormRow>
      {accountType === 'company' && (
        <>
          <MultiRow multi style={{ alignItems: 'center' }}>
            <CompanyType>
              <Field
                label={t('selfEmployed')}
                name="billingAddress.businessType"
                component={RadioButton}
                type="radio"
                value="self_employed"
                fullWidth="auto"
              />
              <Field
                label={t('otherBusiness')}
                name="billingAddress.businessType"
                component={RadioButton}
                type="radio"
                value="other_business"
                fullWidth="auto"
              />
            </CompanyType>
            {!isMobile && (
              <Flex style={{ flexGrow: 1, gap: '14px' }}>
                <Field
                  label={t('Company name')}
                  name="billingAddress.companyName"
                  type="text"
                  component={TextInput}
                  validate={composeValidators(required)}
                  fullWidth="100%"
                />

                <Field
                  label={t('VAT Number')}
                  name="billingAddress.vatNumber"
                  type="text"
                  component={TextInput}
                  validate={composeValidators(required, isValidNip)}
                  fullWidth="100%"
                />
              </Flex>
            )}
          </MultiRow>
          {isMobile && (
            <>
              <Field
                label={t('Company name')}
                name="billingAddress.companyName"
                type="text"
                component={TextInput}
                validate={composeValidators(required)}
              />

              <Field
                label={t('VAT Number')}
                name="billingAddress.vatNumber"
                type="text"
                component={TextInput}
                validate={composeValidators(required, isValidNip)}
              />
            </>
          )}
        </>
      )}
      <FormRow
        multi
        style={{ width: !isMobile ? '49%' : '100%', marginBottom: '20px' }}
      >
        <Field
          name="billingAddress.country"
          placeholder={t('Country')}
          size="big"
          border="secondary"
          countrySelect
          fullWidth
          component={FormSelect}
          validate={required}
          options={COUNTRY_SELECT_OPTIONS}
        />
      </FormRow>

      <MultiRow>
        <Field
          label={t('Street')}
          name="billingAddress.street"
          type="text"
          fullWidth="auto"
          component={TextInput}
          validate={composeValidators(required)}
        />
        <Field
          label={t('StreetNumber')}
          name="billingAddress.streetNumber"
          type="text"
          component={TextInput}
          fullWidth="auto"
          validate={composeValidators(required)}
        />
        <Field
          label={t('FlatNumber')}
          name="billingAddress.flatNumber"
          type="text"
          fullWidth="auto"
          component={TextInput}
        />
        <Field
          label={t('ZIP Code')}
          name="billingAddress.zipCode"
          fullWidth="auto"
          type="text"
          component={TextInput}
          validate={composeValidators(required, isValidZipCode)}
        />
        <Field
          label={t('City')}
          name="billingAddress.city"
          fullWidth="auto"
          type="text"
          component={TextInput}
          validate={composeValidators(required)}
        />
      </MultiRow>
    </>
  );
};

AddressForm.defaultProps = {
  showTypeSelector: false,
  accountType: 'private',
};

AddressForm.propTypes = {
  t: PropTypes.func.isRequired,
  showTypeSelector: PropTypes.bool,
  accountType: (props, propName, componentName) => {
    const validValues = ['private', 'company'];
    // eslint-disable-next-line
    if (props.showTypeSelector && !validValues.includes(props[propName])) {
      return new Error(
        `${componentName}: Prop ${propName} has incorrect value: ${
          props[propName]
        }. Expected one of ${validValues.join(', ')}`,
      );
    }
    return null;
  },
};

export default withTranslation('account')(memo(AddressForm));
