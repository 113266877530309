/* eslint-disable max-len */
export default {
  shop: {
    title: 'Магазин танців',
    'no products': 'Вибачте, продуктів немає',
  },
  footer: {
    termsAndConditions: 'Загальні положення',
    privacyPolicy: 'Політика конфіденційності',
    Contact: 'Контакт',
    'Investor Relations': 'Відносини з інвесторами',
  },
  account: {
    'Account Settings': 'Профіль користувача',
    'My bookings': 'Мої резервування',
    'Personal information': 'Персональні дані',
    'Billing information': 'Платіжні дані',
    expired: 'Термін дії закінчується:',
    Phone: 'Номер телефону',
    Email: 'Адреса e-mail',
    Name: 'Ім`я та Прізвище / Прізвисько',
    'Billing Name': 'Ім`я та Прізвище / Назва компанії',
    'VAT Number': 'Номер NIP (ІНПП)',
    Street: 'Вулиця',
    Country: 'Kraj',
    StreetNumber: 'Street number',
    FlatNumber: 'Numer mieszkania',
    'ZIP Code': 'Поштовий індекс',
    City: 'Місто',
    Save: 'Зберегти',
    'booking.complete': 'ЗАВЕРШЕНО',
    'booking.modify': 'ЗМІНИТИ',
    'booking.cancelRes': 'СКАСУВАТИ РЕЗЕРВУВАННЯ',
    'booking.cancel': 'СКАСУВАТИ',
    'booking.cancelled': 'CANCELLED',
    'Cancel room booking': 'Скасування резервування зали - {{ name }}',
    'Are you sure you want to cancel this reservation? A fee eqaual to 6 zł per booking hour will be charged.':
      'Ви впевнені, що хочете скасувати резервування? Буде стягнута плата в розмірі 6 злотих.',
    "You can change your booking's hours without extra costs. Choose one of listed options": `Ви можете безкоштовно змінити час резервування, вибравши один зі списку доступних варантів.
    `,
    Change: 'Змінити',
    'You have no bookings yet.': 'Ви ще не маєте жодних резервувань',
    DeleteInfo:
      'Видаливши свій обліковий запис, ви не матимете доступу до своїх резервувань.',
    'Delete account': 'Видалити обліковий запис',
    'Are you sure you want to delete your account?':
      'Ви впевнені, що хочете видалити свій обліковий запис?',
    Cancel: 'Відмінити',
    Delete: 'Видалити',
    Premium: 'Преміум-рахунок',
    'Your PREMIUM account is active!': 'Ваш рахунок PREMIUM активний!',
    'package is valid to': 'пакет діє до ',
    'Buy premium!': 'Купуйте преміум!',
    "Don't know the benefits of a premium account? Get to know them now":
      'Не знаєте переваг преміум-рахунку? Познайомтеся з ними вже зараз',
    'Additional promotions': 'Додаткові акції',
    "You don't have any additional promotions": 'У вас немає додаткових акцій',
    'Check additional promotions': 'Перевірте додаткові акції',
    'Check more additional promotions': 'Перевірте додаткові акції',
    'buy.premium.modal.description':
      'Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna. Curabitur blandit tempus porttitor.',
    Buy: 'Купуйте',
    'Buy PREMIUM account': 'Придбайте рахунок PREMIUM',
    "You've successfully bought premium account":
      'Ви успішно придбали преміум-акаунт',
    "You've successfully bought promotion":
      'Ви успішно придбали рекламну акцію',
    'My orders': 'Мої замовлення',
    selfEmployed: 'Self employed',
    otherBusiness: 'Other business type',
  },
  buttons: {
    Search: 'Пошук',
    'Open filters': 'Відкрити фільтри',
    'Reset password': 'Скинути пароль',
    Book: 'Зарезервуй',
    'Free room': 'Вільні зали',
    'Selected room': 'Вибрана зала',
    'Back to the homepage': 'Повернутися на головну сторінку',
    Send: 'Надіслати',
  },
  countries: {
    pl: 'Польща',
    de: 'Німеччина',
    fr: 'Франція',
  },
  discount: {
    facebook:
      '<b>Отримайте 5 злотих!</b> Зайдіть на наш фанпейдж (funpage) та поділіться ним на своїй стіні (wallu), і ви отримаєте ЗНИЖКУ!',
  },
  common: {
    'Rent a dance hall': 'Замовити танцювальний зал',
    From: 'Від',
    To: 'До',
    'Current settings': 'На даний момент вибрано',
    person: '{{count}} людина',
    person_plural: '{{count}} люди',
    person_multi: '{{count}} люди',
    'Search criteria': 'Критерії пошуку',
    'Want to book a room for long term?': 'Хочете зарезервувати кілька днів?',
    'Contact us': `Зв'яжіться з нами`,
    'Similar rooms': 'Доступні зали на дату поряд',
    'No rooms found! Please search for a different time or date, or contact us': `Не знайдено залів! Змініть час або дату або зв'яжіться з нами`,
    Price: 'Ціна',
    max_people: 'Максимум осіб: ',
    available: 'Доступна: ',
    personAndMore: '10 осіб і більше',
    'Not found': 'Не знайдено',
    starInfo:
      '*Додаткова плата в розмірі 500 злотих буде стягуватися в разі введення орендодавця залу в оману щодо кількості осіб.',
    termsAndConditionsFooter:
      '<0>Загальні положення</0> | <2>Політика конфіденційності</2>',
    short: '1 month - 39PLN',
    long: '3 months - 99PLN',
  },
  dropdowns: {
    'Choose location': 'Виберіть розташування',
    'Choose plan': 'Вибірський план',
    'Number of people': 'Кількість осіб',
    'Select day and hours': 'Виберіть день та годину',
    'Premium duration': 'Premium account duration',
    City: 'Місто',
  },
  home: {
    "Can't find room in your city?": 'Ви не знайшли зал у своєму місті?',
  },
  navbar: {
    'About us': 'Про нас',
    Blog: 'Новини',
    Contact: 'Контактна інформація',
    'Sign up': 'Створити обліковий запис',
    'Sign in': 'Вхід в систему',
    Hello: 'Привіт',
    'Account Settings': 'Мій обліковий запис',
    'Investor Relations': 'Відносини з інвесторами',
    Logout: 'Вихід із системи',
    Premium: 'Преміум-рахунок',
    'Our locations': 'Наші місця розташування',
    Shop: 'танцювальний магазин',
    'How to book': 'як забронювати',
  },
  titles: {
    Login: 'Вхід в систему',
    Register: 'Реєстрація нового користувача',
    Contact: 'Контактна інформація',
  },
  forms: {
    'Already a member?': 'У вас уже є обліковий запис?',
    termsAndConditions:
      'Заявляю, що я ознайомився з <2>Загальними положеннями</2> i <4>Політикою конфіденційності</4>',
    Email: 'Email',
    Password: 'Пароль',
    'Password confirmation': 'Підтвердження паролю',
    "Passwords don't match": 'Паролі не збігаються',
    'Forgot password?': 'Забули пароль?',
    Login: 'Вхід в систему',
    Name: `Ім'я та Прізвище / Назва`,
    'Sign in now': 'Вхід в систему',
    'Sign up now': 'Зареєструйтеся зараз',
    'Not a member?': 'У вас ще немає облікового запису? ',
    Register: 'Створіть обліковий запис',
    forgotPasswordInstruction:
      'Введіть адресу e-mail, яку ви використовували при реєстрації, і ми надішлемо вам інструкції щодо скидання пароля',
    resetPasswordInstruction:
      'Введіть новий пароль для свого облікового запису',
    contactForm: `У вас є питання? Зв'яжіться з нами:`,
    Phone: 'Номер телефону',
    Subject: 'Тема',
    Message: 'Повідомлення',
    City: 'Місто',
    callUs: 'Прямий контакт:',
    "I can't find room in my city": 'Я не знайшов зал у своєму місті',
    'Reset password': 'Скинути пароль',
  },
  errors: {
    'Invalid username or password': `Неправильне ім'я користувача або пароль`,
    'Something went wrong': 'Щось пішло не так.',
    'Invalid email address': 'Невірна адреса email',
    'Invalid street number': 'Invalid street number',
    'This field is required': `Це поле обов'язкове`,
    'Minimum duration is 1h': 'Мінімальний час резервування становить 1 годину',
    'Start time must be before end time':
      'Початковий час має бути раніше кінцевого часу',
    'Incorrect booking time': 'Неправильний час бронювання',
    invalid_zip_code: 'Недійсний поштовий індекс',
  },
  aboutUs: {
    'About us': 'Про нас',
    'row-1.p1': `Резервуй танцювальні зали в Польщі онлайн`,
    'row-1.p2': `
    <ol>
      <li>Резервація без обслуговування – Резервуєш -> Платиш  -> Використовуєш </li>
      <li>Миттєва інформація про доступність залів 24/7</li>
      <li>Можливість відміни резервації з випередженням в принаймні 24 години (після внесення оплати згідно з нашими Правилами) 	 </li>
      <li>Ти обираєш зал та місце</li>
      <li>Оплата онлайн</li>
      <li>Рахунок-фактура автоматично надсилається на електронну пошту одразу після оплати резервації.</li>
    </ol>`,
    'row-2.p1': `Знижка за реєстрацію`,
    'row-2.p2': `Революція для інструкторів`,
    'row-3.p1': `Для зареєстрованих користувачів ми підготували знижку, яка буде активна протягом 7 днів.<br/>
      Якщо Ви поширите публікацію на своїй сторінці Facebook, усі тарифи за годину зменшаться на 5 злотих.<br/>
      Знижку можна відновлювати. Кожне наступне поширення подовжує знижку на тиждень.<br/>
      Як активувати знижку:<br/>
      Увійдіть у свій акаунт<br/>
      Угорі сторінки з’явиться блакитна панель з інформацією про знижки<br/>
      Клікніть на цій панелі, вона перенаправить Вас на Facebook<br/>
      Натисніть "Поширити"<br/>
      Переконайтесь, що Ваша публікація є загальнодоступною<br/>
      Поверніться на saletaneczne.pl та почніть бронювання<br/>
      Ознайомтеся з нашими Правилами,  а якщо у Вас виникнуть питання – напишіть нам у <a href="https://www.facebook.com/saletanecznepl" target="_blank">Facebook</a>`,
    'row-3.p2': `Пара скасувала своє перше танцювальне заняття? Клієнт раптом "захворів"?
      Тепер Ви можете запропонувати своїм клієнтам забронювати зал через нашу систему.
      Саме вони будуть відповідальні за вибір зала, оплату, та слідкування за резервацією.`,
    'row-4.quote': `Успіх полягає не в тому, щоб досягти чогось у майбутньому, а в тому, щоб робити те, що тобі
       подобається зараз`,
    'row-5.p1': `Місія нашої організації – створити професійний простір, призначений для танця. Зали призначені як для професіоналів, так і любителей.<br/><br/>
    Головні переваги співпраці в такому форматі – це доступ до приміщень різного розміру, довільні години занять та швидке здійснення резервації, без очікування на відповідь школи.<br/>
    На практиці, ця система ідеально підходить для роботи інструктора, котрий потребує негайної інформації для клієнта чи зайняття може відбутися у визначений час.<br/><br/>
    Це прекрасне розв'язання для танцюриста, котрий шукає місце для тренувань у зручний для себе час. Тут Ви знайдете зал доступний зранку, після обіду, у вихідні та навіть в Новий Рік. Коротко кажучи: зали доступні ЗАВЖДИ!<br/>
    Щомісяця до нас долучаються все більше й більше танцювальних шкіл. Вам немає необхідності задаватися питанням: як заповнити зал після прайм-тайму, це – наша проблема. Ви робите те, що любите та працюєте з учнями коли вам зручно. При необхідності, ви можете орендувати кілька залів одночасно о тій самій годині. Немає жодних проблем!😉<br/><br/>
    На нашому рахунку вже понад 200 000 зарезервованих годин! У нашому розпорядженні 40 зал розташованих у 5 різних комплексах у 3 різних містах і ми продовжуємо рости та розвиватися! 😉`,
  },
  investorRelations: {
    title: 'Відносини з інвесторами',
  },
  premiumLanding: {
    'Become PREMIUM user': 'Станьте ПРЕМІУМ-користувачем',
    'row-1.p': `Переваги преміум-облікового запису:`,
    'list-item.1': 'Нижчі ціни на 5 злотих/1год *',
    'list-item.2': 'Можливість скасування бронювання за 12 годин до бронювання',
    'list-item.3':
      'Доступ до додаткових пакетів, що знижують ціни ще на 10 злотих/1 год',
    'Get premium account': 'Я хочу стати преміум-користувачем',
    'plans.header': 'Доступні плани',
    month: 'місяць',
    months: 'місяців',
    'asterix-info':
      '*від цін без реєстрації аккаунта. Наприклад, Варшава, 3 людини, 10-11 година, 25 злотих - 5 злотих = 20 злотих / 1 год',
    'already-premium':
      'У вас є активний <1>ПРЕМІУМ</1> (дійсний до {{availableTo}})',
  },
  promotions: {
    'row-1.p': `Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Curabitur blandit tempus porttitor.
                Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur est at lobortis. Donec ullamcorper nulla non metus auctor fringilla. Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo.`,
    'Buy more discounts': 'Купуйте більше знижок',
    "There's no special offers": 'Спеціальних пропозицій немає',
    Discount: 'Знижка',
    Availability: 'Доступність',
    'Buy now': 'Купити зараз',
    Buy: 'Kup',
    days: 'днів',
    hour: '{{count}} hour',
    hour_plural: '{{count}} hours',
    minutes: 'minutes',
    Price: 'Ціна',
    Limit: 'Daily limit',
    Description: 'Опис',
    'Buy discount': 'Придбайте знижку',
    'buy.promotion.modal.description':
      'Щоб придбати додатковий ПАКЕТ, введіть дані платника та натисніть кнопку КУПИТИ',
    Duration: 'Duration',
    'Select duration': 'Виберіть тривалість',
    'buy.promotion.modal.statement':
      'Я прошу вас почати надавати цифровий контент негайно (до закінчення 14-денного періоду відмови). Таким чином, я підтверджую, що з цього моменту я не можу відмовитися від договору.',
  },
  privacyPolicy: {
    'Privacy Policy': 'Політика конфіденційності saletaneczne.pl',
    content: `

    <h3>1. Загальна інформація</h3>
    <p>1. Ця політика стосується Веб-сайту www, що функціонує під url-адресою: https://saletaneczne.pl (‘Веб-сайт'). Усі формулювання, написані в цій Політиці великими літерами та в ній не визначені, повинні розумітись відповідно до визначень, що випливають із Регламенту Веб-сайту saletaneczne.pl.</p>
    <p>2. Оператором веб-сайту та адміністратором персональних даних користувачів веб-сайту є: Dance Rooms Europe Sp. z o. o. у Варшаві: Ал. Jerozolimskie 123a, 02-017 Warszawa, внесений до реєстру підприємців, який веде Окружний суд у Варшаві, 12-й комерційний відділ Національного судового реєстру під номером KRS 0001057815, REGON: 526386363, NIP: 7011164542 зі статутним капіталом 5000,00 злотих ( прописом: п'ять тисяч 00/100 злотих) («Оператор»).</p>
    <p>3. Контактна адреса електронної пошти Оператора: <a href="saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>
    <p>4. Оператор є адміністратором персональних даних Користувачів стосовно даних, що надаються добровільно на Веб-сайті.</p>
    <p>5. Веб-сайт використовує персональну інформацію в наступних цілях:</p>
    <ul>
      <li>Публікація Оголошень;</li>
      <li>Надання інформації власникам об’єктів щодо бронювання місць відпочинку;</li>
      <li>Відображення вибраного імені користувача на стіні сайту в місці бронювання Танцювального залу.</li>
    </ul>

    <p>6. Веб-сайт реалізує функції отримання інформації про користувачів та їх поведінку наступним чином:</p>
    <ol>
      <li>За допомогою добровільно введених у форми даних, які будуть внесені до систем Оператора.</li>
      <li>За допомогою збереження файлів cookie на кінцевих пристроях (так звані «тістечка»).</li>
    </ol>

    <h3>2. Правила обробки персональних даних Користувачів Веб-сайту Оператором</h3>
    <p>1. Адміністратором персональних даних Користувачів Веб-сайту є Оператор.</p>
    <p>2. Персональні дані будуть оброблятися Оператором для цілей, необхідних для надання послуг в рамках Веб-сайту та виконання договору між Оператором та Користувачем, включаючи - у випадку Власника об'єкта - надання на його користь Послуг Оператора (ст. 6 абз. 1, п. b. GDPR/RODO). Оператор також може використовувати персональні дані Користувача для цілей, що випливають із законних інтересів, що реалізуються адміністратором (ст. 6 абз.1 п. f. GDPR/RODO), зокрема, маркетингу послуг, що надаються Оператором, включаючи профілювання персональних даних для вищезазначених потреб, а також для цілей створення статистики, за умови, що Користувач має право в будь-який момент виголосити спротив для обробки його персональних даних для цілей, зазначених у цьому реченні.</p>
    <p>3. Для маркетингових цілей Оператор може також обробляти персональні дані на підставі окремо вираженої згоди Користувача, в обсязі, що випливає з такої згоди (ст. 6 абз.1 п. a. GDPR/RODO). Висловлення цієї згоди є добровільним і Користувач може відкликати її в будь-який момент, за винятком того, що відкликання згоди не вплине на законність обробки персональних даних на підставі згоди до її відкликання.</p>
    <p>4. Персональні дані Власника об’єкта будуть видимі на Веб-сайті для всіх людей, які переглядають Веб-сайт.</p>
    <p>5. Персональні дані Орендаря будуть надані Орендодавцю під час здійснення Бронювання. Дані будуть передані Орендодавцю як окремому адміністратору персональних даних. Прийняття Регламенту являє собою згоду на передачу Оператором персональних даних Орендаря Орендодавцю, як окремому адміністратору даних. Абз. 4 застосовується відповідно.</p>
    <p>6. З урахуванням абзацу 5 і 6 вище, персональні дані Користувачів можуть передаватися суб’єктам, які їх обробляють, на вимогу Оператора, зокрема субпідрядникам Оператора, що надають ІТ-послуги, технічному обслуговуванню Веб-сайту тощо.</p>
    <p>7. Персональні дані Користувачів, які здійснили Бронювання та Реєстрацію на Веб-сайті, будуть зберігатися Оператором протягом періоду використання Веб-сайту та 24 місяців з моменту останнього входу на Веб-сайт.</p>
    <p>8. Що стосується персональних даних, що обробляються для маркетингових цілей, Оператор оброблятиме їх до тих пір, поки не отримає можливого спротиву Користувача або відкликання згоди Користувачем.</p>
    <p>9. Оператор оброблятиме всі або деякі з наведених нижче персональних даних Користувача: ім'я та прізвище, фірма - підприємницька діяльність Користувача, адреса Користувача, ідентифікаційний податковий номер "NIP", електронний адрес, контактний телефонний номер.</p>
    <p>10. Крім інших прав, про які йде мова в цій статті Регламенту, Користувач має право вимагати від Оператора доступу до своїх персональних даних, до їх виправлення, видалення або обмеження обробки, а також право передачі даних і подачі скарги до контролюючого органу: Голови управління із захисту персональних даних, ul. Stawki 2, 00-193 Warszawa.</p>
    <p>11. Надання персональних даних є добровільним, при цьому воно є необхідним для цілей Реєстрації та використання функціональності розміщення Оголошень та здійснення Бронювання.</p>
    <p>12. З питань персональних даних можна звертатися по електронній пошті <a href="saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>

    <h3>3. Вибрані методи захисту даних, що застосовуються Оператором</h3>

    <p>1. Паролі Користувачів зберігаються в хешованому вигляді. Функція хешування є односторонньою, неможливо повернути її роботу, що тепер є сучасним стандартом в області зберігання паролів користувачів.</p>
    <p>2. З метою захисту даних, Оператор регулярно робить резервні копії.</p>

    <h3>4. Лог-журнали Адміністратора</h3>
    <p>1. Інформація про поведінку Користувачів на Веб-сайті може підлягати реєстрації. Ці дані використовуються для адміністрування Веб-сайту.</p>

    <h3>5. Важливі методи маркетингу</h3>
    <p>1. Оператор використовує статистичний аналіз трафіку на веб-сайті за допомогою Google Analytics, Google Tag Manager (Google Inc. зі штаб-квартирою в США). Оператор не передає персональні дані оператору цієї послуги, а лише анонімізовану інформацію. Послуга заснована на використанні файлів cookie на кінцевому пристрої користувача. В області інформації про користувацькі переваги, зібрані рекламною мережею Google, користувач може переглядати та редагувати інформацію, отриману з файлів cookie, використовуючи цей інструмент: <a href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a> </p>
    <p>2. Оператор використовує методи ремаркетингу, які дозволяють відповідати рекламним повідомленням поведінці користувача на сайті, що може створити ілюзію того, що персональні дані користувача використовуються для його відстеження, але на практиці не відбувається жодна передача персональних даних від Оператора до рекламних операторів. Технологічною умовою таких дій є включена підтримка файлів cookie.</p>
    <p>3. Оператор використовує піксель Фейсбуку (Pixel Facebook). Ця технологія призводить до того, що сервіс Facebook (Facebook Inc. зі штаб-квартирою в США) знає, що особа, зареєстрована в ньому, використовує Веб-сайт. У цьому випадку він базується на даних, щодо яких він є адміністратором, Оператор не надає від себе жодних додаткових персональних даних веб-сайту Facebook. Послуга заснована на використанні файлів cookie на кінцевому пристрої користувача.</p>
    <p>4. Оператор застосовує рішення, яке автоматизує роботу Веб-сайту стосовно користувачів, наприклад, яке може надіслати електронний лист користувачеві після відвідування певної підсторінки, за умови, що він погодився отримати комерційної кореспонденції від Оператора.</p>

    <h3>6. Інформація про файли cookie</h3>
    <p>1. Веб-сайт використовує файли cookies.</p>
    <p>2. Файли cookie (так звані "тістечка") - являють собою інформаційні дані, зокрема текстові файли, які зберігаються на кінцевому пристрої Користувача Веб-сайту та призначені для використання сторінок Веб-сайту. Файли cookie зазвичай містять назву веб-сайту, з якого вони походять, час їх зберігання на кінцевому пристрої та унікальний номер.</p>
    <p>3. Суб'єктом, що розміщує на кінцевому пристрої Користувача Веб-сайту файли cookies і отримує до них доступ, є Оператор Веб-сайту.</p>
    <p>4. Файли cookie використовуються для наступних цілей:</p>
    <ol>
      <li>ведення сеансу користувача Веб-сайту (після входу в систему), завдяки якому користувачеві не потрібно повторно вводити логін та пароль на кожній підсторінці Веб-сайту;</li>
      <li>досягнення цілей, викладених вище у розділі «Важливі методи маркетингу»;</li>
    </ol>

    <p>5. В рамках Веб-сайту використовуються два основних типи файлів cookies: "сесійні" (session cookies) та "постійні" (persistent cookies). "сесійні" файли cookie - це тимчасові файли, які зберігаються на кінцевому пристрої користувача до моменту виходу з системи, опущення веб-сайту або вимкнення програмного забезпечення (веб-браузера). "Постійні" файли cookie зберігаються на кінцевому пристрої користувача протягом часу, визначеного в параметрах файлу cookie, або до тих пір, поки користувач їх не усуне.</p>
    <p>6. Програмне забезпечення для перегляду веб-сайтів (веб-браузер) зазвичай за замовчуванням дозволяє зберігати файли cookie на кінцевому пристрої користувача. Кожен користувач Веб-сайту може змінити налаштування в цьому відношенні. Веб-браузер дозволяє видаляти файли cookie. Також можливе автоматичне блокування файлів cookie. Детальна інформація з цього приводу надається у довідці або документації веб-браузера.</p>
    <p>7. Обмеження щодо використання файлів cookie можуть вплинути на деякий функціонал, доступний на інтернет-сторінках Веб-са</p>
    <p>8. Файли cookie, розміщені на кінцевому пристрої користувача Веб-сайту, також можуть використовуватися суб’єктами, які співпрацюють з оператором Веб-сайту, зокрема, це стосується таких компаній: Google (Google Inc. зі штаб-квартирою в США), Facebook (Facebook Inc. зі штаб-квартирою в США), Twitter (Twitter Inc. зі штаб-квартирою в США).</p>

    <h3>7. Управління файлами cookie - як на практиці надати і відкликати свою згоду?</h3>
    <p>1. Якщо користувач не хоче отримувати файли cookie, він може змінити настройки браузера. Ми зазначаємо, що відключення файлів cookie, необхідних для процесів автентифікації, безпеки, збереження переваг користувачів, може ускладнити, а в крайньому випадку може завадити використанню веб-сайтів www.</p>
    <p>2. З метою управління налаштуваннями файлів cookie, виберіть веб-браузер, який ви використовуєте, зі списку нижче та дотримуйтесь інструкцій:</p>
    <ul>
      <li>Edge</li>
      <li>Internet Explorer</li>
      <li>Chrome</li>
      <li>Safari</li>
      <li>Firefox</li>
      <li>Opera</li>
    </ul>
    <p>Мобільні пристрої:</p>
    <ul>
      <li>Android</li>
      <li>Safari (iOS)</li>
    </ul>

    `,
  },
  regulations: {
    Regulations: 'ПРАВИЛА ПОРТАЛУ saletaneczne.pl',
    RegulationsIndividual:
      'Правила порталу saletaneczne.pl для Індивідуальних Клієнтів',
    RegulationsCompany: 'Правила сервісу saletaneczne.pl для Бізнес Клієнтів',
    content: `

    <h3>1. Предмет Правил </h3>
    <p>Правила визначають правила про реєстрацію суб’єктів, зацікавлених в оренді залів на Порталі "saletaneczne.pl", а також правила пов'язані з орендою залів, їх бронюванням та здійсненням платежів за оренду.</p>

    <h3>2. Визначення</h3>
    <p>Для потреб роз'яснення Правил користування приймаються наступні визначення::</p>

    <dl>
      <dt>Постачальник Платежів</dt>
      <dd>це суб'єкт господарювання, який надає на основі відповідних правових норм та повноважень платіжні послуги, що дозволяють здійснювати Оплату, в тому числі портал www.przelewy24.pl;</dd>

      <dt>Орендар</dt>
      <dd>означає підприємця або іншу юридичну особу, яка має право до юридичної діяльності, яка зацікавлена в оренді Приміщень;</dd>

      <dt>Оголошення</dt>
      <dd>це запрошення для укладення Договору Оренди у значенні ст. 71 Цивільного кодексу, розміщене на Порталі Оператора на замовлення Орендодавця, що визначають основні умови договору оренди, зокрема, визначення Поверхні, можливої дати оренди, винагороди (орендної плати) за оренду Приміщень, правила щодо можливості розірвання Договору Оренди та, можливо, додаткові умови використання Приміщень, зокрема, щодо послуг, що надаються в рамках Договору Оренди;</dd>

      <dt>Оператор</dt>
      <dd>відноситься до суб'єкта, який володіє правами на веб-сайт, тобто наступної компанії: Dance Rooms Europe Sp. z o.o з Варшави: Al. Jerozolimskie 123a, 02-017 Warszawa, внесений до Центрального реєстру підприємницької діяльності та інформаційного запису, що ведеться Окружним судом у Варшаві, 12-й комерційний відділ Національного судового реєстру, за номером KRS 0001057815, REGON: 526386363, NIP. статутний капітал 5 000,00 zł (прописом: п'ять тисяч і 00/100 злотих), Електронна пошта: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>;</dd>

      <dt>Оплата</dt>
      <dd>означає загальну суму, належну за Договором Оренди від Орендаря для Орендодавця, що включає, зокрема, винагороду Орендодавця (орендна плата) за надання на користь Орендаря Приміщень в період дії Договору Оренди;</dd>

      <dt>Приміщення</dt>
      <dd>це приміщення танцювальних залів, представлені Орендодавцями на Порталі, які є предметом Договору Оренди у разі його укладення;</dd>

      <dt>Вирахування</dt>
      <dd>означає суму, яка буде стягнута з поверненої Оплати за Бронювання. Поточна сума вирахування доступна в додатку № 1 цих Правил.</dd>

      <dt>Правила</dt>
      <dd>означає ці правила;</dd>

      <dt>Реєстрація</dt>
      <dd>означає процес створення Користувачем Преміум-Облікового запису на Порталі, що використовуються для його ідентифікації на Порталі; </dd>

      <dt>Бронювання</dt>
      <dd>надання Орендарем заяви за посередництвом Порталу щодо бажання орендувати Приміщення протягом певного періоду часу і за певну суму. Здійснення бронювання означає прийняття основних умов Договору Оренди, в інших сферах до Договору Оренди застосовуються положення Цивільного кодексу та / або інших законів;</dd>

      <dt>ROGO (GDPR)</dt>
      <dd>означає Регламент Європейського Парламенту та Ради (ЄС) 2016/679 від 27 квітня 2016 року про захист фізичних осіб у зв'язку з обробкою персональних даних та про вільне переміщення таких даних, а також про скасування директиви 95/46/WE;</dd>

      <dt>Портал</dt>
      <dd>означає веб-сайт за адресою <a href="https://www.saletaneczne.pl">www.saletaneczne.pl</a>, включаючи її підсторінки, які дозволяють Орендодавцям представляти та ознайомлювати потенційних Орендарів з інформацією про наявність Приміщень та умовами їх оренди, а також можливістю бронювання Приміщень Орендарем;</dd>

      <dt>Договір Оренди</dt>
      <dd>це договір оренди Приміщення, укладений між Орендарем та Орендодавцем в результаті здійснення Бронювання Орендарем на Порталі;</dd>

      <dt>Послуга Оператора</dt>
      <dd>означає послугу, що надається Оператором на користь Орендодавця, що полягає в посередництві на користь Орендодавця в укладенні Договору Оренди; в рамках реалізації Послуги Оператора, Оператор виконує на користь Орендодавця дії, спрямовані на укладення Договору Оренди;</dd>

      <dt>Користувач</dt>
      <dd>означає всіх користувачів Порталу, тобто Орендодавців і Орендарів;</dd>

      <dt>Преміум-Користувач</dt>
      <dd>означає зареєстрованих користувачів (тобто Орендарів) на Порталі;</dd>

      <dt>Орендодавець</dt>
      <dd>означає підприємця, який володіє Приміщенням або володіє відповідними правами на Приміщення, що дозволяють орендувати його Орендарю відповідно до умов, представлених на Порталі.</dd>

    </dl>

    <h3>3. Бронювання Приміщень через Портал</h3>
    <ol>
      <li>Якщо Орендар зацікавлений в оренді Приміщення і зі змісту Оголошення випливає, що воно доступне в необхідні для Орендаря дні і години, Орендар може здійснити Бронювання Приміщення.</li>
      <li>Точний процес Бронювання здійснюється відповідно до інструкцій наведеним на Порталі.</li>
      <li>Для Бронювання та використання Порталу Користувачеві необхідно мати пристрій з доступом до Інтернету та веб-браузер (наприклад, Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Safari тощо) та активний обліковий запис електронної пошти.</li>
      <li>Бронювання передається через Портал Орендодавцеві разом із даними Орендаря, які Орендар приймає.</li>
      <li>Договір Оренди вважається укладеним після оплати Орендарем Бронювання. Можливе розірвання Договору Оренди шляхом скасування Бронювання відповідно до п.  6 цих Правил.</li>
      <li>Після оформлення Бронювання Орендар отримає електронний лист на адресу, вказану у формі, що підтверджує здійснення Бронювання.</li>
      <li>Умовою здійснення бронювання є прийняття Правил, що визначають правові відносини між Оператором і Користувачем, в тому числі містять інформацію про правила обробки Оператором персональних даних Користувача, що є фізичною особою.</li>
      <li>При Бронюванні Користувач зобов'язаний надати справжні дані. Користувач може в будь-який час внести зміни в дані, надані при Реєстрації. Всякий раз, коли в Правилах йдеться про дані, надані при Реєстрації, під цим слід розуміти відповідно дані, змінені Користувачем надалі.</li>
      <li>Приймаючи Правила та здійснюючи Бронювання, Орендар зобов'язується перед Оператором, що:
        <ol>
          <li class="lower-alpha">він має повні права, що дозволяють Орендарю укласти з Орендодавцем дійсний та ефективний Договір Оренди та відповідні фінансові кошти для його реалізації;</li>
          <li class="lower-alpha">Орендар надав правдиві дані під час Реєстрації.</li>
        </ol>
      </li>
      <li>Крім того, приймаючи Правила та здійснюючи бронювання, Користувачі підтверджують та приймають, що:
        <ol>
          <li class="lower-alpha">правові відносини між Користувачем та Оператором регулюватимуться правилами, що випливають із Правил, включаючи правила щодо відповідальності Оператора та загальноприйняті закони;</li>
          <li class="lower-alpha">Оператор не перевіряє правильність даних, наданих Користувачем у процесі Реєстрації, і не несе відповідальності за їх правильність та достовірність, а також за будь-які негативні наслідки надання Користувачам невірних або неповних даних;</li>
          <li class="lower-alpha">Оператор не несе відповідальності перед Орендарем або Орендодавцем за належне виконання Договору Оренди, в тому числі, зокрема, не несе відповідальності за стан Приміщення, надання додаткового обладнання або інших послуг Орендодавцем;</li>
          <li class="lower-alpha">будь-які рекламації, подані Орендарем щодо виконання Договору Оренди, повинні подаватися безпосередньо Орендодавцю та пояснюватися та вирішуватися ним.</li>
        </ol>
      </li>
      <li>Оператор має право накласти штраф на Користувача, якщо він порушує правила Договору Оренди, зокрема, коли кількість людей у Залі відрізнятиметься від зазначеної у Бронюванні та якщо ця різниця впливає на ціну Залу. Розмір штрафу визначений у Додатку 1 до цих Правил.</li>
      <li>Відповідальність Оператора перед Користувачем, який не є споживачем в розумінні ст. 221 Цивільного кодексу, будь-яка шкода, завдана Користувачем внаслідок невиконання або неналежного виконання Послуг Оператора або інших дій чи бездіяльності Оператора, обмежується сумою 1000 злотих.</li>
      <li>У випадку, якщо Орендар є споживачем у розумінні ст. 221 Цивільного кодексу, тобто фізичною особою, яка здійснює юридичну операцію з підприємцем (Орендодавцем або Оператором), що не пов'язана безпосередньо з його підприємницькою або професійною діяльністю, обмеження відповідальності Оператора, зазначене в абз. 11 вище, а також інші обмеження або виключення відповідальності Оператора, що випливає з Правил, не застосовуватимуться, якщо вони не дозволені чинним законодавством.</li>
    </ol>

    <h3>4. Реєстрація на Порталі, дозволи облікового запису Користувача</h3>
    <ol>
      <li>Реєстрація на Порталі здійснюється шляхом заповнення реєстраційної форми, доступної на сайті Порталу. Перший вхід в Обліковий запис рівносильний його активації. У разі втрати логіна або пароля для доступу до Облікового запису, Користувач може скористатися процедурою їх відновлення, натиснувши на відповідні поля, доступні на Порталі.</li>
      <li>Точний процес Реєстрації Користувачів здійснюється відповідно до інструкцій наведеним на Порталі.</li>
      <li>Для Реєстрації та використання Порталу Користувачеві необхідно мати пристрій з доступом до Інтернету та веб-браузер (наприклад, Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Safari тощо) та активний обліковий запис електронної пошти.</li>
      <li>При Реєстрації Користувач зобов'язаний надати справжні дані. Користувач може в будь-який час внести зміни в дані, надані при Реєстрації. Всякий раз, коли в Правилах йдеться про дані, надані при Реєстрації, під цим слід розуміти відповідно дані, змінені Користувачем надалі.</li>
      <li>Користувач повинен зберігати в секреті і належним чином захищати логін і пароль.</li>
      <li>У максимально допустимому законодавством обсязі оператор не несе відповідальності за наслідки надання Користувачем невірних або неповних даних, а також за наслідки використання логіна і пароля Користувача третьою стороною.</li>
      <li>Користувач має право в будь-який час розірвати правові відносини, що зв'язують його з Оператором, умови якого випливають з Правил, шляхом видалення Облікового запису Користувача на Порталі. Оператор має право розірвати договір з Преміум-Користувачем на користування Порталом зі збереженням семиденного терміну повідомлення з поважних причин.</li>
      <li>Користувач, який пройшов процес реєстрації, матиме наступні права:
        <ol>
          <li class="lower-roman">Доступ до списку Ваших бронювань в Обліковому записі користувача.</li>
        </ol>
      </li>
    </ol>

    <h3>5. Скасування Бронювання</h3>
    <ol>
      <li>Користувач має право Скасувати Бронювання без пояснення причин.</li>
      <li>Якщо скасування Бронювання відбувається за 24 години до терміну Бронювання, Користувач отримає відшкодування Оплати вартості бронювання за вирахуванням суми вирахування.</li>
      <li>Якщо скасування Бронювання відбувається пізніше, ніж за 24 години до терміну Бронювання, Користувач не отримає відшкодування Оплати.</li>
      <li>Точний процес Скасування Бронювання і відшкодування Оплати, відбуватиметься відповідно до інструкцій наведених на Порталі.</li>
      <li>Оплата буде Відшкодована Оператором Платежів.</li>
    </ol>

    <h3>6. Оплата за бронювання</h3>
    <ol>
      <li>Розмір Оплати обумовлений Договором Оренди, укладеним між Орендодавцем і Орендарем. Розмір Оплати буде вказаний на Порталі після вибору Орендарем Оголошення.</li>
      <li>У разі укладення Договору Оренди, Орендар зобов’язаний сплатити Орендодавцю відповідно до Договору Оренди, укладеного Сторонами, при цьому Оплата здійснюється за посередництвом Оператора платежів.</li>
      <li>Орендар повинен внести Оплату, що випливає з Договору Оренди, відразу ж після укладення Договору Оренди.</li>
      <li>У разі, якщо Орендар не внесе Оплату, Бронювання буде автоматично скасоване через 15 хвилин після підтвердження Бронювання.</li>
      <li>Оператор виставляє на Орендаря рахунок-фактуру на суму Платежів відповідно до чинного законодавства та положень Договору Оренди.</li>
      <li>Погоджується, що після того, як Орендар скасує Бронювання, виставляться коригувальні рахунки-фактури на суму відповідно до суми Відрахування.</li>
      <li>Платіжна послуга надається Орендарю Постачальником Платежів, при цьому список доступних Постачальників Платежів включений в Портал. Перед оплатою Орендар повинен ознайомитися з Правилами оплати Постачальника Платежів і прийняти їх.</li>
      <li>Оплата, здійснена електронними каналами оплати, буде підтверджена Оператором шляхом відправлення електронного листа Орендарю.</li>
    </ol>

    <h3>7. Захист персональних даних</h3>
    <ol>
      <li>Стосовно Користувачів, які є фізичними особами, застосовуються положення цієї статті 8 Правил.</li>
      <li>Адміністратором персональних даних користувача є Оператор, тобто.: Usługi Taneczne Maciej Holski, Al. Jerozolimskie 123a, 00-017 Warszawa внесений до Центрального обліку господарської діяльності, з податковим номером NIP: 9522052934, № Regon: 367371440, Електронна пошта: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
      <li>Персональні дані будуть оброблятися Оператором для цілей, необхідних для надання послуг в рамках Порталу та виконання договору між Оператором та Користувачем, включаючи - у випадку Орендодавця - надання на його користь Послуг Оператора (ст. 6 абз. 1, п. b. GDPR/RODO). Оператор також може використовувати персональні дані Користувача для цілей, що випливають із законних інтересів, що реалізуються адміністратором (ст. 6 абз.1 п. f. GDPR/RODO), зокрема, маркетингу послуг, що надаються Оператором, включаючи профілювання персональних даних для вищезазначених потреб, а також для цілей створення статистики, за умови, що Користувач має право в будь-який момент виголосити спротив для обробки його персональних даних для цілей, зазначених у цьому реченні.</li>
      <li>Для маркетингових цілей Оператор може також обробляти персональні дані на підставі окремо вираженої згоди Користувача, в обсязі, що випливає з такої згоди (ст. 6 абз.1 п. a. GDPR/RODO). Висловлення цієї згоди є добровільним і Користувач може відкликати її в будь-який момент, за винятком того, що відкликання згоди не вплине на законність обробки персональних даних на підставі згоди до її відкликання.</li>
      <li>Персональні дані Орендодавця будуть видимі в рамках Порталу для всіх осіб, які переглядатимуть Портал.</li>
      <li>Персональні дані Орендаря будуть надані Орендодавцю під час здійснення Бронювання. Дані будуть передані Орендодавцю як окремому адміністратору персональних даних. Прийняття Правил являє собою згоду на передачу Оператором персональних даних Орендаря Орендодавцю, як окремому адміністратору даних. Абз. 4 застосовується відповідно.</li>
      <li>З урахуванням абзацу 5 і 6 вище, персональні дані Користувачів можуть передаватися суб’єктам, які їх обробляють, на вимогу Оператора, зокрема субпідрядникам Оператора, що надають ІТ-послуги, технічному обслуговуванню Порталу тощо.</li>
      <li>Персональні дані Користувачів, які Зареєструвались на Порталі, зберігатимуться Оператором протягом періоду використання Порталу та 24 місяців з моменту останнього входу на Портал.</li>
      <li>Що стосується персональних даних, що обробляються для маркетингових цілей, Оператор оброблятиме їх до тих пір, поки не отримає можливого спротиву Користувача або відкликання згоди Користувачем.</li>
      <li>Оператор оброблятиме всі або деякі з наведених нижче персональних даних Користувача: ім'я та прізвище, фірма - підприємницька діяльність Користувача, адреса Користувача, ідентифікаційний податковий номер "NIP", електронний адрес, контактний телефонний номер.</li>
      <li>Крім інших прав, про які йде мова в цій статті Регламенту, Користувач має право вимагати від Оператора доступу до своїх персональних даних, до їх виправлення, видалення або обмеження обробки, а також право передачі даних і подачі скарги до контролюючого органу.</li>
      <li>Надання персональних даних є добровільним, при цьому воно є необхідним для цілей Реєстрації та використання функціональності розміщення Оголошень та здійснення Бронювання.</li>
      <li>Ці Правила не регулюють спосіб обробки персональних даних Орендаря Орендодавцем або Орендодавця Орендарем. Зокрема, після передачі персональних даних Орендаря Орендодавцю внаслідок здійснення Бронювання, взаємна обробка персональних даних кожним Користувачем поширюється на положення чинного законодавства, зокрема Постанову Європейського Парламенту та Ради (ЄС) 2016/679 від 27 квітня 2016 про захист фізичних осіб у зв'язку з обробкою персональних даних та про вільне переміщення таких даних.</li>
      <li>Передача Оператором персональних даних Орендаря Орендодавцю здійснюється виключно з метою вжиття Орендодавцем заходів, спрямованих на укладення Договору Оренди з Орендарем, в тому числі, зокрема, для взаємного контакту Сторін для вищевказаних потреб. Передача персональних даних Орендаря Орендодавцю має характер передачі таких даних незалежному адміністратору. Орендодавець визнає, що Орендодавцю заборонено використовувати персональні дані, надані Оператором, для будь-яких інших цілей, зокрема для цілей надання Орендареві будь-яких пропозицій чи іншої комерційної інформації (крім даних пропозиції про оренду приміщень, до яких Орендар виявив зацікавленість), якщо тільки Орендодавець незалежно отримає права на такі дії.</li>
      <li>З питань персональних даних можна звертатися по електронній пошті <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
    </ol>

    <h3>8. Найм кімнат під час епідемії</h3>
    <ol>
      <li>Поки епідемія на території Республіки Польща не вирішиться, оренда приміщень дозволяється лише у випадках, регульованих постановами Уряду Республіки Польща. Зокрема, це:
        <ol>
          <li class="lower-alpha">Діяльність клубів спортивних танців</li>
          <li class="lower-alpha">Заходи, необхідні для підготовки мистецьких заходів, таких як репетиції та вправи</li>
          <li class="lower-alpha">Фонографічні та аудіовізуальні записи</li>
          <li class="lower-alpha">Події, що передаються за допомогою прямого віддаленого зв'язку.</li>
        </ol>
      </li>
    </ol>

    <h3>9. Заключні положення</h3>
    <ol>
      <li>Ці Правила набувають чинності у день 1 серпня 2020 року.</li>
      <li>У разі зміни Правил Оператор повідомляє Користувача про зміну в електронній формі, на електронну адресу Користувача, надану на Порталі. Нові Правила зобов'язують Користувача, якщо Користувач не розірвав Договір протягом 14 днів з дня отримання інформації про запропоновані зміни. Повідомлення про припинення може бути подано в електронній формі на електронну адресу Оператора, вказану в Правилах, і воно набирає чинності негайно. Відповідне застосування передбачено ст. 3 пункту 16 Правил. Протягом зазначеного вище 14-денного періоду застосовуються умови цих Правил (до пропонованої зміни).</li>
      <li>Оператор зобов'язаний надавати послуги належним чином (без помилок).</li>
      <li>Користувач має право подати Оператору рекламацію на послуги, що надаються Оператором, або на якість Порталу, на електронну адресу Оператора, вказану в Правилах. Оператор розгляне рекламацію Користувача та надасть відповідь не пізніше 14 днів з моменту отримання рекламації. Відповідь буде надіслана Користувачеві на його електронну адресу, вказану на Порталі.</li>
      <li>Оператор залишає за собою право тимчасово призупинити доступність Порталу, зокрема у випадку технічного обслуговування, ремонту чи розширення Порталу.</li>
      <li>У максимально допустимій чинним законодавством мірі Оператор не несе відповідальності за блокування адміністраторами поштових серверів для передачі повідомлень на адресу електронної пошти, вказану Користувачем, а також за видалення і блокування e-mail повідомлень через програмне забезпечення, встановлене на комп'ютері, використовуваному Користувачем.</li>
      <li>Оператор в максимально допустимій чинним законодавством мірі не несе відповідальності за будь-які перешкоди, в тому числі перерви в роботі Порталу, зокрема, викликані непереборною силою, неправомірним впливом третіх осіб або несумісністю Порталу з технічною інфраструктурою Користувача або причинами, що лежать на стороні постачальників послуг телекомунікацій.</li>
      <li>У статистичних цілях і для забезпечення максимально високої якості послуг Портал використовує інформацію, що записується сервером на кінцевому пристрої Користувача, яка потім зчитується при кожному підключенні веб-браузера (так звані cookies). Користувач може в будь-який час змінити настройки браузера, щоб не приймати такі файли або інформувати про їх передачу. Неприйняття файлів cookie може спричинити труднощі у використанні Порталу. Використання Користувачем браузера, настройки якого дозволяють зберігати файли cookie на пристрої Користувача, означає згоду на збереження вищезазначених файлів на пристрої цього Користувача.</li>
      <li>Користувач зобов'язаний утримуватися від дій, які можуть порушити належне функціонування Порталу, зокрема від втручання в програмне забезпечення, яке забезпечує функціонування Порталу чи вміст, представлений на Порталі, та від надання незаконного контенту.</li>
      <li>Спори, що виникають з правових відносин між Користувачем та Оператором, будуть розглядатися загальним судом для столичного міста Варшава. Вищенаведене не стосується можливих спорів з Орендарями, які є споживачами, і які будуть розглядатися компетентним судом відповідно до загальних положень чинного законодавства, або - якщо Сторони погоджуються - органом, погодженим Сторонами, призначеним для позасудового вирішення спорів.</li>
      <li>У питаннях, які не охоплені Правилами, застосовуються положення польського законодавства, зокрема положення Цивільного кодексу, а щодо Орендарів, які є споживачами - також положення Закону від 30 травня 2014 року про права споживачів.</li>
    </ol>

    <br/>
    <br/>

    <h3><a name="zalacznik_1">Додаток № 1</a></h3>
    <p><b>Таблиця Оплат</b></p>

    <table>
      <tr>
        <th>Вид Оплати</th>
        <th>Розмір Оплати</th>
        <th>Коментарі</th>
      </tr>
      <tr>
        <td>Вирахування</td>
        <td>4 злотих брутто.</td>
        <td>за кожну скасовану годину</td>
      </tr>
      <tr>
        <td>Штраф</td>
        <td>500 злотих брутто</td>
        <td>за надання іншої, крім фактичної кількості осіб у Залі, якщо це впливає на ціну залу</td>
      </tr>
    </table>

    `,
  },
  contactInfo: {
    content: `
      Email: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a><br/>
      Facebook Page: <a href="https://www.facebook.com/saletanecznepl" target="_blank">saletanecznepl</a><br/>
      <br/>
      Dance Rooms Europe Sp. z o.o.<br/>
      al. Jerozolimskie 123a<br/>
      02-017 Warszawa<br/>
      NIP (ІНПП): 7011164542<br/>
    `,
  },
  toastify: {
    'We sent you a password reset link. Check your email!':
      'Ми надіслали Вам посилання для скидання пароля. Перевірте свою електронну пошту!',
    'We sent your contact request': 'Ми надіслали Ваше повідомлення.',
    'Your booking time limit expired.':
      'Закінчився ліміт часу складання резервування.',
    'Your booking schedule has been changed.':
      'Час Вашого резервування був змінений.',
    'Your account has been deleted.': 'Ваш обліковий запис видалено.',
    'Your booking has been cancelled.': 'Your booking has been cancelled.',
    'Your password has been changed.': 'Ваш пароль був змінений.',
    'Form contains some errors': 'Форма містить деякі помилки',
    'Something went wrong. Contact us to book the room.':
      'Виникла проблема з вашим бронюванням, будь ласка, зв’яжіться з нами, щоб зробити бронювання.',
  },
  order: {
    Date: 'дата покупки',
    Price: 'Вартість придбання',
  },
  booking: {
    typePrivate: 'Private',
    selfEmployed: 'Self employed',
    otherBusiness: 'Other business type',
    typeCompany: 'Company',
    firstName: 'First name',
    lastName: 'Last name',
    Name: `Ім'я і прізвище`,
    Phone: 'Номер телефону',
    Street: 'Вулиця',
    Country: 'Kraj',
    StreetNumber: 'Street number',
    FlatNumber: 'Numer mieszkania',
    'ZIP Code': 'Поштовий індекс',
    City: 'Місто',
    Private: 'Приватний рахунок',
    Company: 'Фірмовий рахунок',
    'Company name': 'Назва компанії',
    'VAT Number': 'Номер NIP (ІНПП)',
    Next: 'Далі',
    'You can cancel your booking within {{hours}} hours.':
      'Ви можете скасувати бронювання за {{hours}} години до бронювання.',
    'You are renting the room with the following options':
      'Підсумовування умов оренди залу',
    'Billing details': 'Дані платника',
    Modify: 'Замініть',
    Invoice: 'Рахунок-фактура',
    Book: 'Зарезервувати',
    Date: 'Дата',
    rentDate: 'Дата бронювання',
    For: 'Для',
    From: 'Від',
    Hours: 'Години',
    to: 'до',
    'Room details': 'Ваше резервування',
    NIP: 'Номер NIP (ІНПП)',
    'E-mail': 'E-mail',
    'Confirmation of the room booking':
      'Підтвердження резервування залу {{room}}',
    'Booking status': 'Статус резервування',
    'Payment status': 'Статус оплати',
    'Booking number': 'Booking number',
    Localization: 'Localization',
    Room: 'Room',
    title: 'Резервування залу <b>{{ name }}</b>',
    step: 'крок {{ step }} z 2',
    'Book as guest user': 'Зарезервувати без входу в систему',
    'Customer data': 'Дані платника',
    'Sign In': 'Вхід в систему',
    or: 'або',
    Remember:
      'Remember that after signing up, you will get 5 PLN discount for your bookings!',
  },
  pricing: {
    'PRICE LIST': 'ПРЕЙСКУРАНТ',
    Hours: 'Години',
    Capacity: 'Максимальна кількість осіб',
    'Gross price per hour': 'Валова ціна / год',
  },
  bookingStatus: {
    COMPLETE: 'Підтверджено',
  },
  paymentStatus: {
    PAID: 'Оплачено',
  },
  validator: {
    'Incorrect VAT Number': 'Невірний номер NIP (ІНПП)',
  },
  cookies: {
    'This website uses cookies to enhance the user experience.':
      'Цей веб-сайт використовує файли cookie для покращення взаємодії з користувачем. Якщо ви вибираєте “Прийняти все”, ви приймаєте цей факт і погоджуєтеся на обробку нами цієї інформації. Якщо ви відмовитесь, ми використовуватимемо лише необхідні файли cookie. Виберіть “Налаштування параметрів”, щоб переглянути деталі та керувати параметрами. Щоб дізнатися більше, перегляньте нашу <1>політику конфіденційності</1>.',
    button: 'Прийняти все',
    declineButton: 'Відхилити все',
    modifyPreferences: 'Налаштування параметрів',
    Preferences: 'Уподобання',
    'Manage preferences': 'Керуйте налаштуваннями',
    Essential:
      'Необхідні файли cookie - гарантія технічної роботи сайту. Завжди.',
    Analysis: 'Аналітичні файли cookie.',
    readMore: 'Дізнатись більше',
    readLess: 'Менше',
    'Save preferences': 'Збережіть налаштування',
    statisticDescription: `<p>gtag.js і analytics.js встановлюють такі файли cookie:</p><br/>`,
    statisticPoints: `
    <p> - <b>_ga</b>: використовується для розрізнення користувачів з метою збору інформації про відвідування сайту</p>
    <p> - <b>_gid</b>:
    використовується для розрізнення користувачів з метою збору інформації про відвідування сайту</p>
    <p> - <b>_gat</b>: використовується Google Analytics для моніторингу частоти запитів на власних серверах</p>
    `,
  },
  howToBook: {
    title: 'Наші місця розташування?',
    content: `<ol>
  <li><p>Не потрібно реєструватися/логуватися щоб виконати резервацію.</p><p>Слід памятати, що залогований користувач має можливість самостійно керувати своїми резерваціями, а також виконувати завдання маркетингові та отримувати знижки!</p></li>
  <li>Обери місто</li>
  <li>Обери кількість осіб(разом з викладачем)</li>
  <li><p>Обери дату та час резервації</p><p>Памятай! Система шукає для Тебе вільну залу, значно скоріше знайдеш залу якщо подаси  конкретний час, наприклад 18-20, а не 10-23</p></li>
  <li>Натисни „Шукай”</li>
  <li><p>Система покаже доступні зали для Тебе в вибраних годинах. Необхідно обрати натискаючи на фото зали</p><p>Памятай! Якщо появиться тільки 1 доступна зала - необхідно вибрати і натиснути на її фото.</p></li>
  <li><p>Система відкриє профіль зали і підсумує твоє замовлення. Якщо все в порядку - перейди далі натискаючи „Зарезервуй”.</p></li>
  <li><p>Готуємо оплату за залу! Необхідно ввести свої дані, які викристаємо для фактури. 	Обери Приватне або Компанія, перевірь чи введені дані парвильні. Нарешті, не забудьте прийняти наші Правила та умови! Натисніть „Далі”</p></li>
  <li><p>Система підсумує Твої дані до фактури і ще раз запитає Тебе відносно складеного замовлення. Якщо все в порядку - натисни „Резервуй”</p></li>
  <li><p>Відбудеться перекерування до przelewy24.pl, виконай оплату.</p><p>Якщо повернишся назад з оплати, вона автоматично анулюється.</p><p>Якщо щось піде не так - маєш близько 1 години на оплату резервації. Деталі в мейлу з інформацією, що резервається ОБРОБЛЮЄТЬСЯ. Після того часу вона анулється.</p></li>
  <li><p>Якщо все удалось - отримаєш 4 мейли: з фактурою(InFakt), з деталями оплати (Przelewy24), з інформацією, що резервація є в Обробці, з Підтвердженням резервації.</p></li>
</ol>`,
  },
};
