import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
// import isEmpty from 'lodash/isEmpty';

// import { LineSeparator } from 'styles/globalStyles';

// import SimilarRooms from 'components/SimilarRooms';
import SearchFilter from 'components/SearchFilter';
import { Wrapper, SearchContentWrapper } from './style';

const SearchLayout = ({ children, hideFilters, location: { search } }) => {
  const query = queryString.parse(search);

  return (
    <Wrapper>
      <SearchContentWrapper>
        {!hideFilters ? <SearchFilter query={query} /> : null}

        {children}

        {/* {!isEmpty(query) && (
          <>
            <LineSeparator />
            <SimilarRooms query={query} />
          </>
        )} */}
      </SearchContentWrapper>
    </Wrapper>
  );
};

SearchLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideFilters: PropTypes.bool,
};

SearchLayout.defaultProps = {
  hideFilters: false,
};

export default withRouter(SearchLayout);
