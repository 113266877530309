import { extendObservable, action } from 'mobx';
import { API, APIRoutes } from 'api';
// import { toast } from 'react-toastify';
// import i18n from 'i18n';
// import { authStore } from 'stores';

const initialState = {
  isLoading: false,
  initialized: false,
  error: null,
  meta: {},
  roomsByAddress: {},
};

export class RoomsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  fetchRoomsGrouppedByAddress = async () => {
    this.isLoading = true;
    try {
      const {
        data: { items },
      } = await API.get(APIRoutes.rooms.listByAddress);

      this.roomsByAddress = items;
    } catch (error) {
      // eslint-disable-next-line
      console.debug(error);
    } finally {
      this.isLoading = false;
      this.initialized = true;
    }
  };

  @action
  reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };
}

export default new RoomsStore();
