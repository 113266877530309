/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Text } from 'rebass';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Preloader from 'components/Preloader';
import Modal from 'components/Modal';
import { Container, MainTitle } from 'styles/globalStyles';
import routes from 'routes';
import BuyModal from './components/BuyModal';
import { SinglePromotion } from './components/SinglePromotion';

const List = styled.ul`
  margin-top: 30px;
`;

const PromotionsPage = inject(
  'userStore',
  'routingStore',
  'promotionsStore',
)(
  observer(
    ({
      t,
      userStore: {
        initialized,
        profile,
        userPromotions,
        boughtExclusivePromotion,
      },
      routingStore: { push },
      promotionsStore: {
        fetchPromotions,
        isLoading,
        promotions,
        initialized: promotionsInitialized,
      },
    }) => {
      const [promotionId, setPromotionId] = useState(null);
      const [validityInDays, setValidityInDays] = useState(0);

      useEffect(() => {
        if (initialized) {
          if (profile.isPremium) {
            const getPromotions = async () => {
              await fetchPromotions();
            };

            getPromotions();
          } else {
            push(routes.account);
          }
        }
      }, [initialized, profile]);

      if (!initialized) {
        return <Preloader />;
      }

      const renderList = () => {
        if (isLoading || !promotionsInitialized) return <Preloader />;
        if (promotions.length) {
          return (
            <List>
              {promotions.map(promotion => {
                const amount = () => {
                  if (promotion.type === 'percent') {
                    return `${promotion.amount}%`;
                  }

                  return `${promotion.amount / 100} zł`;
                };

                return (
                  <SinglePromotion
                    key={`promotion-item_${promotion._id}`}
                    t={t}
                    promotion={promotion}
                    userPromotions={userPromotions}
                    amount={amount}
                    profile={profile}
                    setPromotionId={setPromotionId}
                    setValidityInDays={setValidityInDays}
                    boughtExclusivePromotion={boughtExclusivePromotion}
                  />
                );
              })}
            </List>
          );
        }

        return t("There's no special offers.");
      };

      return (
        <Container>
          <MainTitle>{t('Buy more discounts')}</MainTitle>

          <Text
            as="p"
            fontSize={[3, 3, 3, 3, 4]}
            lineHeight={['23px', '23px', '23px', '23px', '25px']}
            color="muted"
            mb={30}
          >
            {t('row-1.p')}
          </Text>
          <Text
            as="p"
            fontSize={[3, 3, 3, 3, 4]}
            lineHeight={['23px', '23px', '23px', '23px', '25px']}
            color="muted"
            mb={30}
          >
            {t('row-2.p')}
          </Text>
          <Text
            as="p"
            fontSize={[3, 3, 3, 3, 4]}
            lineHeight={['23px', '23px', '23px', '23px', '25px']}
            color="muted"
            mb={30}
          >
            {t('row-3.p')}
          </Text>

          {renderList()}
          <Modal
            opened={!!promotionId}
            close={() => {
              setPromotionId(null);
              setValidityInDays(0);
            }}
          >
            <BuyModal
              promotionId={promotionId}
              validityInDays={validityInDays}
              billingAddress={profile.billingAddress || {}}
            />
          </Modal>
        </Container>
      );
    },
  ),
);

export default withTranslation('promotions')(PromotionsPage);
