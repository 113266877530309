/* eslint-disable max-len */
export default {
  shop: {
    title: 'Dance shop',
    'no products': 'Sorry, there are no products',
  },
  footer: {
    termsAndConditions: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    Contact: 'Contact',
    'Investor Relations': 'Investor Relations',
  },
  account: {
    'Account Settings': 'Account Settings',
    'My bookings': 'My bookings',
    'Personal information': 'Personal information',
    'Billing information': 'Billing information',
    expired: 'Expired:',
    Phone: 'Phone number',
    Email: 'Email address',
    Name: 'Name & Surname / Nickname',
    'Billing Name': 'Name & Surname / Company name',
    'VAT Number': 'VAT Number',
    Street: 'Street',
    Country: 'Country',
    StreetNumber: 'Street number',
    FlatNumber: 'Flat number',
    'ZIP Code': 'ZIP Code',
    City: 'City',
    Save: 'Save',
    'booking.complete': 'COMPLETED',
    'booking.modify': 'MODIFICATE',
    'booking.cancelRes': 'CANCEL BOOKING',
    'booking.cancel': 'CANCEL',
    'booking.cancelled': 'CANCELLED',
    'Cancel room booking': 'Cancel room booking - {{ name }}',
    'Are you sure you want to cancel this reservation? A fee eqaual to 6 zł per booking hour will be charged.':
      'Are you sure you want to cancel this reservation? A fee equal to 6 zł per booking hour will be charged.',
    'Modify room booking': 'Modify room booking - {{ name }}',
    "You can change your booking's hours without extra costs. Choose one of listed options": `You can change your booking's hours without extra costs. Choose one of listed options.`,
    Change: 'Change',
    'You have no bookings yet.': 'You have no bookings yet.',
    DeleteInfo:
      'By deleting your account, you will not have access to your bookings.',
    'Delete account': 'Delete account',
    'Are you sure you want to delete your account?':
      'Are you sure you want to delete your account?',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Premium: 'Premium',
    'Your PREMIUM account is active!': 'Your PREMIUM account is active!',
    'package is valid to': 'package is valid to ',
    'Buy premium!': 'Buy premium!',
    "Don't know the benefits of a premium account? Get to know them now":
      "Don't know the benefits of a premium account? Get to know them now",
    'Additional promotions': 'Additional promotions',
    "You don't have any additional promotions":
      "You don't have any additional promotions",
    'Check additional promotions': 'Check additional promotions',
    'Check more additional promotions': 'Check more additional promotions',
    'buy.premium.modal.description':
      'Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna. Curabitur blandit tempus porttitor.',
    Buy: 'Buy',
    'Buy PREMIUM account': 'Buy PREMIUM account',
    "You've successfully bought premium account":
      "You've successfully bought premium account",
    "You've successfully bought promotion":
      "You've successfully bought promotion",
    'My orders': 'My orders',
    selfEmployed: 'Self employed',
    otherBusiness: 'Other business type',
  },
  buttons: {
    Search: 'Search',
    'Open filters': 'Open filters',
    'Reset password': 'Reset password',
    Book: 'Book',
    'Free room': 'Free room',
    'Selected room': 'Selected room',
    'Back to the homepage': 'Back to the homepage',
    Send: 'Send',
  },
  countries: {
    pl: 'Poland',
    de: 'Germany',
    fr: 'France',
  },
  discount: {
    facebook:
      '<b>Get 5zł /1 h for 7 days!</b> Go to our funpage and share it on your wall, so you will receive a DISCOUNT!',
  },
  common: {
    'Rent a dance hall': 'Book a Dance Room',
    From: 'From',
    To: 'To',
    'Current settings': 'Current settings',
    person: '{{count}} person',
    person_plural: '{{count}} people',
    'Search criteria': 'Search criteria',
    'Want to book a room for long term?': 'Want to book a room for long term?',
    'Contact us': 'Contact us',
    'Similar rooms': 'Similar rooms',
    'No rooms found! Please search for a different time or date, or contact us':
      'No rooms found! Please search for a different time or date, or contact us',
    Price: 'Price',
    max_people: 'For maximum: ',
    available: 'Available: ',
    personAndMore: '10 people and more',
    'Not found': 'Not found',
    starInfo:
      '*An additional charge of 500 zł will be applied in case of misleading number of people.',
    termsAndConditionsFooter: '<0>Terms of use</0> | <2>Privacy Policy</2>',
    short: '1 month - 39PLN',
    long: '3 months - 99PLN',
  },
  dropdowns: {
    'Choose location': 'Choose location',
    'Choose plan': 'Choose plan',
    'Number of people': 'Number of people',
    'Select day and hours': 'Select day and time',
    'Premium duration': 'Premium account duration',
    City: 'City',
  },
  home: {
    "Can't find room in your city?": "Can't find room in your city?",
  },
  navbar: {
    'About us': 'About us',
    Blog: 'News',
    Contact: 'Contact',
    'Sign up': 'Sign up',
    'Sign in': 'Sign in',
    Hello: 'Hello',
    'Account Settings': 'My Account',
    'Investor Relations': 'Investor Relations',
    Logout: 'Logout',
    Premium: 'Premium',
    Shop: 'Shop',
    'How to book': 'How to book',
    'Our locations': 'Our locations',
  },
  titles: {
    Login: 'Login',
    Register: 'Register',
    Contact: 'Contact',
  },
  forms: {
    'Already a member?': 'Already a member?',
    termsAndConditions:
      'I declare that I have familiarised myself with <2>Terms of Use</2> and <4>Privacy Policy</4>',
    Email: 'Email',
    Password: 'Password',
    'Password confirmation': 'Password confirmation',
    "Passwords don't match": "Passwords don't match",
    'Forgot password?': 'Forgot password?',
    Login: 'Login',
    Name: 'Name',
    'Sign in now': 'Sign in now',
    'Sign up now': 'Sign up now',
    'Not a member?': 'Not a member? ',
    Register: 'Register',
    forgotPasswordInstruction:
      'Please enter the email address you registered to receive an email with further instructions on how to reset your password.',
    resetPasswordInstruction: 'Enter a new password for your account',
    contactForm: 'Have a question? Write to us:',
    Phone: 'Phone number',
    Subject: 'Subject',
    City: 'City',
    Message: 'Message',
    callUs: 'You can always call us directly:',
    "I can't find room in my city": "I can't find room in my city",
    'Reset password': 'Reset password',
  },
  errors: {
    'Invalid username or password': 'Invalid username or password',
    'Something went wrong': 'Something went wrong',
    'Invalid email address': 'Invalid email address',
    'Invalid street number': 'Invalid street number',
    'Invalid e-mail address': 'Invalid e-mail address',
    'This field is required': 'This field is required',
    'Minimum duration is 1h': 'Minimum duration is 1h',
    'Start time must be before end time': 'Start time must be before end time',
    'Incorrect booking time': 'Incorrect booking time',
    invalid_zip_code: 'Invalid zip code',
  },
  aboutUs: {
    'About us': 'About us',
    'row-1.p1': `Rezerwuj sale taneczne w Polsce online`,
    'row-1.p2': `
    <ol>
      <li>Bezobsługowa rezerwacja – Rezerwujesz -> Płacisz -> Korzystasz</li>
      <li>Natychmiastowa informacja o dostępności sal 24/7</li>
      <li> Możliwość odwołania sali do 24h przed rezerwacją (za opłatą zgodną z aktualnym Regulaminem)</li>
      <li>Ty wybierasz salę i lokalizację</li>
      <li>Płatności online</li>
      <li>Faktura automatycznie wysyłana na maila od razu po opłaceniu rezerwacji.</li>
    </ol>`,
    'row-2.p1': `Rabat za rejestrację`,
    'row-2.p2': `Rewolucja dla instruktorów`,
    'row-3.p1': `Dla zarejestrowanych użytkowników przygotowaliśmy rabat, który będzie aktywny na koncie przez 7 dni.<br/>
      Wszystkie ceny spadną o 5zł/1h jeśli udostępnisz post na swoim facebook’u!<br/>
      Rabat jest odnawialny. Kolejny klik, kolejny tydzień obniżonych cen.<br/>
      Jak uruchomić rabat:<br/>
      Zaloguj się na konto<br/>
      Na górze strony pojawi się niebieski pasek z info o rabacie<br/>
      Kliknij w pasek, przekieruje Cię on do facebook’a<br/>
      Kliknij „Udostępnij”<br/>
      Upewnij się, że post jest publiczny<br/>
      Wróć do saletaneczne.pl i rezerwuj<br/>
      Zapoznaj się z Regulaminem, a w razie pytań pisz do nas na <a href="https://www.facebook.com/saletanecznepl" target="_blank">facebook’u</a>`,
    'row-3.p2': `Para odwołała zajęcia pierwszego tańca? Klient nagle „zachorował”?
      Teraz to klientów możesz poprosić o zarezerwowanie sali przez nasz system.
      To na nich przejdzie obowiązek zapłaty, pilnowania rezerwacji i wybór sali.`,
    'row-4.quote': `Sukces nie polega na osiąganiu czegoś w przyszłości, lecz na robieniu tego co kochasz teraz`,
    'row-5.p1': `Misją naszej firmy jest tworzenie profesjonalnych przestrzeni tanecznych.  Sale dedykujemy zarówno zawodowcom, jak i miłośnikom tańca.<br/><br/>
    Głównymi zaletami współpracy w tym modelu są dostęp do powierzchni różnej wielkości, absolutnie dowolne godziny zajęć oraz realizacja rezerwacji tu i teraz, bez oczekiwania na odpowiedź szkoły.<br/>
    W praktyce ten system doskonale sprawdza się w pracy instruktora, który potrzebuje natychmiastowej informacji dla klienta, czy w danym terminie zajęcia mogą się odbyć.<br/><br/>
    Jest to świetne rozwiązanie dla tancerza, który szuka miejsca do treningu w godzinach, które mu pasują. Znajdziesz tutaj salę dostępną nawet w Nowy Rok o 8:00 rano, nie wspominając o weekendach, popołudniach i porankach. Sale dostępne są ZAWSZE.<br/>
    Z każdym miesiącem dołączają do nas kolejne szkoły tańca. W tym modelu nie trzeba się zastanawiać, jak zapełnić salę poza prime time, to nasz problem. Wy robicie to, co kochacie, pracujecie z kursantami wtedy, kiedy chcecie. Jeśli jest taka potrzeba, możecie rezerwować kilka sal równocześnie o godzinie 19:00. Nie ma problemu😉<br/><br/>
    Na naszym koncie wybiło już ponad 200.000 zrealizowanych godzin rezerwacji. Posiadamy 40 sal, w 5 lokalizacjach, w 3 miastach i wciąż się rozwijamy😉`,
  },
  investorRelations: {
    title: 'Investor Relations',
  },
  premiumLanding: {
    'Become PREMIUM user': 'Become PREMIUM user',
    'row-1.p': `Become a premium user in no time`,
    'list-item.1': 'Prices lower by 5zł per hour *',
    'list-item.2':
      'Possibility to cancel the booking up to 12 hours before the start time',
    'list-item.3':
      'Access to additional packages with prices reduced by 10 PLN/1h',
    'Get premium account': 'Get premium account',
    'plans.header': 'Available plans',
    month: 'month',
    months: 'months',
    'asterix-info':
      '*reduced from normal prices without account registration. E.g. Warsaw, 3 people, 10:00-11:00 PLN 25 PLN - 5 PLN = 20 PLN / 1h',
    'already-premium':
      'You already have a <1>PREMIUM</1> account (valid until {{availableTo}})',
  },
  promotions: {
    'row-1.p': `Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Curabitur blandit tempus porttitor.
                Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur est at lobortis. Donec ullamcorper nulla non metus auctor fringilla. Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo.`,
    'Buy more discounts': 'Buy more discounts',
    "There's no special offers": "There's no special offers",
    Discount: 'Discount',
    Availability: 'Availability',
    Price: 'Price',
    Limit: 'Daily limit',
    Description: 'Description',
    'Buy now': 'Buy now',
    days: 'days',
    hour: '{{count}} hour',
    hour_plural: '{{count}} hours',
    minutes: 'minutes',
    Buy: 'Buy',
    'Buy discount': 'Buy discount',
    'buy.promotion.modal.description':
      "To purchase an additional PACKAGE, enter the payer's details and click the BUY button",
    Duration: 'Duration',
    'Select duration': 'Select duration',
    'buy.promotion.modal.statement':
      'I am asking you to start providing digital content immediately (before the expiry of the 14-day withdrawal period). Thus, I acknowledge that from this moment I cannot withdraw from the contract.',
  },
  privacyPolicy: {
    'Privacy Policy': 'Privacy Policy of saletaneczne.pl',
    content: `
    <h3>1. General information</h3>
    <p>1. This policy applies to the website located under the following URL address: https://saletaneczne.pl (“Website”). All terms which are capitalised in this Policy but not defined herein should be construed in accordance with the definitions provided in the Terms and Conditions of saletaneczne.pl.</p>
    <p>2. The Website’s operator and personal data administrator of the personal data of the Website’s users is the following company: Dance Rooms Europe Sp. z o.o. from Warsaw: Al. Jerozolimskie 123a, 02-017 Warszawa, entered into the Business Activity Central Register and Information Record in Warsaw, XII Wydział Gospodarczy Krajowego Rejestru Sądowego with number: KRS 0001057815, REGON: 526386363, NIP: 7011164542  with a share capital of 5 000,00 zł (słownie: five thousand i 00/100 PLN) ('Operator').</p>
    <p>3. The contact address of the Website’s operator is: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>
    <p>4. The Operator is the administrator of Users' personal data in relation to data provided voluntarily on the Website.</p>
    <p>5. The Website uses personal data for the following purposes:</p>
    <ul>
      <li>Publishing Ads;</li>
      <li>Providing information about Booking recreational facilities to Facility owners;</li>
      <li>Displaying the selected User name on a board in the booked dance hall.</li>
    </ul>
    <p>6. The Website obtains information on the users and their behaviour by:</p>
    <ol>
      <li>retrieving data provided voluntarily on the forms which are entered into the Operator’s systems;</li>
      <li>by saving cookie files (“cookies”) on their end devices.</li>
    </ol>

    <h3>2. Rules for the processing of personal data of the Website’s users by the Operator</h3>
    <p>1. The Operator is the administrator of the Website Users' personal data.</p>
    <p>2. Personal data will be processed by the Operator to perform actions that are necessary to provide services via the Website and to perform the agreement between the Operator and the User, including to provide the Operator’s services to them (article 6 item 1 letter b of the GDPR). The Operator may also use the User’s personal data for purposes arising from legitimate interests pursued by the controller (article 6 section 1 letter f of the GDPR), in particular for marketing services provided by the Operator, including personal data profiling for the purposes specified above and creating statistics, subject to the fact that the User has the right to object to the processing of their personal data for the purposes listed in this sentence, at any time.</p>
    <p>3. For marketing purposes, the Operator may also process personal data on the basis of the User’s consent given separately, to the extent arising from such consent (article 6 section 1 letter a of the GDPR). Provision of this consent is voluntary, and the User may withdraw it at any time; however, if the consent is withdrawn, the processing of personal data performed before the withdrawal remains legal.</p>
    <p>4. The personal data of the Facility Owner will be visible on the Website to all people who view the content of the Website.</p>
    <p>5. The Users personal data will be provided to the Facility owner when the Booking is made. The data will be transferred to the Owner of the facility as a separate personal data administrator. Acceptance of the Terms and Conditions constitutes consent for the Operator to transfer the User's personal data to the Facility Owner as a separate data administrator. Section 2.4 is applied in a similar manner.</p>
    <p>6. Subject to articles 2.4 and 2.5 below, the personal data of the Users may be made available to the entities which process them at the Operator’s order, and specifically to the Operator’s contractors who provide IT services, Website maintenance etc.</p>
    <p>7. The personal data of the Users who made Booking on the Website will be stored by the Operator for the period of website usage and for 24 months from the last login on the Website.</p>
    <p>8. With regard to personal data processed for marketing purposes, the Operator will process them as long as the User raises no objection about it or until they have withdrawn their consent.</p>
    <p>9. The Operator will process some or all of the following personal data of the Users: name and surname, business name of the User’s company, the User’s address, tax identification number (NIP), e-mail address, contact phone number.</p>
    <p>10. Apart from the other rights referred to in this provision of the Rules, the User has the right to request that Operator should grant them the access to their personal data, allow them to rectify them, delete them or limit their processing; they also have the right to have their data transferred or to lodge a complaint with a supervisory body, that is: the President of the Office for Personal Data Protection, ul. Stawki 2, 00-193 Warszawa.</p>
    <p>11. Provision of personal data is voluntary, but it is necessary to enable the registration and to use the functions of publishing ads and making bookings.</p>
    <p>12. You can contact us about personal data at the following e-mail address: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>.</p>

    <h3>3. Selected methods of data protection used by the Operator</h3>
    <p>1. Users’ passwords are stored in hashed form. The hashing function works only one way: it is impossible to reverse it, which constitutes a current standard in terms of storing users’ passwords.</p>
    <p>2. In order to protect the data, the Operator makes safe copies on a regular basis.</p>

    <h3>4. Admin logs</h3>
    <p>1. Information on the Users’ behaviour on the Website may be subject to logging. These data are used for managing the Website.</p>

    <h3>5. Notable marketing techniques</h3>
    <p>1. The Operator uses statistical analysis of the website traffic offered by Google Analytics, Google Tag Manager (Google Inc. with its registered office in the United States). The Operator does not provide the personal data service to the operator, but only anonymised information. The service is based on cookies used on the User’s end device. As regards information on the user’s preferences collected by the Google Display Network, users may view and edit information originating from cookie files using the following tool: <a href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a> </p>
    <p>2. The Operator uses remarketing techniques, allowing them for adjusting advertisements to the users’ behaviour on the website, which may create an illusion that the users’ personal data are used for tracking them, but in practice no personal data are exchanged between the Operator and the advertisement operators. Such activities are technologically possible only if cookie files are turned on.</p>
    <p>3. The Operator uses the Facebook pixel technology. This technology allows Facebook (Facebook Inc. with its registered office in the United States) to know that the person registered on the Website is using it. In such a case, it relies on data with regard to which it is the controller; the Operator does not provide any additional personal data to Facebook. The service is based on cookies used on the User’s end device.</p>
    <p>4. The Operator uses a solution that automates the Website’s functions with regard to the users, for instance a solution that allows for sending an e-mail to a user after they have visited a certain page, as long as they have consented to receiving commercial correspondence from the Operator.</p>

    <h3>6. Information on cookies files</h3>
    <p>1. The Website uses cookie files.</p>
    <p>2. Cookie files (“cookies”) are IT data, in particular text files, which are stored on the User’s end devices and intended for using the Website’s pages. Cookies usually contain the name of the website from which they originate, their time of storage on the end device and a unique number.</p>
    <p>3. The entity which places cookie files on the end devices of the Website's Users and obtains access to them is the Website’s Operator.</p>
    <p>4. Cookie files are used for the following purposes:</p>
    <ol>
      <li>maintaining the session of the Website’s user (after they have logged in), thanks to which the user does not need to enter the login and password on each page of the Website;</li>
      <li>achieving the goals specified below in the section on “Notable marketing techniques”.</li>
    </ol>
    <p>5. Two main cookie file types are used on the website: session cookies and persistent cookies. Session cookies are temporary files that are stored on the user’s end device until they log out, leave the page or turn off the software (the browser). Permanent cookies are stored on the user’s end device for a definite period of time, specified in the cookie files parameters or until the cookies are deleted by the user.</p>
    <p>6. Software used for viewing websites (a browser) usually allows for the default saving of cookie files on the user’s end device. Each user of the Website may change their settings concerning cookies. Browsers allow for deleting cookie files. It is also possible to automatically block cookie files. Detailed information about it can found on the help page or documentation of the browser.</p>
    <p>7. Restrictions on using cookie files may affect certain functions available on the Website’s pages.</p>
    <p>8. Cookie files saved on the end device of the Website’s user may also be used by companies working with the Website’s operator, which specifically applies to such companies as: Google (Google Inc. with its registered office in the United States), Facebook (Facebook Inc. with its registered office in the United States), Twitter (Twitter Inc. with its registered office in the United States).</p>

    <h3>7. Managing cookie files – how can you give and withdraw your consent?</h3>
    <p>1. If a user doesn’t want to receive cookies, they may change their browser settings. Please note that if you turn off the cookie files which are necessary for the processes of authentication, maintaining safety and remembering user preferences, it may be more difficult or in some cases, even impossible to visit some websites.</p>
    <p>2. In order to manage cookie settings, select your browser from the list below and proceed in accordance with instructions:</p>
    <ul>
      <li>Edge</li>
      <li>Internet Explorer</li>
      <li>Chrome</li>
      <li>Safari</li>
      <li>Firefox</li>
      <li>Opera</li>
    </ul>
    <p>Mobile devices:</p>
    <ul>
      <li>Android</li>
      <li>Safari (iOS)</li>
    </ul>
    `,
  },
  regulations: {
    Regulations: 'TERMS AND CONDITIONS OF THE WEBSITE saletaneczne.pl',
    RegulationsIndividual:
      'Terms and Conditions of the website saletaneczne.pl for Individual Clients',
    RegulationsCompany:
      'Terms and Conditions of the website saletaneczne.pl for Business Clients',
    content: `

    <h3>1. Objects of the Terms and Conditions</h3>
    <p>This document specifies the terms and conditions concerning the registration of entities interested in the lease of dance halls via the website “saletaneczne.pl”, lease and booking of dance halls and payments for lease.</p>

    <h3>2. Definitions</h3>
    <p>For the purposes of interpreting these Terms and Conditions, the following definitions are adopted:</p>

    <dl>
      <dt>Payment Provider</dt>
      <dd>refers to the entity offering, pursuant to the applicable provisions of law and licences held, payment services that allow for making the Payment, including the following website: www.przelewy24.pl;</dd>

      <dt>Lessee</dt>
      <dd>refers to a business or another entity which has capacity for legal acts and is interested in the lease of the Premises;</dd>

      <dt>Advertisement</dt>
      <dd>refers to an invitation to enter a Lease Contract as per article 71 of the Polish Civil Code, published on the Website by Operator at the Lessor’s request, which specifies the basic provisions of the Lease Contract, including in particular the area of the Premises, dates when booking is possible, remuneration (rent) for the lease of the Premises, rules concerning withdrawing from the Lease Contract and, if applicable, additional rules for using the Premises, in particular concerning services available under the Lease Contract;</dd>

      <dt>Operator</dt>
      <dd>refers to the entity which owns the rights to the Website, that is the following company: Dance Rooms Europe Sp. z o.o. company from Warsaw: Al. Jerozolimskie 123a, 02-017 Warszawa, entered in the Business Activity Central Register and Information Record, kept by the District Court in Warsaw, 12th Commercial Division of the National Court Register, under no KRS 0001057815, REGON: 526386363, NIP: 7011164542 with a share capital of 5 000,00 zł (in words: five hundred and 00/100 zlotys), e-mail: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a>;</dd>

      <dt>Payment</dt>
      <dd>refers to the total amount that is payable by the Lessee to the Lessor pursuant to the Lease Contract and includes, in particular, the Lessor’s remuneration (rent) for making the Premises available to the Lessee within the period specified in the Lease Contract.</dd>

      <dt>Premises</dt>
      <dd>refer to dance hall areas presented by the Lessors on the Website, which are the objects of the Lease Contract if it is concluded;</dd>

      <dt>Deduction</dt>
      <dd>refers to the amount that will be deducted from the Amount paid for the Booking if it is reimbursed. The current <b>Deduction</b> amount is specified in Attachment no. 1 hereto;</dd>

      <dt>Terms and Conditions</dt>
      <dd>refer to the terms and conditions specified in this document;</dd>

      <dt>Registration</dt>
      <dd>refers to the process of setting up an account on the Website by a Premium User, containing the User’s data that allow for identifying them on the Website; </dd>

      <dt>Booking</dt>
      <dd>refers to the submission of a statement of intent by the Lessee via the Website, in which they state their wish to lease the Premises within a specified deadline. Making a booking involves acceptance of the basic provisions of the Lease Contract; for other matters, the provisions of the Polish Civil Code and/or other acts shall apply to the Lease Contract.</dd>

      <dt>GDPR</dt>
      <dd>refers to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC;</dd>

      <dt>The Website</dt>
      <dd>refers the online platform available at the following address: <a href="https://www.saletaneczne.pl">www.saletaneczne.pl</a>, including the pages on the Website, that allows Lessors to present and potential Lessees to acquaint themselves with information on the availability of the Premises and the conditions of lease, and also allows the Lessees to book the Premises;</dd>

      <dt>Lease Contract</dt>
      <dd>refers to the contract concerning the lease of the Premises, concluded by and between the Lessee and Lessor as a result of a Booking made by the Lessee via the Website;</dd>

      <dt>Operator’s Service</dt>
      <dd>refers to a service performed by the Operator for the Lessor that consists in being the Lessor's intermediary in concluding the Lease Contract; when performing the Operator’s Service, the Operator acts for the Lessor, taking steps towards the conclusion of a Lease Contract;</dd>

      <dt>User</dt>
      <dd>refers to all users of the Website, that is Lessors and Lessees;</dd>

      <dt>Premium User</dt>
      <dd>refers to users registered on the Website (that is Lessees);</dd>

      <dt>Lessor</dt>
      <dd>refers to an entrepreneur that owns the Premises or who has appropriate rights to the Premises that allow them to lease it to the Lessee in accordance with the rules presented on the Website.</dd>

    </dl>

    <h3>3. Booking Premises via the Website</h3>
    <ol>
      <li>If the Lessee is interested in the lease of the Premises and if it arises from the Advertisement that the Premises are available at the times and on the dates requested by the Lessee, the Lessee may book the Premises.</li>
      <li>As regards details, the process of making a Booking will follow the instructions from the Website.</li>
      <li>The User must have a device with Internet access and a browser (for instance, Internet Explorer, Mozilla Firefox, Opera, Google Chrome or Safari) and an active e-mail account in order to make a Booking and to use the Website.</li>
      <li>The Website informs the Lessor about the booking and provides them with the Lessee’s data, which the Lessee accepts.</li>
      <li>The Lease Contract will be deemed concluded when the Lessee pays for the Booking. A Lease Contract may be terminated by cancellation of the Booking pursuant to Section 6 hereof.</li>
      <li>After the Lessee has made a Booking, they will receive an e-mail confirmation of the Booking to the address provided in the form.</li>
      <li>The condition for making a Booking is accepting the Terms and Conditions, which specify the legal relationship between the Operator and the User, and also contain information on the rules of processing the User’s personal data by the Operator (if the User is a natural person).</li>

      <li>When making a Booking, the User is required to provide true data. The User may change the data provided during the Registration at any time. Wherever the Terms and Conditions refer to the data provided during the Registration, they should be construed as the data modified by the User at a later date.</li>
      <li>By accepting the Terms and Conditions and making a Booking, the Lessee undertakes an obligation towards the Operator that:
        <ol>
          <li class="lower-alpha">They have full rights that allow them to conclude a valid and effective Lease Contract with the Lessor as well as sufficient funds to perform such a contract;</li>
          <li class="lower-alpha">They have provided true data during the Registration.</li>
        </ol>
      </li>
      <li>Also, by accepting the Terms and Conditions and making a Booking, the Users acknowledge and accept that:
        <ol>
          <li class="lower-alpha">As regards the legal relationship between the User and Operator, rules arising from these Terms and Conditions shall apply, including the rules concerning the Operator’s liability and the generally applicable provisions of law;</li>
          <li class="lower-alpha">The Operator does not verify the correctness of data provided by the User during the Registration, is not accountable for their correctness and reliability and holds no liability for any adverse consequences of the provision of incorrect or incomplete data by the User;</li>
          <li class="lower-alpha">The Operator is not accountable towards the Lessee nor the Lessor for the proper performance of the Lease Contract, and specifically the Operator is not liable for the condition of the Premises, ensuring additional equipment or for the provision of other services by the Lessor;</li>
          <li class="lower-alpha">Any complaints made by the Lessee regarding the performance of the Lease Contract should be lodged directly with the Lessor as well as examined and resolved by the Lessor.</li>
        </ol>
      </li>
      <li>The Operator has the right to impose a penalty on the User if the User violates the provisions of the Lease Contract, and in particular if the number of people in the Dance Hall is different from the number provided in the Booking and if the difference is significant for the price of the Dance Hall. The Amount of the penalty is specified in the Attachment no. 1 hereof.</li>
      <li>The Operator's liability towards the User who is not a consumer as per article 221 of the Polish Civil Code for any damage incurred by the User as a result of non-performance or incomplete performance of the Operator’s Services or as a result of other actions or omissions of the Operator will be limited to the amount of PLN 1,000.</li>
      <li>If the Lessee is a consumer as per article 221 of the Polish Civil Code, that is a natural person who engages in a legal act with an entrepreneur (the Lessor or the Operator) that is not related directly to its business or professional activity, the limitation of the Operator’s liability referred to in section 11 above will not apply, and no other restrictions or exclusions of the Operator's liability arising from the Terms and Conditions will apply either, to the extent permitted by the applicable provisions of law.</li>
    </ol>

    <h3>4. Registration on the Website, rights attached to the User’s account</h3>
    <ol>
      <li>Registration on the Website is executed by filling in a registration form available on the Website. The account is activated at the moment of first login. If the User loses their login or password, the User may use the recovery procedure by clicking on the relevant buttons available on the Website.</li>
      <li>In detail, the process of registering Users will follow the instructions from the Website.</li>
      <li>The User must have a device with Internet access and a browser (for instance, Internet Explorer, Mozilla Firefox, Opera, Google Chrome or Safari) and an active e-mail account in order to register on the Website and use it.</li>
      <li>When registering, the User is obliged to provide true data. The User may change the data provided during Registration at any time. Wherever the Terms and Conditions refer to the data provided during Registration, they should be construed as the data modified by the User at a later date.</li>
      <li>The User should keep their login and password secret and protect it appropriately.</li>
      <li>To the broadest extent permitted by law, the Operator holds no liability for the consequences of provision false or incomplete data by the User nor for the consequences of use of the User’s login and password by a third party.</li>
      <li>The User has the right to dissolve, at any time, the legal relationship with the Operator, which is regulated by these Terms and Conditions, by deleting their account on the Website. The Operator has the right to dissolve the contract on using the Website concluded with the Premium User for significant reasons, with a seven days’ notice.</li>

      <li>A User who has completed the process of Registration will have the rights listed below:
        <ol>
          <li class="lower-roman">Access to the list of their bookings on the User’s account.</li>
        </ol>
      </li>
    </ol>

    <h3>5. Cancelling a Booking</h3>
    <ol>
      <li>The User has the right to cancel their booking without providing a reason for cancelling.</li>
      <li>If a Booking is cancelled earlier than 24 hours prior to the booking date, the User will be reimbursed for the Payment made for the Booking, reduced by the Deduction amount.</li>
      <li>If a Booking is called later than 24 hours prior to the booking date, the User will not be reimbursed for the Payment.</li>
      <li>As regards details, the process of cancelling a booking and reimbursement will follow the instructions from the Website.</li>
      <li>The Payment will be reimbursed by the Payment Operator.</li>
    </ol>

    <h3>6. Payments for Bookings</h3>
    <ol>
      <li>The Payment amount results from the Lease Contract concluded by and between the Lessor and Lessee. The Payment amount will be displayed on the Website when the Lessee selects the Advertisement.</li>
      <li>If the Lease Contract is concluded, the Lessee is obliged to make the Payment to the Lessor pursuant to the Lease Contract concluded by the Parties, where the Payment is executed by the Payment Operator.</li>
      <li>The Lessee must make the Payment arising from the Lease Contract promptly upon concluding the Lease Contract.</li>
      <li>If the Lessee fails to make the Payment, the Booking will be automatically cancelled after 15 minutes from the confirmation of the Booking.</li>
      <li>The Lessor will issue invoices with the Payment amounts to the Lessee pursuant to the applicable provisions of law and provisions of the Lease Contract.</li>
      <li>It is agreed that after the Lessee has cancelled the Booking, corrective invoices will be issued for the amount in accordance with the amount of the Deduction.</li>
      <li>The Payment service is offered to the Lessee by the Payment Provider; the list of available Payment Providers can be found on the Website. Before making the Payment, the Lessee should read the Payment Providers’ terms and conditions and accept it.</li>
      <li>Making the Payment via electronic payment channels will be confirmed by the Operator in an e‑mail notification sent to the Lessee.</li>
    </ol>

    <h3>7. Personal data protection</h3>
    <ol>
      <li>If the Users are natural persons, the provisions of this article 8 hereof shall apply.</li>
      <li>The data controller for the Users’ personal data is the Operator, that is: Usługi Taneczne Maciej Holski, Al. Jerozolimskie 123a, 00-017 Warszawa, entered in the Business Activity Central Register and Information Record, with the following tax identification number (NIP:) 9522052934, REGON (Business Statistical Number): 367371440, e-mail: <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
      <li>Personal data will be processed by the Operator for purposes that are necessary for providing services on the Website and for performing the contract concluded by and between the Operator and User, including, in the case of the Lessor, for allowing the provision of the Operator’s Services to the Lessor (Article 6 section 1 item b of the GDPR). The Operator may also use the User’s personal data for the purposes resulting from legitimate interests pursued by the Data Controller (article 6 section 1 item f of the GDPR), in particular for the purposes of marketing the services offered by the Operator, including profiling personal data for such needs, as well as for creating statistics, where the User has, at any time, the right to object to the processing of their personal data for the purposes specified in this sentence.</li>
      <li>For marketing purposes, the Operator may also process personal data pursuant to the User’s consent granted separately, to the extent arising from such a consent (article 6 section 1 item a of the GDPR). This consent is granted on a voluntary basis, and the User may withdraw it any time, but the withdrawal of consent will not affect the lawfulness of the processing of the personal data pursuant to the consent that took place before the consent was withdrawn.</li>
      <li>The Lessor’s personal data will be visible on the Website for all persons that will view the content on the Website.</li>
      <li>The Lessee’s personal data will be disclosed to the Lessor when a Booking is made. The data will be disclosed to the Lessor as a separate data controller. Accepting these Terms and Conditions involves granting consent for the Operator to disclose the Lessee’s personal data to the Lessor as a separate data controller. Section 4 shall apply by analogy.</li>
      <li>Subject to sections 5 and 6 above, the Users’ personal data may be disclosed to the entities that process them on the Operator's behalf, in particular to the Operator's subcontractors that provide IT, website maintenance or similar services to the Operator.</li>
      <li>Personal data of the Users who registered on the Website will be stored by the Operator for the period in which they will use the Website and for 24 hours from their last login in the Service.</li>
      <li>As regards personal data processed for marketing purposes, the Operator will process them until any objection is raised by the User or until the consent is withdrawn by the User.</li>
      <li>The Operator will process all or some of the following personal data of the Users: name and surname, name of the User’s business, User’s address, tax identification number (NIP), e-mail address, contact phone number.</li>
      <li>Apart from the rights specified in this section of these Terms and Conditions, the User also has the right to demand that the Operator should grant them access to their personal data, the right to rectify them, delete them or to restrict their processing, as well the right to transfer the data and to lodge a complaint with a supervisory body.</li>
      <li>Provision of personal data is voluntary, but it is necessary for the purposes of Registration and using the functions of publishing Advertisements and making Bookings.</li>
      <li>These Terms and Conditions do not regulate the manner of processing the Lessee’s personal data by the Lessor or the processing of the Lessor's personal data by the Lessee. In particular, when the Lessee’s personal data are disclosed to the Lessor at the moment of the Booking, the mutual processing of personal data by each of the User’s is subject to the applicable law, in particular to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.</li>
      <li>Disclosure of the Lessee’s personal data to the Lessor is executed by the Operator solely for the Lessor to take actions towards conclusion of a Lease Contract with the Lessee, including for the Parties to communicate between each other for the purposes specified above. Disclosure of the Lessee's personal data to the Lessor has the nature of providing such data to an independent data controller. The Lessor acknowledges that the Lessor must not use the personal data disclosed by the Operator for any other purposes, and in particular the Lessor must not use it to send any offers or commercial information to the Lessee (except for the data available in the offer to lease the Premises in which the Lessee has expressed interest), unless the Lessor obtains the right to take such actions independently.</li>
      <li>In issues relating to personal data, you can contact us via e-mail at <a href="mailto:saletaneczne@gmail.com">saletaneczne@gmail.com</a></li>
    </ol>

    <h3>8. Rooms booking during the epidemic</h3>
    <ol>
      <li>Until the epidemic disappears in the territory of the Republic of Poland, renting the premises is allowed only in cases regulated by the regulations of the Government of the Republic of Poland. In particular these are:
        <ol>
          <li class="lower-alpha">Activities of sports dance clubs;</li>
          <li class="lower-alpha">Activities necessary to prepare artistic events such as rehearsals and exercises;</li>
          <li class="lower-alpha">Phonographic and audio-visual recordings;</li>
          <li class="lower-alpha">Events transmitted by means of direct remote communication.</li>
        </ol>
      </li>
    </ol>

    <h3>9. Final provisions</h3>
    <ol>
      <li>These Terms and Conditions become effective as of 1 August 2020.</li>
      <li>If the Terms and Conditions are amended, the Operator will inform the User about such amendments in electronic form, ending a message to the e-mail address provided by the User on the website. The amended Terms and Conditions are binding on the User if the User has not terminated the Agreement within 14 days from receiving information on the proposed amendment. A statement on terminating the Agreement may be submitted in electronic form, to the Operator's e-mail address provided in the Terms and Conditions, and will have immediate effect. Article 3 section 16 hereof is applied in a similar manner. In the 14-day period specified above, the previous provisions of the Terms and Conditions shall apply (provisions that were in force before the amendment in question was made).</li>
      <li>The Operator shall perform to provide its services in the proper manner (without defects).</li>
      <li>The User has the right to lodge complaints with the Operator with regard to the services provided by the Operator or the quality of the Website, to the Operator’s address provided in the Terms and Conditions. The Operator will examine the User’s complaint and respond to it no later than within 14 days from receiving the complaint. The response will be sent to the User to their e-mail address provided on the Website.</li>
      <li>The Operator reserves the right to make the Website temporarily unavailable, especially in the case of maintenance works, repairing or expanding the Website.</li>
      <li>To the broadest extent permitted by law, the Operator holds no liability for blocking correspondence to the e-mail address provided by the User by administrators of e-mail servers and for deleting and blocking e-mail messages by the software installed on the User’s computer.</li>
      <li>To the broadest extent permitted by law, the Operator has no liability for disturbances, or breaks, in the functioning of the Website, in particular if caused by force majeure, unauthorized action of third parties or incompatibility of the Website with the User’s technical infrastructure or by factors attributable to the providers of telecommunications services.</li>
      <li>For statistical purposes and in order to ensure the highest quality of Service, the Website uses information saved by the server on the User’s end device, which are later read every time when the browser is launched (so-called cookies). The User may change the browser’s settings at any time so that it no longer accepts such files or so that it informs the User about transmission of such files. If cookie files are not accepted, it may cause difficulties in using the Website. If the User uses a browser with settings that allow for saving cookies on the User's device, it means that the User consents to the saving of such files on their device.</li>
      <li>The User is obliged to refrain from actions that might disrupt the correct functioning of the Website, and particularly from interfering with the software that ensures the functioning of the Website or with the content displayed on the Website, as well as from providing unlawful content.</li>
      <li>Disputes arising from legal relations between the User and Operator will be examined by the common court for the capital city of Warsaw. The above provision does not apply to any disputes with Lessees who are consumers, which will be examined by a court with the appropriate jurisdiction, as determined by the general provisions of the applicable law, or, if the Parties so decide, by a body selected by the Parties for amicable resolution of disputes.</li>
      <li>In cases not regulated by these Terms and Conditions, provisions of the Polish law shall apply, and in particular the provisions of the Civil Code, and in case of Lessees who are consumers, the provisions of the Act of 30 May 2014 on consumers’ rights shall also apply.</li>
    </ol>

    <br/>
    <br/>

    <h3><a name="zalacznik_1">ATTACHMENT no. 1</a></h3>
    <p><b>Schedule of Charges</b></p>

    <table>
      <tr>
        <th>Charge Type</th>
        <th>Charge Amount</th>
        <th>Comments</th>
      </tr>
      <tr>
        <td>Deduction</td>
        <td>PLN 4,00 (gross)</td>
        <td>for each cancelled hour</td>
      </tr>
      <tr>
        <td>Penalty</td>
        <td>PLN 500,00 (gross)</td>
        <td>for providing a false number of people using the hall, if it affects the lease price</td>
      </tr>
    </table>

    `,
  },
  toastify: {
    'We sent you a password reset link. Check your email!':
      'We sent you password reset link. Check your email!',
    'We sent your contact request': 'We sent your contact request',
    'Your booking time limit expired.': 'Your booking time limit expired.',
    'Your booking schedule has been changed.':
      'Your booking schedule has been changed.',
    'Your account has been deleted.': 'Your account has been deleted.',
    'Your booking has been cancelled.': 'Your booking has been cancelled.',
    'Your password has been changed.': 'Your password has been changed.',
    'Form contains some errors': 'Form contains some errors',
    'Something went wrong. Contact us to book the room.':
      'Something went wrong. Contact us to book the room.',
  },
  order: {
    Date: 'Date',
    Price: 'Price',
  },
  booking: {
    typePrivate: 'Private',
    selfEmployed: 'Self employed',
    otherBusiness: 'Other business type',
    typeCompany: 'Company',
    firstName: 'First name',
    lastName: 'Last name',
    Name: 'Name and surname',
    Phone: 'Phone number',
    Street: 'Street',
    Country: 'Country',
    StreetNumber: 'Street number',
    FlatNumber: 'Flat number',
    'ZIP Code': 'ZIP Code',
    City: 'City',
    Private: 'Private account',
    Company: 'Company',
    'Company name': 'Company name',
    'VAT Number': 'VAT Number',
    Next: 'Next',
    'You can cancel your booking within {{hours}} hours.':
      'You can cancel your booking up to {{hours}} hours before the start of reservation.',
    'You are renting the room with the following options':
      'You are renting the room with the following options',
    'Billing details': 'Billing details',
    Modify: 'Modify',
    Invoice: 'Invoice',
    Book: 'Book',
    Date: 'Date',
    rentDate: 'Book date',
    For: 'For',
    From: 'From',
    Hours: 'Hours',
    to: 'to',
    'Room details': 'Room details',
    NIP: 'NIP',
    'E-mail': 'E-mail',
    'Confirmation of the room booking':
      'Confirmation of the room booking {{room}}',
    'Booking status': 'Booking status',
    'Payment status': 'Payment status',
    'Booking number': 'Booking number',
    Localization: 'Localization',
    Room: 'Room',
    title: 'Room booking <b>{{ name }}</b>',
    step: 'step {{ step }} of 2',
    'Book as guest user': 'Book as guest user',
    'Customer data': 'Customer data',
    'Sign In': 'Sign In',
    or: 'or',
    Remember: 'Sign up and check how to pay 5zł less per 1h for 7 days!',
  },
  pricing: {
    'PRICE LIST': 'PRICE LIST',
    Hours: 'Hours',
    Capacity: 'Maximum number of people',
    'Gross price per hour': 'Gross price per hour',
  },
  bookingStatus: {
    COMPLETE: 'Confirmed',
  },
  paymentStatus: {
    PAID: 'Paid',
    NOT_PAID: 'Not paid',
    PROCESSING: 'Processing',
    WILL_BE_PAID_IN_PERSON: 'Processing',
    FAILED: 'Failed',
  },
  validator: {
    'Incorrect VAT Number': 'Incorrect VAT Number',
  },
  cookies: {
    'This website uses cookies to enhance the user experience.':
      'This website uses cookies to enhance the user experience.If you select “Accept All”, you accept this fact and consent to the processing by us this information. If you decline, we will only use the necessary files cookie. Select “Preference Settings” to view details and manage options. For more information, see our <1>privacy policy</1>.',
    button: 'I accept',
    declineButton: 'Decline',
    modifyPreferences: 'Modify preferences',
    Preferences: 'Preferences',
    'Manage preferences': 'Manage preferences',
    Essential:
      'Essential cookies - necessary for the technical performance of the website. Always checked.',
    Analysis: 'Analysis cookies.',
    readMore: 'Read more',
    readLess: 'Read less',
    'Save preferences': 'Save Preferences',
    statisticDescription: `<p>gtag.js and analytics.js set the following cookies:</p><br/>`,
    statisticPoints: `
    <p> - <b>_ga</b>: used to distinguish users</p>
    <p> - <b>_gid</b>: used to distinguish users</p>
    <p> - <b>_gat</b>: user to throttle request rate</p>
    `,
  },
  howToBook: {
    title: 'How to book',
    content: `<ol><li><p>You do not have to have an account to book a room.</p><p>However, rember that registered users have access to their account, reservations and also various marketing tasks and discounts.</p></li>
<li><p>Choose the city</p></li>
<li><p>Choose numer of people (count in the teacher)</p></li>
<li><p>Choose date and time</p><p>Rember! System is looking for a free room, it will find something faster if you put a specific range (e.g. 18:00-20:00) rather than wide range (e.g.10-23)</p></li>
<li><p>Click „Search”</p></li>
<li><p>Search results will show you rooms available to you at a chosen time. Click on the photo to choose.</p><p>Remember! If there is only one room shown in search results you need to clikc on it too.</p></li>
<li><p>You can take a look on the chosen room, check if the date and time is correct. If yes, click „Book”</p></li>
<li><p>Fill in the form with data that will appear on the payment confirmation and invoice. Choose Private or Company, check if all the data is correct. Accept our Terms of Use, click „Next”.</p></li>
<li><p>System will sum up all the information, date, time, invoice data. Check again and click „Book”</p></li>
<li><p>You will be redirected to przelewy24.pl, proceed with the payment.</p>
<p>If you resign before paying the reservation will be cancelled automatically.</p>
<p>If anything will be wrong you have about 1 hour to pay for the reservation. Please read instructions in the „your reservation is processed” email. After 1 hour the reservation will be cancelled.</p>
</li>
<li><p></p>If everyting went well you will get 4 emails: with the invoice (InFakt), with payment data(Przelewy24), with information that reservation is being Processed, with Reservation Confirmation.</li></ol>`,
  },
};
