import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AuthLayout from 'components/Auth/Layout';

import routes from 'routes';
import RegisterForm from './components/RegisterForm';

@inject('authStore', 'routingStore')
@observer
class RegisterPage extends Component {
  componentDidMount() {
    const {
      authStore: { isAuthenticated },
      routingStore: { replace },
    } = this.props;

    if (isAuthenticated) {
      replace(routes.home);
    }
  }

  render() {
    const {
      authStore: { signUp },
    } = this.props;
    return (
      <AuthLayout image="/images/backgrounds/register.jpg">
        <RegisterForm onSubmit={signUp} />
      </AuthLayout>
    );
  }
}

export default RegisterPage;
