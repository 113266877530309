import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Card, Container, NotPublishedBadge } from 'styles/globalStyles';
import Preloader from 'components/Preloader';
// import { Header, Content, PublishedDate } from 'containers/BlogPost/style';
import { Header, Content } from 'containers/BlogPost/style';
import NotFound from 'components/NotFound';
// import { formatDateFromAPI } from 'utils/datetimeHelpers';

const ProductPage = ({
  shopStore: {
    product,
    fetchProduct,
    productIsLoading,
    resetProduct,
    productHasBeenFetched,
  },
}) => {
  const params = useParams();
  useEffect(() => {
    fetchProduct(params.slug);

    return () => {
      resetProduct();
    };
  }, [params.slug]);

  if (productIsLoading || !productHasBeenFetched) {
    return <Preloader />;
  }

  if (!product || (product && !Object.keys(product).length)) {
    return <NotFound />;
  }

  return (
    <Container>
      {!product.isPublished ? <NotPublishedBadge /> : null}
      <Header bg={product.image.thumbnails.big}>
        <h1>{product.title}</h1>
      </Header>

      {/* <PublishedDate>{formatDateFromAPI(product.createdAt)}</PublishedDate> */}

      <Card>
        <Content dangerouslySetInnerHTML={{ __html: product.content }} />
      </Card>
    </Container>
  );
};

export default inject('shopStore')(observer(ProductPage));
