import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Heading, Button, Text } from 'rebass';
import Preloader from 'components/Preloader';
import MainButton from 'components/MainButton';
import moment from 'moment';
import i18n from 'i18n';
import * as Styled from './style';

const BookingSummary = inject(
  'bookingStore',
  'userStore',
)(
  observer(
    ({
      bookingStore: {
        currentBooking,
        userData,
        initialized,
        params,
        room,
        submitBooking,
        modifyBillingAddress,
        submitting,
      },
      userStore: { profile },
      t,
    }) => {
      if (!initialized) return <Preloader h={200} />;

      const from = moment(params.from, 'HH:mm');
      const to = moment(params.to === '00:00' ? '24:00' : params.to, 'HH:mm');
      const duration = to.diff(from, 'hours', true);

      return (
        <>
          {submitting && <Preloader cover />}
          <Heading
            fontSize={['18px', '18px', '18px', '22px']}
            fontWeight="medium"
          >
            {t('You are renting the room with the following options')}
          </Heading>

          <Styled.SummarySection>
            <Heading fontWeight="medium" as="h3" mb={3}>
              {t('Billing details')}:
            </Heading>

            <Styled.TextRow>{userData.billingAddress.name}</Styled.TextRow>
            <Styled.TextRow>
              {userData.billingAddress.street}&nbsp;
              {userData.billingAddress.streetNumber}
            </Styled.TextRow>
            {userData.billingAddress.flatNumber && (
              <Styled.TextRow>
                {userData.billingAddress.flatNumber}
              </Styled.TextRow>
            )}

            <Styled.TextRow>
              {userData.billingAddress.zipCode} {userData.billingAddress.city}
            </Styled.TextRow>
            {userData.billingAddress.vatNumber && (
              <Styled.TextRow>
                {t('NIP')}: {userData.billingAddress.vatNumber}
              </Styled.TextRow>
            )}
            <Styled.TextRow>{userData.email}</Styled.TextRow>
            <Styled.TextRow>{userData.phone}</Styled.TextRow>

            <Button
              type="button"
              variant="secondary"
              onClick={modifyBillingAddress}
              mt={3}
            >
              {t('Modify')}
            </Button>
          </Styled.SummarySection>

          <Styled.SummarySection>
            <Heading fontWeight="medium" as="h3" mb={3}>
              {t('Room details')}:
            </Heading>

            <Styled.TextRow>
              <Text fontWeight="medium">
                {(room[i18n.language] &&
                  room[i18n.language].name &&
                  room[i18n.language].name) ||
                  room.name}
              </Text>
            </Styled.TextRow>

            <Styled.TextRow mb={4}>
              {room.location.street}, {room.location.city}{' '}
              {room[i18n.language] &&
                room[i18n.language].additionalLocationInfo && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: room[i18n.language].additionalLocationInfo,
                    }}
                  />
                )}
            </Styled.TextRow>

            <Styled.TextRow>
              <Styled.Label>{t('Date')}</Styled.Label>
              <Styled.Value>{params.date}</Styled.Value>
            </Styled.TextRow>

            <Styled.TextRow>
              <Styled.Label>{t('Hours')}</Styled.Label>
              <Styled.Value>
                {params.from} - {params.to}
              </Styled.Value>
            </Styled.TextRow>

            <Styled.TextRow>
              <Styled.Label>{t('For')}</Styled.Label>
              <Styled.Value>
                {t('common:person', { count: parseInt(params.capacity, 10) })}
              </Styled.Value>
            </Styled.TextRow>
          </Styled.SummarySection>

          <Styled.SummaryRow>
            <Styled.CancelBooking>
              *
              {t('You can cancel your booking within {{hours}} hours.', {
                hours: profile && profile.isPremium ? 12 : 24,
              })}
            </Styled.CancelBooking>
          </Styled.SummaryRow>

          <Styled.Separator />

          <Styled.SummarySection transparent noPadding>
            <Styled.SummaryRow>
              <Styled.Label>
                <span>{duration}h</span>
              </Styled.Label>
              <Styled.Value>
                {currentBooking.priceData.price / 100}zł
              </Styled.Value>
            </Styled.SummaryRow>
          </Styled.SummarySection>

          <MainButton
            width="100%"
            type="button"
            onClick={submitBooking}
            disabled={submitting}
          >
            {t('Book')}
          </MainButton>
        </>
      );
    },
  ),
);

BookingSummary.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('booking')(BookingSummary);
