import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'styles/globalStyles';

import { Grid } from 'containers/Rooms/style';

import FoundRoomTile from 'components/FoundRoomTile';
import ToggableBox from 'components/ToggableBox';

import { imagePath } from 'utils/imagePathHelper';
import routes from 'routes';

const CountrySection = ({ isAuthenticated, section }) => {
  const cities = useMemo(
    () => Object.keys(section).sort((a, b) => a.localeCompare(b)),
    [section],
  );

  return (
    <Card noPadding>
      {cities.map((city, index) => {
        const locations = section[city];

        return (
          <ToggableBox key={`city-${index}`} title={city} subItem>
            {locations.map(location => (
              <ToggableBox key={location._id} title={location.street} subItem>
                <Grid>
                  {location.rooms.map(room => (
                    <FoundRoomTile
                      key={`room_${room._id}`}
                      image={imagePath(
                        room.images[room.primaryImageIndex].thumbnails.big,
                      )}
                      title={room.name}
                      size={room.surfaceArea}
                      pricing={{ price: room.pricing[0].grossPrice }}
                      to={`${routes.description(room.slug)}?info=1`}
                      isAuthenticated={isAuthenticated}
                      showPrice={false}
                    />
                  ))}
                </Grid>
              </ToggableBox>
            ))}
          </ToggableBox>
        );
      })}
    </Card>
  );
};

CountrySection.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default memo(CountrySection);
