import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { isValidElementType } from 'react-is';

import routes from 'routes';

const ProtectedRoute = inject('authStore')(
  observer(
    ({
      component: Component,
      authStore: { isAuthenticated },
      pageTitle,
      ...rest
    }) => (
      <Route
        {...rest}
        render={props =>
          isAuthenticated === true ? (
            <Component {...props} pageTitle={pageTitle} />
          ) : (
            <Redirect to={routes.auth.signIn} />
          )
        }
      />
    ),
  ),
);

ProtectedRoute.propTypes = {
  // eslint-disable-next-line
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        `Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`,
      );
    }
  },
};

export default ProtectedRoute;
