import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { Link } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

export const FullHeightFlex = styled(Flex)`
  height: 100vh;
  width: 100%;
`;

export const MainImg = styled.img`
  height: 100%;
  width: 43.22%;
  object-fit: cover;
  object-position: -30px 0;

  @media ${breakpoint.xs} {
    display: none;
  }
  @media ${breakpoint.md} {
    display: block;
  }

  @media ${breakpoint.xxl} {
    width: 40%;
    max-width: 916px;
  }
`;
export const FormBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 64em) {
    align-items: unset;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const MainName = styled(Link)`
  font-weight: 900;
  position: absolute;
  padding-left: 30px;
  text-decoration: none;

  @media ${breakpoint.xs} {
    color: #bfbfbf;
    font-size: 16px;
    line-height: 28px;
    top: 26px;
    left: 0;
  }
  @media ${breakpoint.md} {
    top: 30px;
    left: 30px;
    font-size: 34px;
    line-height: 40px;
    color: #ffffff;
  }
`;

export const ActionWrapper = styled.div`
  display: inline-block;
  padding-top: 38px;
`;

export const ActionText = styled.div`
  font-size: 16px;
  color: #000000;
`;

export const ForgotPasswordText = styled.div`
  @media ${breakpoint.xs} {
    font-size: 13px;
    line-height: 18px;
    padding-top: 8px;
    padding-bottom: 22px;
  }
  @media ${breakpoint.md} {
    font-size: 16px;
    line-height: 21px;
    padding-top: 13px;
    padding-bottom: 37px;
  }
`;

export const ActionLink = styled(({ positionabs, ...props }) => (
  <Link {...props} />
))`
  color: #d9a248;
  text-decoration: none;
  font-weight: ${props => (props.positionabs ? '500' : '900')};
  cursor: pointer;
  position: ${props => (props.positionabs ? 'absolute' : '')};
  right: ${props => (props.positionabs ? '0' : 'auto')};
  z-index: 10;
`;
