import React from 'react';
// import { Flex, Box, Image, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Container, HTMLContent, MainTitle } from 'styles/globalStyles';
// import { LineSeparator, Quote, QI } from './style';

const HowToBook = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainTitle>{t('howToBook:title')}</MainTitle>
      <HTMLContent
        dangerouslySetInnerHTML={{ __html: t('howToBook:content') }}
      />
    </Container>
  );
};

export default HowToBook;
