import React from 'react';
import { withRouter } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import reset from 'emotion-reset';
import { useTheme } from 'emotion-theming';
import { Box, Heading } from 'rebass';

import { breakpoint } from 'styles/breakpoints';

const GlobalStyle = ({
  history: {
    location: { pathname },
  },
}) => {
  const isHomepage = pathname === '/';
  const isAuthPage = pathname.indexOf('auth') !== -1;
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${reset}

        @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=latin-ext');

        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        body {
          margin: 0;
          font-family: 'Roboto', sans-serif;
          background: ${theme.colors.bodyBackground};
          position: relative;
          /* padding-bottom: ${isAuthPage ? 0 : 75}px; */
          min-height: 100vh;

          ${isHomepage &&
            css`
              background: #3E291C url('/images/backgrounds/body-lights.png')
                no-repeat 50% 50% / cover;
              background-attachment: fixed;

              &:before {
                content: '';
                display: block;
                position: fixed;
                width: 100%;
                height: 100%;

                max-width: 1761px;
                max-height: 979px;
                background: transparent
                  url('/images/backgrounds/body-shapes.svg') no-repeat 50% 50% /
                  310%;
                left: 50%;
                top: 47%;
                transform: translate(-50%, -50%);
                z-index: 2;

                @media ${breakpoint.md} {
                  background-size: cover;
                }

                @media screen and (max-height: 1000px) {
                  position: absolute;
                }
              }

              &:after {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
                background: #000
                z-index: 1;
              }
            `}

          /* @media ${breakpoint.xs} {
            padding-top: ${isHomepage ? 0 : 72}px;
          } */

          @media ${breakpoint.md} {
            padding-top: 0;
          }
        }
        html {
          box-sizing: border-box;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        b {
          font-weight: 500;
        }

        #root {
          /* min-height: ${isAuthPage ? '100vh' : 'calc(100vh - 75px)'}; */
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          @media ${breakpoint.md} {
            ${isHomepage && 'justify-content: center'};
          }
        }

        /* Scroll Bar */
        select__menu-list::-webkit-scrollbar {
          width: 4px;
          height: 0px;
        }
        select__menu-list::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        select__menu-list::-webkit-scrollbar-thumb {
          background: #888;
        }
        select__menu-list::-webkit-scrollbar-thumb:hover {
          background: #573b27;
        }

        .slick-slider,
        .slick-list,
        .slick-track {
          height: 100%;
        }

        .slick-slide {
          > div {
            height: 100%;
          }
        }

        .slick-arrow {
          width: 60px;
          height: 60px;
          background-color: rgba(255,255,255,.8);
          opacity: .5;
          transition: opacity .3s ease-in-out;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-size: 20px;
            background-repeat: no-repeat;
            display: block;
            transition: background-position .3s ease-in-out;
          }

          &:active, &:focus, &:hover {
            background-color: rgba(255,255,255,.8);
          }

          &:hover {
            opacity: 1;
          }
        }
        .slick-prev {
          left: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          &:before {
            background-image: url('/images/icons/arrow-left.svg');
            background-position: right 49% top 50%;
          }

          &:hover {
            &:before {
              background-position: right 70% top 50%;
            }
          }
        }
        .slick-next {
          right: 0;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          &:before {
            background-image: url('/images/icons/arrow-right.svg');
            background-position: left 49% top 50%;
          }

          &:hover {
            &:before {
              background-position: left 70% top 50%;
            }
          }
        }

        .slick-next,
        .slick-prev {
          z-index: 2;
        }

        .classic-form-field-wrapper {
          .react-select-container {
            .react-select__control {
              /* border: 1px  */
              box-shadow: inset 0px 2px 4px rgb(0 0 0 / 15%);
              background-color: #fafafa;
              border: 2px #212121 solid;
              border-style: inset;
              border-radius: 3px;
            }
          }
        }

        .fade-enter {
          opacity: 0;
        }
        .fade-enter-active {
          opacity: 1;
          transition: opacity .3s;
        }
        .fade-exit {
          opacity: 1;
          transition: opacity .3s;
        }
        .fade-exit-active {
          opacity: 0;
          transition: opacity .3s;
        }

        .slide-enter {
          opacity: 0;
          max-height: 0;
        }
        .slide-enter-active {
          opacity: 1;
          max-height: 2000px;
          transition: opacity .3s, max-height .5s;
          transform-origin: center top;
        }
        .slide-exit {
          opacity: 1;
          max-height: 2000px;
          transition: opacity .3s, max-height .5s;
          transform-origin: center top;
        }
        .slide-exit-active {
          opacity: 0;
          max-height: 0;
          transition: opacity .3s, max-height .5s;
          transform-origin: center top;
        }

        .scale-enter {
          opacity: 0;
          transform: scale(0) translate(-50%, -50%) !important;
          transform-origin: left top;
        }
        .scale-enter-active {
          opacity: 1;
          transform: scale(1) translate(-50%, -50%) !important;
          transition: opacity .3s, transform .5s;
          transform-origin: left top;
        }
        .scale-enter-done {
          transform: scale(1) translate(-50%, -50%) !important;
        }
        .scale-exit {
          transform: scale(0) translate(-50%, -50%) !important;
          transition: opacity .3s, transform .5s;
          opacity: 1;
          transform-origin: left top;
        }
        .scale-exit-active {
          opacity: 0;
          transform: scale(0) translate(-50%, -50%) !important;
          transition: opacity .3s, transform .5s;
          transform-origin: left top;
        }

        // The main container element
        .Collapsible {
          background-color: #f4b78a;
        }


        //The content within the collaspable area
        .Collapsible__contentInner {
          background-color: white;
          padding: 10px;
          border: 1px solid #f4b78a;
          border-top: 0;

          p {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        //The link which when clicked opens the collapsable area
        .Collapsible__trigger {
          display: block;
          font-weight: 400;
          text-decoration: none;
          position: relative;
          margin-left: -1px;
          margin-right: -1px;
          padding: 10px;
          background-color: #f4b78a;
          color: white;
          cursor: pointer;
          margin-top: 20px;


          &:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "+";
            position: absolute;
            right: 10px;
            top: 10px;
            display: block;
            transition: transform 300ms;
          }

          &.is-open {
            &:after {
              content: "-";
              transform: rotateZ(180deg);
            }
          }

          &.is-disabled {
            opacity: 0.5;
            background-color: grey;
          }
        }

        .CustomTriggerCSS {
          background-color: lightcoral;
          transition: background-color 200ms ease;
          
          
        }

        .CustomTriggerCSS--open {
          background-color: darkslateblue;
        }

        .Collapsible__custom-sibling {
          padding: 5px;
          font-size: 12px;
          background-color: #CBB700;
          color: black;
        }

      `}
    />
  );
};

export default withRouter(GlobalStyle);

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 15px;

  @media ${breakpoint.md} {
    padding: 0 30px;
  }
`;

export const MainTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  padding-bottom: 30px;
  text-align: left;

  @media ${breakpoint.xs} {
    padding-top: 30px;
  }
  @media ${breakpoint.md} {
    padding-top: 0;
  }
`;

export const SecondaryTitle = styled.div`
  font-size: 22px;
  line-height: 29px;
  color: #000000;
  padding-bottom: 17px;
  text-align: left;

  @media ${breakpoint.xs} {
    padding-top: 30px;
  }
  @media ${breakpoint.md} {
    padding-top: 0;
  }
`;
export const LineSeparator = styled.div`
  border: 1px solid #dcdcdc;
  width: 100%;
  margin-top: ${props => props.marginTop};
`;
export const AuthForm = styled.form`
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 486px;
`;

export const ThinForm = styled.form`
  width: 100%;
  max-width: 486px;
  margin: 0 auto;
`;

export const ContactForm = styled(ThinForm)`
  margin: unset;
  max-width: 772px;
`;

export const PreWrappedBox = styled(Box)`
  white-space: pre-wrap;
`;

export const Surface = styled.div`
  position: absolute;
  right: ${props => (props.bottom ? 25 : 0)}px;
  bottom: ${props => (props.bottom ? '25px' : 'auto')};
  font-size: 12px;
  line-height: 14px;
  color: #000;
  top: ${props => !props.bottom && '-10px'};
  sup {
    font-size: 10px;
    vertical-align: super;
  }

  @media ${breakpoint.md} {
    font-size: 22px;
    line-height: 26px;
    sup {
      font-size: 12px;
    }
  }
`;

export const SurfaceBorder = styled.div`
  position: absolute;
  bottom: -2px;
  right: 7px;
  width: calc(100% - 7px);
  border-bottom: 1px #000 solid;
  &:before {
    position: absolute;
    right: 0;
    top: -1px;
    content: '';
    display: block;
    border-top: 1px #000 solid;
    width: 10px;
  }

  @media ${breakpoint.md} {
    &:before {
      width: 19px;
    }
  }
`;

export const RoomHeaderTitle = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  display: inline-block;
  max-width: 50%;
  padding: 15px;
  text-align: left;
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  z-index: 3;
  top: ${props => props.topPos};

  @media ${breakpoint.xs} {
    padding: 15px;
    max-width: 50%;
  }
  @media ${breakpoint.md} {
    max-width: 30%;
    padding: 40px;
  }
`;

export const SimilarRoomHeaderTitle = styled(RoomHeaderTitle)`
  @media ${breakpoint.md} {
    max-width: 100%;
    padding: 40px;
  }
`;

export const Header = styled(Heading)`
  text-transform: uppercase;
  font-size: ${props => {
    let sizeIndex = 0;
    switch (props.as) {
      case 'h1':
        sizeIndex = 9;
        break;
      default:
        sizeIndex = 1;
        break;
    }
    return `${props.theme.fontSizes[sizeIndex]}px`;
  }};
`;

export const StarText = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  margin-left: 24px;
  margin-top: 50px;
  @media ${breakpoint.md} {
    font-size: 15px;
  }
`;

export const AdditionalInfo = styled(StarText)`
  margin-bottom: 8px;
  line-height: unset;
  margin-top: unset;

  strong {
    font-weight: 700;
  }

  > p {
    & + p {
      margin-top: 10px;
    }
  }

  @media ${breakpoint.md} {
    margin-bottom: ${props => (props.similarTile ? '8px' : '16px')};
    font-size: ${props => (props.similarTile ? '16px' : '18px')};
    line-height: ${props => (props.similarTile ? '20px' : '24px')};
  }

  ${props =>
    props.similarTile &&
    `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      `}
`;

export const Card = styled.div`
  background-color: #fff;
  padding: ${({ noPadding }) => (noPadding ? 0 : '20px 30px')};
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  display: block;

  @media ${breakpoint.md} {
    padding: ${({ noPadding }) => (noPadding ? 0 : '40px 45px')};
  }

  & + & {
    margin-top: 30px;
  }
`;

export const NotPublishedBadge = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff url('/images/icons/alert.svg') no-repeat 50% 50% / 24px;
  z-index: 5;
  border-radius: 18px;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;

  &:after {
    white-space: nowrap;
    content: 'Nieopublikowane';
    padding-left: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-transform: uppercase;
    text-shadow: 0 0 5px rgba(255, 255, 255, 1);
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

export const HTMLContent = styled.div`
  ol {
    list-style: decimal;
    padding-left: 30px;

    > li {
      margin-top: 20px;
      line-height: 20px;

      p + p {
        margin-top: 10px;
      }
    }
  }
  a {
    color: #575757;
    text-decoration: none;
  }
`;
