import { action, extendObservable } from 'mobx';
import { FORM_ERROR } from 'final-form';

import { API, APIRoutes } from 'api';
import { routingStore } from 'stores';
import routes from 'routes';
import i18n from 'i18n';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  subject: null,
};

export class EmailStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  resetSubject = () => {
    this.subject = null;
  };

  @action
  askForRoomInYourCity = () => {
    this.subject = i18n.t("forms:I can't find room in my city");
    routingStore.push(routes.contact);
  };

  @action
  // eslint-disable-next-line consistent-return
  requestContact = async formData => {
    this.isLoading = true;
    try {
      await API.post(APIRoutes.emails.contact, formData);
      toast.info(i18n.t('toastify:We sent your contact request'));
      routingStore.push(routes.home);
    } catch (error) {
      if (Object.hasOwnProperty.call(error, 'errors')) {
        return error.errors;
      }
      return { [FORM_ERROR]: i18n.t('errors:Something went wrong') };
    } finally {
      this.isLoading = false;
    }
  };
}

export default new EmailStore();
