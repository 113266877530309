import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const Location = styled.div`
  margin-bottom: 30px;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;

  @media screen and ${breakpoint.md} {
    padding: 40px;
  }
`;
