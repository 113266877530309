import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Heading } from 'rebass';
import { formatDateFromAPI } from 'utils/datetimeHelpers';
import i18n from 'i18n';

import Layout from './Layout';

import * as Styled from './style';

@inject('bookingStore', 'userStore')
@observer
class BookingComplete extends Component {
  componentDidMount = () => {
    const {
      match: { params },
      bookingStore: { room, fetchRoom, fetchBookingSummary },
    } = this.props;

    if (isEmpty(room)) {
      fetchRoom(params.slug);
    }
    fetchBookingSummary();
  };

  componentDidUpdate = prevProps => {
    const {
      location: { search },
      bookingStore: { handleQueryParamsChange },
    } = this.props;

    if (prevProps.location.search !== search) {
      handleQueryParamsChange();
    }
  };

  render() {
    const {
      t,
      bookingStore: { room, order },
      userStore: { profile },
    } = this.props;

    let { name } = room;
    if (room[i18n.language] && room[i18n.language].name) {
      name = room[i18n.language].name;
    }

    return (
      <Layout complete>
        <Heading as="h1" fontSize={5}>
          {room.name && t('Confirmation of the room booking', { room: name })}
        </Heading>
        {!isEmpty(order) && (
          <>
            <Styled.SummarySection>
              <Styled.TextRow>
                {t('Booking status')}:{' '}
                <b>{t(`bookingStatus:${order.state}`)}</b>
              </Styled.TextRow>
              <Styled.TextRow>
                {t('Payment status')}:{' '}
                <b>{t(`paymentStatus:${order.paymentState}`)}</b>
              </Styled.TextRow>
            </Styled.SummarySection>

            <Styled.SummarySection>
              <Heading fontWeight="medium" as="h3" mb={3}>
                {t('Room details')}:
              </Heading>

              <Styled.TextRow>
                <Styled.Label>{t('Rent date')}</Styled.Label>
                <Styled.Value>
                  {formatDateFromAPI(order.bookings[0].from)}
                </Styled.Value>
              </Styled.TextRow>

              <Styled.TextRow>
                <Styled.Label>{t('For')}</Styled.Label>
                <Styled.Value>
                  {t('common:person', {
                    count: parseInt(order.bookings[0].numberOfUsers, 10),
                  })}
                </Styled.Value>
              </Styled.TextRow>

              <Styled.TextRow>
                <Styled.Label>{t('From')}</Styled.Label>
                <Styled.Value>
                  {formatDateFromAPI(order.bookings[0].from, 'HH:mm')}{' '}
                  <span>{t('to')}</span>{' '}
                  {formatDateFromAPI(order.bookings[0].to, 'HH:mm')}
                </Styled.Value>
              </Styled.TextRow>
            </Styled.SummarySection>

            <Styled.SummarySection>
              <Heading fontWeight="medium" as="h3" mb={3}>
                {t('Billing details')}:
              </Heading>

              <Styled.TextRow>{order.billingAddress.name}</Styled.TextRow>
              <Styled.TextRow>
                {order.billingAddress.street}&nbsp;
                {order.billingAddress.streetNumber}
              </Styled.TextRow>
              {order.billingAddress.flatNumber && (
                <Styled.TextRow>
                  {order.billingAddress.flatNumber}
                </Styled.TextRow>
              )}
              <Styled.TextRow>
                {order.billingAddress.zipCode} {order.billingAddress.city}
              </Styled.TextRow>
              {order.billingAddress.vatNumber && (
                <Styled.TextRow>
                  {t('NIP')}: {order.billingAddress.vatNumber}
                </Styled.TextRow>
              )}
              <Styled.TextRow>{order.email}</Styled.TextRow>
              <Styled.TextRow>{order.phone}</Styled.TextRow>
            </Styled.SummarySection>

            <Styled.SummaryRow>
              <Styled.CancelBooking>
                *
                {t('You can cancel your booking within {{hours}} hours.', {
                  hours: profile && profile.isPremium ? 12 : 24,
                })}
              </Styled.CancelBooking>
            </Styled.SummaryRow>
          </>
        )}
      </Layout>
    );
  }
}

BookingComplete.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const enhance = compose(withTranslation('booking'), withRouter);

export default enhance(BookingComplete);
