import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form, Field } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import MainButton from 'components/MainButton';
import Preloader from 'components/Preloader';
import { required } from 'utils/formValidators';
import FormSelect from 'components/forms/FormSelect';
import { premiumDurationOptions } from 'utils/staticValues';
import Checkbox from 'components/Checkbox';
import { Box } from 'rebass';
import AddressForm from '../AddressForm';
import { FormTitle, Description, FieldWrapper } from './style';

const BuyModal = inject('promotionsStore')(
  observer(
    ({
      billingAddress,
      promotionsStore: { isLoading, buyPremium },
      selectedPlan,
    }) => {
      const { t } = useTranslation('account');
      const history = useHistory();

      useEffect(() => () => history.replace({ state: {} }), []);

      return (
        <div>
          <FormTitle
            as="h3"
            fontWeight="medium"
            fontSize={['16px', '16px', '16px', '24px']}
          >
            {t('Buy PREMIUM account')}
          </FormTitle>

          <Description>{t('buy.premium.modal.description')}</Description>

          <Form
            onSubmit={async v => {
              const { billingAddress: address, option } = v;
              await buyPremium(address, option);
            }}
            initialValues={{ billingAddress, option: selectedPlan }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {(submitting || isLoading) && <Preloader cover />}
                <AddressForm
                  showTypeSelector
                  accountType={values.billingAddress?.type}
                />
                <FieldWrapper
                  width="40%"
                  className="classic-form-field-wrapper"
                >
                  <Box as="span" sx={{ marginBottom: '5px' }}>
                    {t('dropdowns:Choose plan')}
                  </Box>
                  <Field
                    fullWidth
                    height={30}
                    name="option"
                    placeholder={t('dropdowns:Premium duration')}
                    component={FormSelect}
                    validate={required}
                    variant="primary"
                    options={premiumDurationOptions}
                  />
                </FieldWrapper>
                <Field
                  label={
                    <Trans i18nKey="forms:termsAndConditions">
                      Creating an account means you’re okay with our{' '}
                      <RouterLink to="/regulations">
                        Terms of Service
                      </RouterLink>
                      and
                      <RouterLink to="/privacy-policy">
                        Privacy Policy
                      </RouterLink>
                      .
                    </Trans>
                  }
                  name="terms"
                  type="checkbox"
                  component={Checkbox}
                  validate={required}
                />
                <Field
                  label={t('promotions:buy.promotion.modal.statement')}
                  name="statement"
                  type="checkbox"
                  component={Checkbox}
                  validate={required}
                />
                <MainButton
                  width="100%"
                  my={15}
                  type="submit"
                  disabled={submitting}
                >
                  {t('Buy')}
                </MainButton>
              </form>
            )}
          />
        </div>
      );
    },
  ),
);

BuyModal.defaultProps = {
  billingAddress: {},
};

BuyModal.propTypes = {
  billingAddress: PropTypes.shape({}),
};

export default BuyModal;
