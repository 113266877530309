import styled from '@emotion/styled';

export const Header = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  background: url(${({ bg }) => `${process.env.REACT_APP_API_URL}/${bg}`})
    no-repeat 50% 50% / cover;
  display: flex;
  align-items: flex-end;
  padding: 32px;
  margin-bottom: 30px;

  h1 {
    font-size: 50px;
    font-weight: 200;
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
`;

export const PublishedDate = styled.div`
  text-align: right;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  line-height: 24px;

  p {
    margin-bottom: 15px;
  }

  ol,
  ul {
    margin-bottom: 15px;
    padding-left: 16px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  a {
    color: ${({ theme }) => theme.colors.gold};
  }
`;
