import { extendObservable, action } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { API, APIRoutes } from 'api';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';

const initialState = {
  isLoading: true,
  error: null,
  addresses: [],
  usedAddressesforDropdown: {},
  usedAddresses: [],
};

export class AddressStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  fetchAddressesForDropdown = async () => {
    try {
      if (
        isEmpty(this.usedAddressesforDropdown) &&
        isEmpty(this.usedAddresses)
      ) {
        this.isLoading = true;
        const {
          data: { items },
        } = await API.get(APIRoutes.dropdowns.cities);

        this.usedAddresses = orderBy(items, item => deburr(item));
        this.usedAddressesforDropdown = this.usedAddresses.map(el => ({
          value: el,
          label: el,
        }));
      }
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new AddressStore();
