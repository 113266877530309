import React from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';
import { Link } from 'react-router-dom';
import { Flex } from 'rebass';

export const QuestionText = styled(({ textcolor, ...props }) => (
  <Link {...props} />
))`
  font-size: 14px;
  text-align: right;
  color: ${props => props.textcolor};
  cursor: pointer;
  text-decoration: none;

  & + a {
    margin-left: 10px;
  }
`;

export const SearchRight = styled.div`
  flex: 1 1 auto;
  @media (max-width: 1000px) {
    text-align: left;
    margin-top: 10px;
  }

  @media ${breakpoint.xs} {
    text-align: center;

    a {
      display: block;
      text-align: center;
    }
  }

  @media ${breakpoint.md} {
    text-align: right;

    a {
      text-align: right;
    }
  }
`;

export const SearchWrapper = styled(Flex)`
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;
