import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const Row = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: 10px;
  }
`;

export const Title = styled.div`
  font-weight: normal;
  text-align: left;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  padding-bottom: 30px;

  @media ${breakpoint.xs} {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 15px;
  }
`;
