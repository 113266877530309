import queryString from 'query-string';

export default {
  auth: {
    signIn: '/auth/sign-in',
    register: '/auth/register',
    forgotPassword: '/auth/forgot-password',
    resetPassword: token =>
      token ? `/auth/reset/${token}` : '/auth/reset/:token',
  },
  home: '/',
  blog: '/blog',
  blogPost: slug => (slug ? `/blog/${slug}` : '/blog/:slug'),
  aboutUs: '/about-us',
  investorRelations: '/investor-relations',
  howToBook: '/how-to-book',
  contact: '/contact',
  percent: '/percent',
  shop: '/shop',
  product: slug => (slug ? `/shop/${slug}` : '/shop/:slug'),
  account: '/account',
  unsubscribe: '/unsubscribe',
  privacyPolicy: '/privacy-policy',
  regulations: '/regulations',
  premium: '/premium',
  promotions: '/promotions',
  rooms: '/rooms',
  description: roomId => (roomId ? `/room/${roomId}` : '/room/:roomId'),
  search: params =>
    params ? `/search?${queryString.stringify(params)}` : '/search',
  booking: {
    process: (slug, params) =>
      slug ? `/book/${slug}?${queryString.stringify(params)}` : '/book/:slug',
    summary: (slug, params) =>
      slug
        ? `/book/${slug}/complete?${queryString.stringify(params)}`
        : '/book/:slug/complete',
  },
};
