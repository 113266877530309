import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { MainTitle, SecondaryTitle } from 'styles/globalStyles';
import { withTranslation } from 'react-i18next';
import * as Styled from './style';
import FormContact from './components/ContactForm';
import ContactInfoPreWrappedBox from './components/ContactInfoPreWrappedBox';

const ContactPage = inject('emailStore')(
  observer(({ emailStore: { requestContact, subject, resetSubject }, t }) => {
    useEffect(() => {
      return () => {
        resetSubject();
      };
    }, []);

    return (
      <Styled.PageWrapper>
        <Styled.FormContentWrapper>
          <MainTitle>{t('titles:Contact')}</MainTitle>
          <Styled.Wrapper>
            <Styled.Row>
              <Styled.LeftColumn>
                <FormContact onSubmit={requestContact} subject={subject} />
              </Styled.LeftColumn>
              <Styled.VerticalLineSeparator></Styled.VerticalLineSeparator>
              <Styled.RightColumn>
                <SecondaryTitle>{t('forms:callUs')}</SecondaryTitle>
                <ContactInfoPreWrappedBox
                  dangerouslySetInnerHTML={{ __html: t('contactInfo:content') }}
                />
              </Styled.RightColumn>
            </Styled.Row>
          </Styled.Wrapper>
        </Styled.FormContentWrapper>
      </Styled.PageWrapper>
    );
  }),
);

ContactPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ContactPage);
