import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Flex, Box } from 'rebass';

import { breakpoint } from 'styles/breakpoints';

export const Row = styled(Flex)`
  display: flex;
  width: 100%;

  @media ${breakpoint.xs} {
    height: auto;
    flex-direction: column;
    box-shadow: none;
  }

  @media ${breakpoint.md} {
    flex-direction: row;
    height: 70px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    flex-direction: row;
  }
`;

export const Form = styled.form`
  max-width: 770px;
  margin: 0 auto;

  button {
    width: 100%;
  }

  @media ${breakpoint.xs} {
    width: 100%;
  }
  @media ${breakpoint.md} {
    width: 100%;

    button {
      width: auto;
    }
  }

  .form-error {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
`;

export const InputWrapper = styled(Box)`
  @media ${breakpoint.xs} {
    flex: 1;
    width: 100%;
    /* height: 45px; */
    margin-bottom: 10px !important;
  }

  @media ${breakpoint.md} {
    height: 100%;
    width: unset;
    border-right: ${props => (props.borderSeparator ? '1px solid #D3D7DC' : 0)};
  }
`;

export const MobileSelector = styled.button`
  display: flex;
  width: 100%;
  border: none;
  font-size: 16px;
  border-radius: 3px;
  padding: 15px 11px;
  background: #fff url('/images/icons/select.svg') no-repeat right 11px top 50% /
    24px;
  align-items: center;
`;

export const MobileSelectorLabel = styled.span`
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 11px;
`;

export const FullscreenSelector = styled('div', {
  shouldForwardProp: prop => !['isMobileIOS'].includes(prop),
})`
  height: ${props => (props.isMobileIOS ? 'calc(100vh - 80px)' : '100vh')};
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

export const FullScreenSelectorHeader = styled.div`
  display: flex;
  padding: 20px 15px;
  align-items: center;
  background-color: #3e291c;
  color: #fff;

  h3 {
    font-size: 16px;
  }

  button {
    border: none;
    background-color: transparent;
    margin: 0 0 0 auto;
    padding: 0;

    img {
      display: block;
      width: 18px;
      height: 18px;
    }
  }
`;

export const FullscreenSelectorContent = styled.div`
  margin: 0;
  flex: 1;
  overflow-y: auto;
`;

export const FullscreenSelectorItem = styled.button`
  width: 100%;
  text-align: left;
  border: none;
  border-top: 1px #d5d5d5 solid;
  background-color: transparent;
  font-size: 16px;
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;

  &:last-of-type {
    border-bottom: 1px #d5d5d5 solid;
  }

  ${({ active }) =>
    active
      ? css`
          background-color: #f7eee8;
        `
      : null}
`;
