import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as Styled from './style';

const FoundRoomTile = ({
  t,
  image,
  title,
  street,
  pricing,
  size,
  to,
  showPrice,
}) => {
  return (
    <Styled.Card to={to}>
      <Styled.Container image={image}>
        <Styled.RoomName>{title}</Styled.RoomName>
        <Styled.RoomCapacity>{street}</Styled.RoomCapacity>
        <Styled.RoomCapacity>
          {size} m
          <sup style={{ fontSize: 'xx-small', verticalAlign: 'super' }}>2</sup>
        </Styled.RoomCapacity>
        {showPrice ? (
          <Styled.RoomPrice>
            <div>{t('Price')}</div> {pricing.price / 100}zł
          </Styled.RoomPrice>
        ) : null}
      </Styled.Container>
    </Styled.Card>
  );
};

FoundRoomTile.propTypes = {
  t: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  street: PropTypes.string,
  pricing: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  showPrice: PropTypes.bool,
};

FoundRoomTile.defaultProps = {
  showPrice: true,
  street: null,
};

export default withTranslation()(FoundRoomTile);
