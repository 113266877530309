import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button as RawButton } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

const StyledButton = styled(RawButton)`
  text-shadow: none;
  font-style: normal;
  font-weight: 900;
  color: #ffffff;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
  background: linear-gradient(
    180deg,
    #f7e8a7 0%,
    #967032 45.56%,
    #997732 59.26%,
    #c1a542 100%
  );
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  display: inline-block;
  min-width: 189px;
  align-items: center;
  text-align: center;
  position: relative;

  &:focus {
    outline: none;
  }
  &:hover {
    transform: translateX(2px) translateY(2px);
  }
  @media ${breakpoint.xs} {
    min-width: 130px;
    padding: 12px 0;
    font-size: 18px;
  }
  @media ${breakpoint.md} {
    min-width: 130px;
    font-size: 18px;
    padding: ${props => (props.size === 'big' ? '22px 60px' : '10px 35px')};
    font-size: 22px;
  }
`;

const Button = ({ children, size, ...props }) => (
  <StyledButton size={size} {...props}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  size: 'big',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['big', 'normal']),
};

export default Button;
