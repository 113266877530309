import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Container } from 'styles/globalStyles';
import Preloader from 'components/Preloader';
import CountrySection from 'containers/Rooms/components/CountrySection';

const RoomsListPage = ({
  authStore: { isAuthenticated },
  roomsStore: {
    fetchRoomsGrouppedByAddress,
    reset,
    isLoading,
    initialized,
    roomsByAddress,
  },
}) => {
  useEffect(() => {
    fetchRoomsGrouppedByAddress();

    return () => {
      reset();
    };
  }, []);

  if (isLoading || !initialized) {
    return <Preloader />;
  }

  return (
    <Container>
      {Object.keys(roomsByAddress).map(countryCode => (
        <CountrySection
          key={`country-section_${countryCode}`}
          section={roomsByAddress[countryCode]}
          isAuthenticated={isAuthenticated}
        />
      ))}
    </Container>
  );
};

RoomsListPage.propTypes = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  roomsStore: PropTypes.shape({
    fetchRoomsGrouppedByAddress: PropTypes.func,
    reset: PropTypes.func,
    isLoading: PropTypes.bool,
    initialized: PropTypes.bool,
    roomsByAddress: PropTypes.object,
  }).isRequired,
};

export default inject('authStore', 'roomsStore')(observer(RoomsListPage));
