import styled from '@emotion/styled';
import { Heading } from 'rebass';
import { breakpoint } from 'styles/breakpoints';

export const ImageBanner = styled.div`
  background-image: url('${props => props.img}');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 192px;
  text-align: left;

  @media ${breakpoint.xs} {
    height: 192px;
    margin-top: 15px;

    .slick-next,
    .slick-prev {
      width: 20px;
      height: 20px;
      &:before {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 640px) and (max-width: 768px) {
    height: 350px;
  }
  @media ${breakpoint.md} {
    height: 594px;
    margin-top: 0;
    .slick-arrow {
      width: 60px;
      height: 60px;
    }
  }

  ${props =>
    props.relative
      ? `
    height: 100% !important;
    width: 100% !important;
  `
      : null}
`;

export const Title = styled(Heading)`
  font-size: 22px;
  font-weight: 900;

  @media ${breakpoint.xs} {
    font-size: 22px;
  }
  @media ${breakpoint.md} {
    font-size: 48px;
  }
`;

export const Price = styled.div`
  color: #b67c1e;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;

  @media ${breakpoint.xs} {
    margin-top: 10px;
    font-size: 14px;
  }
  @media ${breakpoint.md} {
    margin-top: 60px;
    font-size: 30px;
  }
`;

export const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
`;
