import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import { Cover, Wrapper, Content, Close } from './style';

const Modal = ({ opened, close, children }) => {
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    if (opened) {
      window.addEventListener('keydown', closeOnESC);
    }
  }, [opened]);

  const closeHandler = () => {
    setVisibility(false);
    window.removeEventListener('keydown', closeOnESC);
  };

  const closeOnESC = ev => {
    if (ev.which === 27) {
      closeHandler();
    }
  };

  return ReactDOM.createPortal(
    <>
      <CSSTransition
        in={visible}
        classNames="scale"
        unmountOnExit
        timeout={420}
        onExited={close}
      >
        <Wrapper>
          <Close onClick={() => setVisibility(false)} />
          <Content>{cloneElement(children, { close: closeHandler })}</Content>
        </Wrapper>
      </CSSTransition>
      <CSSTransition
        in={opened}
        timeout={420}
        classNames="fade"
        unmountOnExit
        onEnter={() => setVisibility(true)}
      >
        <Cover onClick={closeHandler} />
      </CSSTransition>
    </>,
    document.body,
  );
};

Modal.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Modal;
