import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Heading } from 'rebass';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import MainButton from 'components/MainButton';
import routes from 'routes';

const Wrapper = styled.div`
  text-align: center;
  background: url('/images/backgrounds/404.jpg') no-repeat 50% 50% / cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

const Box = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 40px 100px;
  backdrop-filter: blur(5px);
  border-radius: 4px;
`;

const NotFound = ({ message, t, history }) => (
  <Wrapper>
    <Box>
      <Heading as="h1" fontSize="100px">
        404
      </Heading>
      <Heading as="h2" fontSize="30px" sx={{ textTransform: 'uppercase' }}>
        {message || t('Not found')}
      </Heading>
    </Box>
    <MainButton mt={30} onClick={() => history.push(routes.home)}>
      {t('buttons:Back to the homepage')}
    </MainButton>
  </Wrapper>
);

NotFound.defaultProps = {
  message: null,
};
NotFound.propTypes = {
  message: PropTypes.string,
};

const enhance = compose(withTranslation(), withRouter);

export default enhance(NotFound);
