import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const Icon = styled.img`
  width: ${props => (props.small ? '18px' : '36px')};
  height: ${props => (props.small ? '18px' : '36px')};
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${props =>
    props.cover &&
    `
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.8);
    z-index: 5;
  `}
`;

const Preloader = ({ cover, light, h, small }) => (
  <Wrapper height={h} cover={cover}>
    <Icon
      src={`/images/preloader${light ? '-light' : ''}.svg`}
      small={small}
      alt=""
    />
  </Wrapper>
);

Preloader.defaultProps = {
  light: false,
  small: false,
  h: 'auto',
  cover: false,
};

Preloader.propTypes = {
  light: PropTypes.bool,
  small: PropTypes.bool,
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cover: PropTypes.bool,
};

export default Preloader;
