import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
import TextInput from 'components/forms/TextInput';
import {
  required,
  composeValidators,
  equalToPassword,
} from 'utils/formValidators';
import { MainTitle, AuthForm } from 'styles/globalStyles';
import { ForgotPasswordText } from 'components/Auth/style';

const Error = styled.div`
  color: #f45a5a;
`;

const ForgotPasswordForm = ({ onSubmit, t }) => {
  const { token } = useParams();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => (
        <AuthForm onSubmit={handleSubmit}>
          <MainTitle>{t('Reset password')}</MainTitle>
          <ForgotPasswordText>
            {t('resetPasswordInstruction')}
          </ForgotPasswordText>
          <Field
            label={t('Password')}
            name="password"
            type="password"
            component={TextInput}
            validate={composeValidators(required)}
          />
          <Field
            label={t('Password confirmation')}
            name="passwordConfirmation"
            type="password"
            component={TextInput}
            validate={composeValidators(required, equalToPassword)}
          />
          <Field
            name="token"
            type="hidden"
            component="input"
            initialValue={token}
          />
          {submitError && <Error>{submitError}</Error>}
          <Box pt={20} width={1}>
            <MainButton width="192px" mb={15} type="submit">
              {t('Reset password')}
            </MainButton>
          </Box>
        </AuthForm>
      )}
    />
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation('forms')(ForgotPasswordForm);
