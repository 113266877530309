import React, { Component } from 'react';
import { Flex, Box, Image, Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Container, MainTitle } from 'styles/globalStyles';
import { LineSeparator, Quote, QI } from './style';

const AboutUsWrapper = styled(Container)`
  ol {
    list-style: decimal;
    padding-left: 30px;
  }
  a {
    color: #575757;
    text-decoration: none;
  }
`;

class AboutUsPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <AboutUsWrapper>
        <MainTitle>{t('About us')}</MainTitle>

        <Flex flexWrap="wrap" mb={[4, 4, 4, 4, 6]}>
          <Box width={[1, 1, 1, 1, 1 / 3]} pr={4} mb={[4, 4, 4, 4, 0]}>
            <Text
              as="p"
              fontSize={[3, 3, 3, 3, 7]}
              lineHeight={['23px', '23px', '23px', '23px', '35px']}
              mb={[2, 2, 2, 2, 3]}
            >
              {t('row-1.p1')}
            </Text>
            <Text
              color="muted"
              as="p"
              fontSize={[3, 3, 3, 3, 4]}
              lineHeight={['23px', '23px', '23px', '23px', '28px']}
              dangerouslySetInnerHTML={{ __html: t('row-1.p2') }}
            />
          </Box>
          <Box width={[1, 1, 1, 1, 2 / 3]}>
            <Image
              src="/images/static-pages/about/people-dance-1.jpg"
              sx={{
                width: '100%',
              }}
            />
          </Box>
        </Flex>

        <Flex flexWrap="wrap">
          <Box
            width={[1, 1, 1, 1, 1 / 2]}
            pr={[0, 0, 0, 0, 3]}
            mb={[4, 4, 4, 4, 0]}
          >
            <Text
              as="p"
              fontSize={[3, 3, 3, 3, 7]}
              lineHeight={['23px', '23px', '23px', '23px', '35px']}
              mb={[2, 2, 2, 2, 3]}
            >
              {t('row-2.p1')}
            </Text>

            <Text
              color="muted"
              as="p"
              fontSize={[3, 3, 3, 3, 4]}
              lineHeight={['23px', '23px', '23px', '23px', '30px']}
              dangerouslySetInnerHTML={{ __html: t('row-3.p1') }}
            />
          </Box>
          <Box width={[1, 1, 1, 1, 1 / 2]} pl={[0, 0, 0, 0, 4]} mb={0}>
            <Text
              as="p"
              fontSize={[3, 3, 3, 3, 7]}
              lineHeight={['23px', '23px', '23px', '23px', '35px']}
              mb={[2, 2, 2, 2, 3]}
            >
              {t('row-2.p2')}
            </Text>

            <Text
              as="p"
              fontSize={[3, 3, 3, 3, 4]}
              lineHeight={['23px', '23px', '23px', '23px', '30px']}
              color="muted"
            >
              {t('row-3.p2')}
            </Text>
          </Box>
        </Flex>

        <LineSeparator mb={[4, 4, 4, 4, 6]} pt={[4, 4, 4, 4, 6]} />
        <Box
          sx={{
            width: ['80%'],
            maxWidth: '1050px',
            margin: '0 auto',
          }}
        >
          <Quote
            as="p"
            fontSize={[5, 5, 5, 5, 9]}
            lineHeight={['29px', '29px', '29px', '29px', '54px']}
          >
            <QI up />
            {t('row-4.quote')}
            <QI down />
            <Text textAlign="right" mr="70px">
              Leo Babauta
            </Text>
          </Quote>
        </Box>
        <LineSeparator mb={[4, 4, 4, 4, 6]} pt={[4, 4, 4, 4, 6]} />

        <Flex flexWrap="wrap" mb={7}>
          <Box
            width={[1, 1, 1, 1, 1 / 2]}
            pr={[0, 0, 0, 0, 4]}
            mb={[4, 4, 4, 4, 0]}
          >
            <Text
              color="muted"
              as="p"
              fontSize={[3, 3, 3, 3, 4]}
              lineHeight={['23px', '23px', '23px', '23px', '28px']}
              mb={4}
              dangerouslySetInnerHTML={{ __html: t('row-5.p1') }}
            />
          </Box>
          <Box width={[1, 1, 1, 1, 1 / 2]} pl={[0, 0, 0, 0, 4]}>
            <Image
              src="/images/static-pages/about/people-dance-2.jpg"
              sx={{
                width: '100%',
              }}
            />
          </Box>
        </Flex>
      </AboutUsWrapper>
    );
  }
}

export default withTranslation('aboutUs')(AboutUsPage);
