import React from 'react';

import {
  ProductBox,
  Image,
  Title,
  Description,
} from 'containers/Shop/components/Product/style';
import { NotPublishedBadge } from 'styles/globalStyles';
import routes from 'routes';

const Product = ({ title, isPublished, image, slug, shortDescription }) => (
  <ProductBox to={routes.product(slug)}>
    {!isPublished ? <NotPublishedBadge /> : 0}

    <Image src={image.thumbnails.small} alt="" />
    <Title>{title}</Title>
    <Description>{shortDescription}</Description>
  </ProductBox>
);

export default Product;
