import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { RoomsForm } from 'components/forms/FormError';
import { required } from 'utils/formValidators';
import FormSelect from 'components/forms/FormSelect';
import DateTimePicker from 'components/forms/DateTimePicker';
import { capacity } from 'utils/staticValues';
import Preloader from 'components/Preloader';

import {
  MainButtonSearch,
  MainButtonShow,
  FiltersWrapper,
  ArrowIcon,
  ExpandArrowIcon,
  FiltersForm,
  InputWrapper,
  Error,
  Icon,
} from '../../../searchResults/style';

const SearchFormResults = inject(
  'addressStore',
  'searchStore',
)(
  observer(
    ({
      addressStore: { fetchAddressesForDropdown },
      addressStore: { usedAddressesforDropdown, isLoading },
      searchStore: { submitSearchForm, error },
      initialValues,
      t,
    }) => {
      const [showSection, toggleSectionVisibility] = useState(false);

      useEffect(() => {
        const fetchDropdownValues = async () => {
          await fetchAddressesForDropdown();
        };

        fetchDropdownValues();
      }, []);

      const toggleSection = () => toggleSectionVisibility(!showSection);

      const CAPACITY = capacity();

      if (isLoading) return <Preloader h={60} />;

      return (
        <Form
          onSubmit={submitSearchForm}
          initialValues={{
            ...initialValues,
          }}
          render={({
            handleSubmit,
            touched,
            dirtyFields,
            form: { change },
            values: formValues,
          }) => (
            <RoomsForm onSubmit={handleSubmit}>
              <MainButtonShow
                showSection={showSection}
                sx={{
                  '@media screen and (max-width: 40em)': {
                    marginTop: '10px',
                  },
                }}
                onClick={toggleSection}
                type="button"
              >
                {t('buttons:Open filters')}
                <ExpandArrowIcon src="/images/arrow.svg" />
              </MainButtonShow>

              <FiltersWrapper showSection={showSection}>
                <FiltersForm>
                  <InputWrapper flex={1.8}>
                    <Field
                      name="city"
                      placeholder={t('dropdowns:Choose location')}
                      border="secondary"
                      component={FormSelect}
                      validate={required}
                      variant="secondary"
                      options={usedAddressesforDropdown}
                    />
                  </InputWrapper>
                  <InputWrapper flex={1}>
                    <Field
                      height={30}
                      name="capacity"
                      placeholder={t('dropdowns:Number of people')}
                      component={FormSelect}
                      validate={required}
                      variant="secondary"
                      options={CAPACITY}
                    />
                  </InputWrapper>
                  <InputWrapper flex={1.8}>
                    <Field
                      height={30}
                      name="date"
                      placeholder={t('dropdowns:Select day and hours')}
                      hidePlaceholder={
                        (Object.hasOwnProperty.call(formValues, 'date') &&
                          Object.hasOwnProperty.call(formValues, 'from') &&
                          Object.hasOwnProperty.call(formValues, 'to')) ||
                        touched.from ||
                        touched.to ||
                        dirtyFields.date
                      }
                      changeFieldValueHandler={change}
                      formValues={formValues}
                      initialValues={formValues}
                      component={DateTimePicker}
                      bgColor="#FAFAFA"
                      validate={required}
                      secondaryColor
                      bgTimeColor="#FAFAFA"
                    />
                  </InputWrapper>
                  <MainButtonSearch
                    sx={{
                      '@media screen and (max-width: 40em)': {
                        marginTop: '10px',
                      },
                    }}
                    type="submit"
                  >
                    {t('buttons:Search')}
                    <ArrowIcon src="/images/arrow.svg" alt="" />
                  </MainButtonSearch>
                </FiltersForm>
              </FiltersWrapper>
              {error ? (
                <Error>
                  <Icon src="/images/icons/error.svg" alt="error" />
                  {error}
                </Error>
              ) : null}
            </RoomsForm>
          )}
        />
      );
    },
  ),
);

export default withTranslation()(withRouter(SearchFormResults));
