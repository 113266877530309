import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import uniqueId from 'lodash/uniqueId';

import FormError from 'components/forms/FormError';

const Input = styled.input`
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;

  :checked + label {
    :after {
      opacity: 1;
    }
  }
`;

const Label = styled.label`
  position: relative;
  font-size: 14px;
  display: flex;
  color: ${props => (props.error ? '#F45A5A' : '#919191')};

  :before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #fafafa;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
  }

  :after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #3e291c;
    opacity: 0;
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    transition: opacity 0.3s ease-in-out;
  }
`;

const Text = styled.span`
  padding: 3px 0 0 10px;
  > a {
    text-decoration: none;
    color: #000;
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;

const RadioButton = ({ input, meta, label, width, ...props }) => {
  const fieldId = uniqueId(`field-${input.name}_${input.type}-`);
  const withError =
    (meta.modified || meta.submitFailed) && (meta.error || meta.submitError);

  return (
    <Wrapper>
      <Input id={fieldId} {...props} {...input} />
      <Label htmlFor={fieldId} error={withError}>
        <Text>{label}</Text>
      </Label>
      <FormError meta={meta} marginTop={2} marginLeft={30} />
    </Wrapper>
  );
};

RadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default RadioButton;
