import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment-timezone';
import 'moment/min/locales';
import en from './translations/en';
import ua from './translations/ua';
import pl from './translations/pl';

const detector = {
  name: 'browserLanguage',

  lookup() {
    const found = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i += 1) {
          found.push(navigator.languages[i]);
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage);
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    if (found.length > 0) {
      return ['pl', 'en', 'ua'].includes(found[0].split('-')[0])
        ? found[0].split('-')[0]
        : 'pl';
    }
    return undefined;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      pl,
      en,
      ua,
    },
    detection: {
      order: ['querystring', 'localStorage', 'browserLanguage'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'ballroom:language',
      caches: ['localStorage'],
    },
    fallbackLng: 'pl',
    debug: true,

    // have a common namespace used around the full app
    ns: ['common', 'navbar'],
    defaultNS: 'common',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export const availableLanguages = [
  { value: 'pl', label: 'PL' },
  { value: 'en', label: 'EN' },
  { value: 'ua', label: 'UA' },
];
const currentLang = i18n.language === 'ua' ? 'uk' : i18n.language;
moment.locale(currentLang);

i18n.on('languageChanged', lng => {
  const language = lng === 'ua' ? 'uk' : lng;
  moment.locale(language);
});

export default i18n;
