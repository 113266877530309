import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass';
import { withTranslation } from 'react-i18next';
import MainButton from 'components/MainButton';
import TextInput from 'components/forms/TextInput';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from 'utils/formValidators';
import { MainTitle, AuthForm } from 'styles/globalStyles';
import { ForgotPasswordText } from 'components/Auth/style';

const ForgotPasswordForm = ({ onSubmit, t }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError }) => (
      <AuthForm onSubmit={handleSubmit}>
        <MainTitle>{t('Forgot password?')}</MainTitle>
        <ForgotPasswordText>
          {t('forgotPasswordInstruction')}
        </ForgotPasswordText>
        <Field
          label={t('Email')}
          name="email"
          type="text"
          component={TextInput}
          validate={composeValidators(required, mustBeValidEmail)}
        />
        {submitError && <div>{submitError}</div>}
        <Box pt={20} width={1}>
          <MainButton width="192px" mb={15} type="submit">
            {t('Reset password')}
          </MainButton>
        </Box>
      </AuthForm>
    )}
  />
);

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation('forms')(ForgotPasswordForm);
