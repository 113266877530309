import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

const Link = ({ description, text, to }) => {
  if (description) {
    return (
      <Styled.Wrapper>
        <Styled.Description>{description}</Styled.Description>
        <Styled.Link to={to}>{text}</Styled.Link>
      </Styled.Wrapper>
    );
  }

  return <Styled.Link to={to}>{text}</Styled.Link>;
};

Link.defaultProps = {
  description: null,
};

Link.propTypes = {
  description: PropTypes.string,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Link;
