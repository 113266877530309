import styled from '@emotion/styled';
import { Box, Text } from 'rebass';
import { NavLink } from 'react-router-dom';

export const LineSeparator = styled(Box)`
  border-bottom: 1px solid #dcdcdc;
`;

export const Quote = styled(Text)`
  position: relative;
`;

export const QI = styled.span`
  /* position: absolute; */
  width: 26px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-image: url(${props =>
    props.up ? '/images/icons/quote-up.svg' : '/images/icons/quote-down.svg'});
  display: inline-block;
  position: relative;
  margin-left: ${props => (props.up ? '-26px' : '10px')};
  left: ${props => (props.up ? '-8px' : 'auto')};
  top: ${props => (props.up ? '-8px' : 'auto')};
  bottom: ${props => (props.up ? 'auto' : '-33px')};
  margin-top: ${props => (props.up ? '-50px' : '-33px')};
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.muted};

  &:before {
    display: block;
    width: 16px;
    height: 16px;
    content: '';
    background: url('/images/icons/diamond.svg') no-repeat 50% 50% / contain;
    margin-right: 10px;
  }
`;

export const Plans = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 15px 0;
`;

export const Price = styled(Text)`
  color: ${({ theme }) => theme.colors.gold};
  transition: color 0.3s ease-in-out;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Plan = styled(NavLink)`
  width: 30%;
  max-width: 300px;
  text-align: center;
  margin: 15px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #fff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gold};
    ${Price} {
      color: #fff;
    }
  }
`;
