import { extendObservable, action } from 'mobx';
import { API, APIRoutes } from 'api';
// import { toast } from 'react-toastify';
// import i18n from 'i18n';
// import { authStore } from 'stores';

const initialState = {
  isLoading: false,
  initialized: false,
  error: null,
  promotions: [],
};

export class UserStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action
  buyPremium = async (billingAddress, option) => {
    this.isLoading = true;
    try {
      const {
        data: { status, paymentPageUrl },
      } = await API.post(APIRoutes.buyPremium, { billingAddress, option });

      if (status === 'success') {
        window.location.href = paymentPageUrl;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
      this.isLoading = false;
    }
  };

  @action
  buyPromotion = async (promotionId, billingAddress, validityInDays) => {
    this.isLoading = true;
    try {
      const {
        data: { status, paymentPageUrl },
      } = await API.post(APIRoutes.buyPromotion(promotionId), {
        ...billingAddress,
        validity_in_days: +validityInDays,
      });

      if (status === 'success') {
        window.location.href = paymentPageUrl;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
      this.isLoading = false;
    }
  };

  @action
  fetchPromotions = async () => {
    this.isLoading = true;
    try {
      const {
        data: { data: promotions },
      } = await API.get(APIRoutes.promotions);
      this.promotions = promotions;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.isLoading = false;
      this.initialized = true;
    }
  };

  @action
  reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };
}

export default new UserStore();
