import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from 'styles/breakpoints';
import { Container } from 'styles/globalStyles';

export const ContentWrapper = styled(Container)`
  @media screen and ${breakpoint.md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Wrapper = styled.footer`
  position: ${({ isOnHomepage }) => (isOnHomepage ? 'absolute' : 'relative')};
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  text-align: center;

  @media screen and ${breakpoint.md} {
    text-align: unset;
  }
`;

export const Col = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 15px 0;

  /* @media ${breakpoint.md} {
    margin-bottom: 0;
    color: ${props => (props.light ? '#947F72' : '#111313')};
  } */
`;

export const Content = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`;

export const Copyright = styled.div`
  background-color: ${({ theme }) => theme.colors.footerCopyBacground};
  color: ${({ theme }) => theme.colors.text};
`;

export const Top = styled.div`
  background: ${({ theme }) => theme.colors.footerTopBackground}c7;
  color: #fff;
  padding: 30px 0;
  display: ${({ isOnHomepage }) => (isOnHomepage ? 'none' : 'block')};

  @media screen and ${breakpoint.md} {
    display: block;
  }

  @media screen and (max-height: 1000px) {
    padding: 15px 0;
  }
`;

export const FooterLink = styled(RouterLink)`
  color: #fff;
  /* text-transform: uppercase; */
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;

  @media screen and (max-height: 1000px) {
    font-size: 14px;
  }
`;

export const Links = styled.ul`
  li {
    padding: 10px 0;

    @media screen and (max-height: 1000px) {
      padding: 5px 0;
    }
  }
`;

export const Contact = styled.div`
  margin-left: auto;
  margin-top: 30px;

  > a {
    display: inline-flex;
    align-items: center;
    color: #fff;
    text-decoration: none;

    > img {
      display: block;
      margin-right: 5px;
      width: 24px;
    }

    & + a {
      @media screen and ${breakpoint.md} {
        margin-left: 15px;
      }
    }

    @media screen and (max-height: 1000px) {
      font-size: 14px;
    }
  }
  @media screen and ${breakpoint.md} {
    margin-top: 0;
  }

  > p {
    font-size: 16px;
    line-height: 24px;

    @media screen and ${breakpoint.md} {
      margin-top: 30px;
    }

    @media screen and (max-height: 1000px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
`;
