import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { Container } from 'styles/globalStyles';
import MainButton from 'components/MainButton';
import Modal from 'components/Modal';
import routes from 'routes';
import Preloader from 'components/Preloader';
import * as Styled from './style';
import MyBookings from './components/MyBookings';
import MyOrders from './components/MyOrders';
import ProfileForm from './components/ProfileForm';
import DeleteAccountModal from './components/DeleteAccountModal/index';
import PremiumSection from './components/Premium';

const AccountPage = ({
  userStore: {
    profile,
    updateProfile,
    deleteProfile,
    fetchBookings,
    fetchingBookings,
    // fetchingMoreBookings,
    fetchMoreBookings,
    bookings,
    orders,
    hasMore,
  },
  promotionsStore: { fetchPromotions, promotions },
}) => {
  const [modalIsVisible, setModalVisibility] = useState(false);
  const { search } = useLocation();
  const { replace } = useHistory();
  const { t } = useTranslation('account');

  const fetchData = async () => {
    await fetchBookings(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!promotions.length) {
      const getPromotions = async () => {
        await fetchPromotions();
      };

      getPromotions();
    }

    if (search) {
      const queryParams = queryString.parse(search);
      if (queryParams.premium) {
        toast.info(t("You've successfully bought premium account"));
        replace(routes.account);
      }
      if (queryParams.promotion) {
        toast.info(t("You've successfully bought promotion"));
        replace(routes.account);
      }
    }
  }, []);

  if (isEmpty(profile)) {
    return <Preloader />;
  }

  return (
    <Container>
      <Styled.Breadcrumb>
        <Text fontWeight="medium" fontSize={[1, 1, 8, 8, 8]} mr={2}>
          {profile.name || profile.email}
          {'  '}
        </Text>
        <Text fontWeight="thin" fontSize={[1, 1, 8, 8, 8]}>
          / {t('Account Settings')}
        </Text>
      </Styled.Breadcrumb>

      <PremiumSection profile={profile} promotions={promotions} />

      <MyBookings
        fetchingBookings={fetchingBookings}
        fetchMoreBookings={fetchMoreBookings}
        hasMore={hasMore}
        bookings={bookings}
        isPremium={profile.isPremium}
      />
      <MyOrders
        fetchingBookings={fetchingBookings}
        fetchMoreBookings={fetchMoreBookings}
        hasMore={hasMore}
        orders={orders}
      />

      <Styled.Wrapper>
        {!isEmpty(profile) ? (
          <ProfileForm
            onSubmit={updateProfile}
            initialValues={{
              name: profile.name,
              email: profile.email,
              phone: profile.phone,
              billingAddress: {
                ...(!profile?.billingAddress?.type && {
                  type: 'private',
                }),
                businessType: 'self_employed',
                ...profile.billingAddress,
              },
            }}
          />
        ) : null}
        <Styled.FormSection separatedTop>
          <Styled.FormTitle
            as="h2"
            fontWeight="medium"
            fontSize={['16px', '16px', '16px', '22px']}
          >
            {t('Delete account')}
          </Styled.FormTitle>
          <Styled.DeleteAccountInfo>{t('DeleteInfo')}</Styled.DeleteAccountInfo>
          <MainButton
            mt={[20, 20, 20, 40]}
            onClick={() => setModalVisibility(true)}
            type="submit"
            // disabled={submitting}
          >
            {t('Delete account')}
          </MainButton>
          <Modal
            opened={modalIsVisible}
            close={() => {
              setModalVisibility(false);
            }}
          >
            <DeleteAccountModal
              profile={profile}
              deleteProfile={deleteProfile}
            />
          </Modal>
        </Styled.FormSection>
      </Styled.Wrapper>
    </Container>
  );
};

export default inject('userStore', 'promotionsStore')(observer(AccountPage));
