import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Container, MainTitle } from 'styles/globalStyles';

const AboutUsWrapper = styled(Container)`
  ol {
    list-style: decimal;
    padding-left: 30px;
  }
  a {
    color: #575757;
    text-decoration: none;
  }
`;

class InvestorRelationsPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <AboutUsWrapper>
        <MainTitle>{t('title')}</MainTitle>
        Investor relations
      </AboutUsWrapper>
    );
  }
}

export default withTranslation('investorRelations')(InvestorRelationsPage);
