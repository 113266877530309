/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MainButton from 'components/MainButton';
import routes from 'routes';
import * as Styled from './style';

// eslint-disable-next-line
const PromotionsList = ({ myPromotions, promotions }) => {
  const { t } = useTranslation('account');
  const { push } = useHistory();

  const myPromotion = promotion => {
    const promo = promotions.find(p => p._id === promotion._id);

    if (promo) {
      return (
        <Styled.PromotionItem key={promotion._id}>
          <h3>
            {promo.name}
            <time>
              {t('expired')} {moment(promotion.validTo).format('DD.MM.YYYY')}
            </time>
          </h3>
        </Styled.PromotionItem>
      );
    }

    return null;
  };

  return (
    <Styled.FormSection separatedTop>
      <Styled.FormTitle
        as="h3"
        fontWeight="medium"
        fontSize={['16px', '16px', '16px', '18px']}
      >
        {t('Additional promotions')}
      </Styled.FormTitle>

      <Styled.List>
        {!myPromotions.length ? (
          <>
            <p>{t("You don't have any additional promotions")}</p>
            <MainButton
              mt={15}
              type="button"
              onClick={() => push(routes.promotions)}
            >
              {t('Check additional promotions')}
            </MainButton>
          </>
        ) : (
          <>
            {myPromotions.map(myPromotion)}
            <MainButton
              mt={15}
              type="button"
              onClick={() => push(routes.promotions)}
            >
              {t('Check more additional promotions')}
            </MainButton>
          </>
        )}
      </Styled.List>
    </Styled.FormSection>
  );
};

PromotionsList.defaultProps = {
  myPromotions: [],
};

PromotionsList.propTypes = {
  myPromotions: PropTypes.array,
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PromotionsList;
