import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const ToggableBoxWrapper = styled.div``;

export const Trigger = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  text-transform: uppercase;
  width: 100%;
  justify-content: space-between;
  font-size: ${({ subItem }) => (subItem ? 18 : 24)}px;
  cursor: pointer;
  padding: ${({ subItem }) => (subItem ? 10 : 20)}px 15px;

  border-top: ${({ subItem }) =>
    subItem ? '1px rgba(0,0,0,.1) solid' : 'none'};

  > svg {
    display: block;
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
  }

  @media screen and ${breakpoint.md} {
    padding: ${({ subItem }) => (subItem ? 20 : 40)}px 45px;
  }
`;

export const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.02);
  overflow: hidden;

  button {
    padding-left: 70px;
`;
