import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

const Label = styled.label`
  margin-bottom: 2px;
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;

  @media ${breakpoint.md} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export default Label;
